import { Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
  handleDeskButtonClick: (time: string) => void;
  reserv_desk: string[];
  selectedDesk: string | null;
  chunkNum: number;
  times: string[];
}

const ReservDeskList = (props: propsType) => {
  const chunkTimeValues = (values: string[], size: number): string[][] => {
    return values.reduce((chunks, value, index) => {
      const chunkIndex = Math.floor(index / size);
      if (!chunks[chunkIndex]) {
        chunks[chunkIndex] = [];
      }
      chunks[chunkIndex].push(value);
      return chunks;
    }, [] as string[][]);
  };

  const timeValueChunks = chunkTimeValues(props.reserv_desk, props.chunkNum);

  return (
    <div style={{ width: "100%", height: "100%", marginLeft: "5px" }}>
      <List sx={{ width: "100%" }}>
        {timeValueChunks.map((chunk, index) => (
          <ListItem
            key={index}
            sx={{ display: "flex", justifyContent: "center", padding: 0 }}
          >
            {chunk.map((timeValue) => (
              <Button
                key={timeValue}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  color: "black",
                  border: "1px solid #ccc",
                  minWidth: "80px",
                  margin: 0,
                  marginRight: 2,
                  marginTop: 1,
                  opacity: props.times.includes(timeValue) ? 0.5 : 1,
                }}
                style={{
                  borderColor: props.times.includes(timeValue)
                    ? "#cccccc"
                    : "#2E3341",
                  color:
                    props.selectedDesk === timeValue &&
                    !props.times.includes(timeValue)
                      ? "#ffffff"
                      : "#2E3341",
                  backgroundColor:
                    props.selectedDesk === timeValue &&
                    !props.times.includes(timeValue)
                      ? "#2E3341"
                      : "",
                  pointerEvents: props.times.includes(timeValue)
                    ? "none"
                    : "auto",
                }}
                onClick={() => props.handleDeskButtonClick(timeValue)}
              >
                {timeValue}
              </Button>
            ))}
            {chunk.length < props.chunkNum &&
              Array.from({ length: props.chunkNum - chunk.length }, (_, i) => (
                <Button
                  key={`empty-${i}`}
                  sx={{
                    borderRadius: "10px",
                    color: "black",
                    border: "0px solid #ccc",
                    minWidth: "80px",
                    margin: 0,
                    marginRight: 2,
                    marginTop: 1,
                  }}
                />
              ))}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ReservDeskList;
