import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { BrowserQRCodeReader } from "@zxing/browser";
import { Box, Stack, Paper, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import CryptoJS from "crypto-js";
import Toast from "../../utils/Toast";
import { HttpMainApi } from "../../interface/main-api";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
//IoT start
import { v4 as uuidv4 } from "uuid";
import * as AWS from "aws-sdk";
import { appConfig } from "../../config/Config";
import moment from "moment";
import * as Paho from "paho-mqtt";
//IoT end
const mainApi = new HttpMainApi();

interface Member {
  userAddr: string;
  reservState: string;
  userId: string;
  userName: string;
  sk: string;
  pk: string;
  inputDt: string;
  displayTime: string;
}

const drawerBleeding = 56;

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const QRCodeScanner: React.FC = () => {
  const webcamRef = useRef<Webcam>(null);
  const [memberData, setMemberData] = useState(0);
  const [memberOutData, setMemberOutData] = useState(0);
  const [memberLive, setMemberLive] = useState<Member[]>([]);
  const lastScannedQRCode = useRef<string | null>(null);
  const toastRef: any = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [scanTimestamp, setScanTimestamp] = useState("");

  const iotRegion: string = appConfig.iotRegion;
  const iotIdentityPollId: string = appConfig.iotIdentityPollId;
  const iotEndPoint: string = appConfig.iotEndPoint;
  let clientId = useRef<string>("");
  let mqtt_client: any = useRef();
  useEffect(() => {
    AWS.config.region = iotRegion;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: iotIdentityPollId,
    });
    AWS.config.getCredentials((err) => {
      if (err) console.error(err.stack);
      else {
        if (AWS.config.credentials !== undefined) {
          mqtt_client.current = initMqttClient();

          mqtt_client.current.onConnectionLost = function (err: any) {
            console.info("MQTT Connection Lost : ", err);
          };
          mqtt_client.current.onMessageArrived = function (msg: any) {
            get_qr_reservation();
            try {
              get_qr_reservation();
            } catch (e) {
              console.error("onMessageArrived error : ", e);
            }
          };
          var connectOptions: any = {
            onSuccess: function () {
              try {
                mqtt_client.current.subscribe(`member/commnity/healthout`, {
                  onSuccess: function () {
                    console.log("IoT subscribe ");
                  },
                  onFailure: function () {
                    console.error("subscribeFailed");
                  },
                });
              } catch (e) {
                console.error("subscribeFailed", e);
              }
            },
            useSSL: true,
            timeout: 3,
            mqttVersion: 4,
            keepAliveInterval: 120,
            onFailure: function (errorMessage: any) {
              console.error("connectionLost", errorMessage);
            },
          };
          mqtt_client.current.connect(connectOptions);
        }
      }
    });
  }, []);

  function SigV4Utils() {}

  SigV4Utils.sign = function (key: any, msg: any) {
    var hash = CryptoJS.HmacSHA256(msg, key);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.sha256 = function (msg: any) {
    var hash = CryptoJS.SHA256(msg);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.getSignatureKey = function (
    key: any,
    dateStamp: any,
    regionName: any,
    serviceName: any
  ) {
    var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
    var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
    var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
    var kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
    return kSigning;
  };

  const initMqttClient = () => {
    var time = moment.utc();
    var dateStamp = time.format("YYYYMMDD");
    var amzdate = dateStamp + "T" + time.format("HHmmss") + "Z";
    var service = "iotdevicegateway";
    var region = AWS.config.region;
    var accessKey = AWS.config.credentials?.accessKeyId;
    var secretKey = AWS.config.credentials?.secretAccessKey;
    var algorithm = "AWS4-HMAC-SHA256";
    var method = "GET";
    var canonicalUri = "/mqtt";
    var host = iotEndPoint;

    var credentialScope =
      dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
    var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
    canonicalQuerystring +=
      "&X-Amz-Credential=" +
      encodeURIComponent(accessKey + "/" + credentialScope);
    canonicalQuerystring += "&X-Amz-Date=" + amzdate;
    canonicalQuerystring += "&X-Amz-Expires=86400";
    canonicalQuerystring += "&X-Amz-SignedHeaders=host";

    var canonicalHeaders = "host:" + host + "\n";
    var payloadHash = SigV4Utils.sha256("");
    var canonicalRequest =
      method +
      "\n" +
      canonicalUri +
      "\n" +
      canonicalQuerystring +
      "\n" +
      canonicalHeaders +
      "\nhost\n" +
      payloadHash;

    var stringToSign =
      algorithm +
      "\n" +
      amzdate +
      "\n" +
      credentialScope +
      "\n" +
      SigV4Utils.sha256(canonicalRequest);
    var signingKey = SigV4Utils.getSignatureKey(
      secretKey,
      dateStamp,
      region,
      service
    );
    var signature = SigV4Utils.sign(signingKey, stringToSign);
    canonicalQuerystring += "&X-Amz-Signature=" + signature;
    if (AWS.config.credentials?.sessionToken !== undefined) {
      canonicalQuerystring +=
        "&X-Amz-Security-Token=" +
        encodeURIComponent(AWS.config.credentials.sessionToken);
    }

    var requestUrl =
      "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
    clientId.current = "member-client-" + uuidv4();
    var mqtt_client = new Paho.Client(requestUrl, clientId.current);

    return mqtt_client;
  };

  const playAudio = (option: string) => {
    const inaudio = new Audio(`/audio/${option}.mp3`); // 오디오 파일 경로 설정
    inaudio.play();
    setIsPlaying(true);
    inaudio.onended = () => setIsPlaying(false);
  };

  useEffect(() => {
    const scan = async () => {
      try {
        if (webcamRef.current) {
          const video = webcamRef.current.video;
          if (video) {
            const codeReader = new BrowserQRCodeReader();

            codeReader.decodeFromVideoElement(video, async (result) => {
              if (result) {
                const scannedData = result.getText();
                if (scannedData !== lastScannedQRCode.current) {
                  const {
                    qrcodehoures,
                    formathourspk,
                    formattedTime,
                    formatmonthpk,
                  } = getFormattedDateTime();
                  const hash = CryptoJS.SHA256(qrcodehoures).toString();
                  lastScannedQRCode.current = scannedData;

                  setScanTimestamp(formathourspk);
                  const splitData = scannedData.split("/");
                  const scannedHash = splitData[0];
                  const userId = splitData[1];
                  // console.log("scannedHash : ", scannedHash);
                  // console.log("hash : ", hash);
                  const shortenedHash = hash.substring(0, 5);
                  if (scannedHash === shortenedHash) {
                    // console.log("헬스 신청 확인");
                    //1.헬스 신청 확인 (유효하지 않은 QR코드입니다.)
                    const pk = `${formatmonthpk}/HEALTH`;
                    const sk = splitData[2];
                    const param: any = {
                      pk: pk,
                      sk: sk,
                    };
                    const res = await mainApi.get_qrcode_reservation(param);
                    if (res.code === "200") {
                      const {
                        formatpk,
                        formathourspk,
                        formatmonthpk,
                        formattedDate,
                      } = getFormattedDateTime();
                      const pk = `${formatmonthpk}/INOUT`;
                      const param2: any = {
                        pk: pk,
                        sk: formatpk + formathourspk,
                        skSearch: formatpk,
                        inputDt: formattedDate,
                        displayTime: formattedTime,
                        userId: userId,
                      };
                      const res2 = await mainApi.put_health_inout(param2);
                      if (res2.code === "200") {
                        playAudio("in");
                        const pk = `${formatmonthpk}/INOUT`;
                        const sk = formatpk;
                        const param: any = {
                          pk: pk,
                          sk: sk,
                        };
                        const res3 = await mainApi.get_now_inout_counting(
                          param
                        );
                        if (res3.code === "200") {
                          toastRef.current?.toast(
                            "입장 확인 되었습니다.",
                            "info",
                            4000,
                            {
                              vertical: "top",
                              horizontal: "center",
                            }
                          );
                          const inInfoCount =
                            res3.response?.result_in?.length ?? 0;
                          const outInfoCount =
                            res3.response?.result_out?.length ?? 0;
                          setMemberData(inInfoCount);
                          setMemberOutData(outInfoCount);
                          const reservationInfo =
                            res3.response?.result_in ?? [];
                          setMemberLive(reservationInfo);
                          console.log(`member/community/${userId}`);
                          mqtt_client.current.publish(
                            `member/community/healthin/${userId}`,
                            JSON.stringify("community onSuccess publish"), // 메시지 내용
                            1, // QoS 설정 (0, 1, 또는 2)
                            false // retain 플래그 설정 (true 또는 false)
                          );
                        }
                      } else if (res2.code === "301") {
                        playAudio("out");
                        const pk = `${formatmonthpk}/INOUT`;
                        const sk = formatpk;
                        const param: any = {
                          pk: pk,
                          sk: sk,
                        };
                        const res3 = await mainApi.get_now_inout_counting(
                          param
                        );
                        if (res3.code === "200") {
                          toastRef.current?.toast(
                            "퇴장 처리 되었습니다.",
                            "error",
                            4000,
                            {
                              vertical: "top",
                              horizontal: "center",
                            }
                          );
                          const inInfoCount =
                            res3.response?.result_in?.length ?? 0;
                          const outInfoCount =
                            res3.response?.result_out?.length ?? 0;
                          setMemberData(inInfoCount);
                          setMemberOutData(outInfoCount);
                          const reservationInfo =
                            res3.response?.result_in ?? [];
                          setMemberLive(reservationInfo);
                          mqtt_client.current.publish(
                            `member/community/healthout/${userId}`,
                            JSON.stringify("community onSuccess publish"), // 메시지 내용
                            1, // QoS 설정 (0, 1, 또는 2)
                            false // retain 플래그 설정 (true 또는 false)
                          );
                        } else {
                          toastRef.current?.toast(
                            "퇴장 처리 되었습니다!",
                            "error",
                            4000,
                            {
                              vertical: "top",
                              horizontal: "center",
                            }
                          );
                          const inInfoCount =
                            res3.response?.result_in?.length ?? 0;
                          const outInfoCount =
                            res3.response?.result_out?.length ?? 0;
                          setMemberData(inInfoCount);
                          setMemberOutData(outInfoCount);
                          const reservationInfo =
                            res3.response?.result_in ?? [];
                          setMemberLive(reservationInfo);
                          mqtt_client.current.publish(
                            `member/community/healthout/${userId}`,
                            JSON.stringify("community onSuccess publish"), // 메시지 내용
                            1, // QoS 설정 (0, 1, 또는 2)
                            false // retain 플래그 설정 (true 또는 false)
                          );
                        }
                      } else {
                        toastRef.current?.toast(
                          "QR코드를 새로고침 후 다시 시도해 주세요",
                          "error",
                          4000,
                          {
                            vertical: "top",
                            horizontal: "center",
                          }
                        );
                        return;
                      }
                    } else if (res.code === "400") {
                      toastRef.current?.toast(
                        "QR코드가 유효하지 않습니다.",
                        "error",
                        4000,
                        {
                          vertical: "top",
                          horizontal: "center",
                        }
                      );
                      return;
                    }
                  } else {
                    toastRef.current?.toast(
                      "QR코드가 유효하지 않습니다.",
                      "error",
                      4000,
                      {
                        vertical: "top",
                        horizontal: "center",
                      }
                    );
                    return;
                  }
                }
              }
            });
          }
        }
      } catch (error) {
        console.error("QR Code scan error: ", error);
      }
    };

    scan();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      lastScannedQRCode.current = null;
    }, 7000);
  }, [scanTimestamp]);

  const get_qr_reservation = async () => {
    const { formatpk, formatmonthpk } = getFormattedDateTime();
    const pk = `${formatmonthpk}/INOUT`;
    const sk = formatpk;
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res3 = await mainApi.get_now_inout_counting(param);
    const inInfoCount = res3.response?.result_in?.length ?? 0;
    const outInfoCount = res3.response?.result_out?.length ?? 0;

    setMemberData(inInfoCount);
    setMemberOutData(outInfoCount);
    const reservationInfo = res3.response?.result_in ?? [];
    setMemberLive(reservationInfo);
  };

  useEffect(() => {
    get_qr_reservation();
  }, []);

  // const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    const timer = setTimeout(() => {
      // window.location.reload();
      get_qr_reservation();
    }, 3600000); // 3600000ms = 1시간

    // 컴포넌트가 언마운트 될 때 타이머를 제거합니다.
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "12px", pb: "16px !important" }}
      >
        <Box
          className="css-8zynwq"
          sx={{
            marginTop: "6rem !important",
            position: "relative",
            height: "30rem",
          }}
        >
          <section>
            <div>
              <div className="css-1d3bbye">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50%",
                    left: "17%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <span style={{ color: "#fff", fontSize: "200px" }}>
                    <p style={{ fontSize: "40px", margin: "0" }}>
                      현재 이용중인 회원
                    </p>
                    <p style={{ margin: "0", textAlign: "center" }}>
                      {memberData}
                    </p>
                  </span>
                </div>
                <div
                  className="css-1q2qc53"
                  style={{ position: "absolute", bottom: "5rem", left: "30%" }}
                >
                  <Box
                    sx={{
                      backgroundImage:
                        'url("https://images.unsplash.com/photo-1521668576204-57ae3afee860?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=934&amp;q=80")',
                      backgroundSize: "cover",
                      width: "25rem",
                      height: "36rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <Webcam
                      ref={webcamRef}
                      style={{ width: "100%", height: "100%" }}
                      videoConstraints={{ facingMode: "user" }}
                    />
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50%",
                    left: "83%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <span style={{ color: "#fff", fontSize: "200px" }}>
                    <p
                      style={{
                        fontSize: "40px",
                        margin: "0",
                        marginRight: "6px",
                        textAlign: "center",
                      }}
                    >
                      퇴장 회원
                    </p>
                    <p style={{ margin: "0", textAlign: "center" }}>
                      {memberOutData}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Stack>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {memberData}명 이용중
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {memberLive.map((member: Member, index: number) => (
            <List key={index}>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary={member.userId.substring(0, 3) + "****"}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
        </StyledBox>
      </SwipeableDrawer>
      <Toast ref={toastRef} />
    </div>
  );
};

export default QRCodeScanner;
