import React, { Fragment, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Stack, useTheme, useMediaQuery, Button, Alert } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import WidgetsIcon from "@mui/icons-material/Widgets";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QrcodePage from "../../utils/QrcodePage";
import CropFreeIcon from "@mui/icons-material/CropFree";
//IoT start
import { v4 as uuidv4 } from "uuid";
import * as AWS from "aws-sdk";
import { appConfig } from "../../config/Config";
import moment from "moment";
import * as Paho from "paho-mqtt";
import CryptoJS from "crypto-js";
import HealthRankPage from "../../utils/HealthRankPage";
import HealthOutPage from "../../utils/HealthOutPage";
import CameraQrcodePage from "../../utils/CameraQrcodePage";
//IoT end
interface propsType {
  userState: userState;
}
interface ReservationDetails {
  userName: string;
  userId: string;
  inputDt: string;
  reservDt: string;
  reservOption: string;
  deviceNum: string;
}

interface Reservations {
  [key: string]: ReservationDetails;
}

interface ReservationInfo {
  sk: string;
  pk: string;
  userId: string;
  reservations: Reservations;
}

interface Reservation {
  sk: string;
  pk: string;
  userId: string;
  reservInfo: ReservationInfo;
  reservState: string;
}
type DataType = {
  content1: string;
  content2: React.ReactNode;
  content3: string;
};

interface UserExerciseCount {
  userId: string;
  count: number;
}

interface GroupedByCount {
  [key: number]: UserExerciseCount[];
}
interface GroupedList {
  userIds: string[];
  count: number;
}

interface Member {
  userAddr: string;
  reservState: string;
  userId: string;
  userName: string;
  sk: string;
  pk: string;
  inputDt: string;
  displayTime: string;
}
const mainApi = new HttpMainApi();

const MobileMainPage = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [reservationGolf, setReservationGolf] = useState<Reservation[]>([]);
  const [reservationStudy, setReservationStudy] = useState<Reservation[]>([]);
  const [reservationHealth, setReservationHealth] = useState<Reservation[]>([]);
  const [reservationLocker, setReservationLocker] = useState<Reservation[]>([]);
  const [golfNoData, setGolfNoData] = useState(true);
  const [studyNoData, setStudyNoData] = useState(true);
  const [healthNoData, setHealthNoData] = useState(true);
  const [lockerNoData, setLockerNoData] = useState(true);
  const [memberData, setMemberData] = useState(0);
  const [memberExist, setMemberExist] = useState(false);
  const [memberHealthDay, setMemberHealthDay] = useState(0);
  const [lockerHPassword, setLockerHPassword] = useState("");
  const [lockerGPassword, setLockerGPassword] = useState("");
  const [groups, setGroups] = useState<GroupedList[]>([]);
  const [userRank, setUserRank] = useState(4);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const iotRegion: string = appConfig.iotRegion;
  // const iotIdentityPollId: string = appConfig.iotIdentityPollId;
  // const iotEndPoint: string = appConfig.iotEndPoint;
  // let clientId = useRef<string>("");
  // let mqtt_client: any = useRef();
  // useEffect(() => {
  //   AWS.config.region = iotRegion;
  //   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //     IdentityPoolId: iotIdentityPollId,
  //   });
  //   AWS.config.getCredentials((err) => {
  //     if (err) console.error(err.stack);
  //     else {
  //       if (AWS.config.credentials !== undefined) {
  //         mqtt_client.current = initMqttClient();

  //         mqtt_client.current.onConnectionLost = function (err: any) {
  //           console.info("MQTT Connection Lost : ", err);
  //         };
  //         mqtt_client.current.onMessageArrived = function (msg: any) {
  //           if (
  //             msg.destinationName ===
  //             `member/community/healthin/${props.userState.userId}`
  //           ) {
  //             console.log("Processing healthin message...");
  //             get_qr_reservation();
  //             setQrOpen(false);
  //           } else if (
  //             msg.destinationName ===
  //             `member/community/healthout/${props.userState.userId}`
  //           ) {
  //             console.log("Processing healthout message...");
  //             get_qr_reservation();
  //             setQrOpen(false);
  //             setPopOpen(true);
  //           }
  //           try {
  //             get_qr_reservation();
  //           } catch (e) {
  //             console.error("onMessageArrived error : ", e);
  //           }
  //         };
  //         var connectOptions: any = {
  //           onSuccess: function () {
  //             try {
  //               mqtt_client.current.subscribe(
  //                 `member/community/healthin/${props.userState.userId}`,
  //                 {
  //                   onSuccess: function () {
  //                     console.log(
  //                       `IoT member/healthin/${props.userState.userId}`
  //                     );
  //                   },
  //                   onFailure: function () {
  //                     console.error("subscribeFailed");
  //                   },
  //                 }
  //               );

  //               mqtt_client.current.subscribe(
  //                 `member/community/healthout/${props.userState.userId}`,
  //                 {
  //                   onSuccess: function () {
  //                     console.log(
  //                       `IoT member/healthout/${props.userState.userId}`
  //                     );
  //                   },
  //                   onFailure: function () {
  //                     console.error("subscribeFailed");
  //                   },
  //                 }
  //               );
  //             } catch (e) {
  //               console.error("subscribeFailed", e);
  //             }
  //           },
  //           useSSL: true,
  //           timeout: 3,
  //           mqttVersion: 4,
  //           keepAliveInterval: 120,
  //           onFailure: function (errorMessage: any) {
  //             console.error("connectionLost", errorMessage);
  //           },
  //         };
  //         mqtt_client.current.connect(connectOptions);
  //       }
  //     }
  //   });
  // }, []);

  // function SigV4Utils() {}

  // SigV4Utils.sign = function (key: any, msg: any) {
  //   var hash = CryptoJS.HmacSHA256(msg, key);
  //   return hash.toString(CryptoJS.enc.Hex);
  // };

  // SigV4Utils.sha256 = function (msg: any) {
  //   var hash = CryptoJS.SHA256(msg);
  //   return hash.toString(CryptoJS.enc.Hex);
  // };

  // SigV4Utils.getSignatureKey = function (
  //   key: any,
  //   dateStamp: any,
  //   regionName: any,
  //   serviceName: any
  // ) {
  //   var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
  //   var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
  //   var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
  //   var kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
  //   return kSigning;
  // };

  // const initMqttClient = () => {
  //   var time = moment.utc();
  //   var dateStamp = time.format("YYYYMMDD");
  //   var amzdate = dateStamp + "T" + time.format("HHmmss") + "Z";
  //   var service = "iotdevicegateway";
  //   var region = AWS.config.region;
  //   var accessKey = AWS.config.credentials?.accessKeyId;
  //   var secretKey = AWS.config.credentials?.secretAccessKey;
  //   var algorithm = "AWS4-HMAC-SHA256";
  //   var method = "GET";
  //   var canonicalUri = "/mqtt";
  //   var host = iotEndPoint;

  //   var credentialScope =
  //     dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
  //   var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
  //   canonicalQuerystring +=
  //     "&X-Amz-Credential=" +
  //     encodeURIComponent(accessKey + "/" + credentialScope);
  //   canonicalQuerystring += "&X-Amz-Date=" + amzdate;
  //   canonicalQuerystring += "&X-Amz-Expires=86400";
  //   canonicalQuerystring += "&X-Amz-SignedHeaders=host";

  //   var canonicalHeaders = "host:" + host + "\n";
  //   var payloadHash = SigV4Utils.sha256("");
  //   var canonicalRequest =
  //     method +
  //     "\n" +
  //     canonicalUri +
  //     "\n" +
  //     canonicalQuerystring +
  //     "\n" +
  //     canonicalHeaders +
  //     "\nhost\n" +
  //     payloadHash;

  //   var stringToSign =
  //     algorithm +
  //     "\n" +
  //     amzdate +
  //     "\n" +
  //     credentialScope +
  //     "\n" +
  //     SigV4Utils.sha256(canonicalRequest);
  //   var signingKey = SigV4Utils.getSignatureKey(
  //     secretKey,
  //     dateStamp,
  //     region,
  //     service
  //   );
  //   var signature = SigV4Utils.sign(signingKey, stringToSign);
  //   canonicalQuerystring += "&X-Amz-Signature=" + signature;
  //   if (AWS.config.credentials?.sessionToken !== undefined) {
  //     canonicalQuerystring +=
  //       "&X-Amz-Security-Token=" +
  //       encodeURIComponent(AWS.config.credentials.sessionToken);
  //   }

  //   var requestUrl =
  //     "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
  //   clientId.current = "member-client-" + uuidv4();
  //   var mqtt_client = new Paho.Client(requestUrl, clientId.current);

  //   return mqtt_client;
  // };

  const get_my_reservation1 = async () => {
    setIsLoading(true);

    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "GOLF",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setGolfNoData(false);
      setReservationGolf(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setGolfNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_reservation2 = async () => {
    setIsLoading(true);

    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "HEALTH",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setHealthNoData(false);
      setReservationHealth(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setHealthNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_reservation3 = async () => {
    setIsLoading(true);
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "STUDY",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setStudyNoData(false);
      setReservationStudy(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setStudyNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_reservation4 = async () => {
    setIsLoading(true);
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "LOCKER",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setLockerNoData(false);
      setReservationLocker(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setLockerNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const [data, setData] = useState<DataType[]>([
    {
      content1: "헬스장",
      content2: <FitnessCenterIcon />,
      content3: "/reservationhealth",
    },
    {
      content1: "골프 연습장",
      content2: <GolfCourseIcon />,
      content3: "/reservationgolf",
    },
    {
      content1: "독서실",
      content2: <AutoStoriesIcon />,
      content3: "/reservationstudy",
    },
    {
      content1: "락커",
      content2: <WidgetsIcon />,
      content3: "/reservationlocker",
    },
  ]);

  const get_qr_reservation = async () => {
    const { formatpk, formatmonthpk } = getFormattedDateTime();
    const pk = `${formatmonthpk}/INOUT`;
    const sk = formatpk;
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res3 = await mainApi.get_now_inout_counting(param);
    // console.log("res3 ;", res3.code);
    if (res3.code === "200") {
      const inInfoCount = res3.response?.result_in?.length ?? 0;

      setMemberData(inInfoCount);
      const reservationInfo: Member[] = res3.response.result_in;
      const userId = props.userState.userId;
      const isMemberExists = reservationInfo.some(
        (member) => member.userId === userId
      );
      setMemberExist(isMemberExists);
    }
  };

  const get_my_health_day = async () => {
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = `${formatmonthpk}/HEALTHDATA`;
    // const pk = `202409/HEALTHDATA`;
    const sk = "헬스기록";
    const param: any = {
      pk: pk,
      sk: sk,
    };

    const res = await mainApi.get_my_health_day(param);
    if (res.code === "200") {
      // console.log(res.response.result);
      let count = 0;
      const reservations = res.response.result[0].reservations;
      Object.values(reservations).forEach((day: any) => {
        if (day.includes(userId)) {
          count += 1;
        }
      });
      setMemberHealthDay(count);
      //순위 가져오기
      const allUserIds = Object.values(
        res.response.result[0].reservations
      ).flat();

      const counts: { [userId: string]: number } = {};
      allUserIds.forEach((userId: any) => {
        const userIdString = userId as string;
        counts[userIdString] = (counts[userIdString] || 0) + 1;
      });

      const userExerciseCounts = Object.entries(counts).map(
        ([userId, count]) => ({
          userId,
          count,
        })
      );

      const grouped: GroupedByCount = userExerciseCounts.reduce(
        (acc: GroupedByCount, { userId, count }) => {
          if (!acc[count]) acc[count] = [];
          acc[count].push({ userId, count });
          return acc;
        },
        {} as GroupedByCount
      );
      const groupedList: GroupedList[] = Object.entries(grouped)
        .map(([count, users]) => {
          return {
            userIds: users.map((user: UserExerciseCount) => user.userId),
            count: parseInt(count),
          };
        })
        .sort((a, b) => b.count - a.count);

      setGroups(groupedList);
      // console.log("groupedList:", groupedList);
      let rankFound = false;

      for (let i = 0; i < groupedList.length; i++) {
        if (groupedList[i].userIds.includes(userId)) {
          if (i < 3) {
            setUserRank(i);
          } else {
            setUserRank(i);
          }
          rankFound = true;
          break;
        }
      }
      // console.log("rank : ", userRank);
      // console.log("groupedList :", groupedList);
    }
  };
  const getMedalSrc = (rank: any) => {
    if (rank === 0) return "images/medal-1.png";
    if (rank === 1) return "images/medal-2.png";
    if (rank === 2) return "images/medal-3.png";
    return "";
  };

  const diffDay = ["25", "26", "27", "28", "29", "30", "31"];
  const [allReservView, setAllReservView] = useState(false);

  useEffect(() => {
    const { formatday } = getFormattedDateTime();
    if (diffDay.includes(formatday)) {
      setAllReservView(true);
    }
  }, []);

  const get_locker_info = async (lockerNum: string, reservPk: string) => {
    let tmepPk = "";
    if (lockerNum.startsWith("H")) {
      tmepPk = "HEALTH";
    } else if (lockerNum.startsWith("G")) {
      tmepPk = "GOLF";
    }

    const { formatday, formatmonthpk } = getFormattedDateTime();
    const checkMonth = reservPk.split("/");

    if (diffDay.includes(formatday)) {
      if (checkMonth[0] !== formatmonthpk) {
        if (lockerNum.startsWith("H")) {
          setLockerHPassword("준비중");
        } else if (lockerNum.startsWith("G")) {
          setLockerGPassword("준비중");
        }
        return;
      }
    }
    const pk = `LOCKER/${tmepPk}`;
    const sk = lockerNum;
    const param: any = {
      pk: pk,
      sk: sk,
    };

    const res = await mainApi.get_locker_list(param);
    if (res.code === "200") {
      if (lockerNum.startsWith("H")) {
        setLockerHPassword(`[${res.response.result[0]["PASSWORD"]}]`);
      } else if (lockerNum.startsWith("G")) {
        setLockerGPassword(`[${res.response.result[0]["PASSWORD"]}]`);
      }
    }
  };

  useEffect(() => {
    get_my_reservation1();
    get_my_reservation2();
    get_my_reservation3();
    get_my_reservation4();
    // get_qr_reservation();
    get_my_health_day();
  }, []);

  const reservClick = (content3: string) => {
    // 클릭 이벤트가 발생했을 때 실행할 코드
    navigate(content3);
  };

  const [qrOpen, setQrOpen] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [qrPkData, setQrPkData] = useState("");
  const [qrSkData, setQrSkData] = useState("");

  const qrClickClose = () => {
    setQrOpen(false);
    get_my_health_day();
  };

  const popClickClose = () => {
    setPopOpen(false);
  };

  const handleButtonClick = () => {
    navigate("/mobilecommunityinfo");
  };

  // const checkAndReconnectMqtt = () => {
  //   if (!mqtt_client.current.isConnected()) {
  //     console.log("MQTT 재접속");
  //     AWS.config.region = iotRegion;
  //     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //       IdentityPoolId: iotIdentityPollId,
  //     });
  //     AWS.config.getCredentials((err) => {
  //       if (err) console.error(err.stack);
  //       else {
  //         if (AWS.config.credentials !== undefined) {
  //           mqtt_client.current = initMqttClient();

  //           mqtt_client.current.onMessageArrived = function (msg: any) {
  //             console.log(`onMessageArrived : ${props.userState.userId}`);
  //             get_qr_reservation();
  //             setQrOpen(false);
  //             try {
  //               get_qr_reservation();
  //             } catch (e) {
  //               console.error("onMessageArrived error : ", e);
  //             }
  //           };
  //           var connectOptions: any = {
  //             onSuccess: function () {
  //               try {
  //                 mqtt_client.current.subscribe(
  //                   `member/community/healthin/${props.userState.userId}`,
  //                   {
  //                     onSuccess: function () {
  //                       console.log(
  //                         `IoT member/community/${props.userState.userId}`
  //                       );
  //                     },
  //                     onFailure: function () {
  //                       console.error("subscribeFailed");
  //                     },
  //                   }
  //                 );
  //               } catch (e) {
  //                 console.error("subscribeFailed", e);
  //               }
  //             },
  //             useSSL: true,
  //             timeout: 3,
  //             mqttVersion: 4,
  //             keepAliveInterval: 120,
  //             onFailure: function (errorMessage: any) {
  //               console.error("connectionLost", errorMessage);
  //             },
  //           };
  //           mqtt_client.current.connect(connectOptions);
  //         }
  //       }
  //     });
  //   }
  // };

  const qrClickOpen = (userId: string, sk: string) => {
    // checkAndReconnectMqtt();

    setQrPkData(userId);
    setQrSkData(sk);
    setTimeout(() => {
      setQrOpen(true);
    }, 100);
  };
  const qrOutClickOpen = () => {
    setQrPkData(reservationHealth[0].userId);
    setQrSkData(reservationHealth[0].sk);
    setQrOpen(true);
  };
  const [rankOpen, setRankOpen] = useState(false);
  const fnHealthRankOpen = () => {
    setRankOpen(true);
  };
  const healRankClose = () => {
    setRankOpen(false);
  };

  const fnScreenPage = () => {
    navigate("/reservationscreen");
  };

  const allreserv = () => {
    navigate("/reservationnext");
  };
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box sx={{ padding: 2 }}>
          {memberExist && (
            <Box
              sx={{
                position: "relative", // Flexbox를 사용하여 내용을 중앙에 배치
                display: "inline-block",
                maxWidth: "100%",
              }}
            >
              <img
                src="/images/healthin08.png"
                alt="Health"
                style={{
                  display: "block",
                  maxWidth: "100%", // Box 내에서 최대 너비 100%
                  height: "auto", // 이미지의 원래 비율을 유지
                  borderRadius: "16px 16px 0 0", // 위 모서리만 둥글게
                }}
              />
              <img
                src="/images/healthin05.png"
                alt="Health"
                style={{
                  display: "block",
                  maxWidth: "100%", // Box 내에서 최대 너비 100%
                  height: "auto", // 이미지의 원래 비율을 유지
                  borderRadius: "0 0 16px 16px", // 아래 모서리만 둥글게
                }}
                onClick={() => qrOutClickOpen()}
              />
              <Typography
                variant="button"
                sx={{
                  position: "absolute", // 텍스트에 절대적 위치 설정
                  bottom: 16, // 하단에서부터 16px 위치
                  right: 16, // 우측에서부터 16px 위치

                  display: "flex", // Flexbox 레이아웃 사용
                  alignItems: "center", // 세로 방향 중앙 정렬
                  justifyContent: "center", // 가로 방향 중앙 정렬
                  color: "white", // 텍스트 색상 설정
                  fontSize: "18px", // 폰트 크기 설정
                }}
              >
                이번 달 {memberHealthDay}일째 운동
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="button"
              gutterBottom
              sx={{
                textAlign: "left",
                margin: "auto",
                mt: 2,
                ml: 3,
                mr: 1,
              }}
            >
              커뮤니티 시설 신청
            </Typography>
            {/* 요금표 확인 버튼 */}
            <Button
              variant="contained"
              size="small"
              onClick={handleButtonClick}
            >
              요금표 확인
            </Button>
          </Box>

          <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              {data.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Card
                    sx={{
                      minWidth: 100,
                      borderRadius: "16px",
                      boxShadow: "none",
                      borderColor: "#f0f0f0",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        textAlign="center"
                        onClick={() => reservClick(item.content3)}
                      >
                        <Typography
                          sx={{ fontSize: 14, mb: 2 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          {item.content1}
                        </Typography>
                        {item.content2}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {allReservView && (
          <>
            <Box
              sx={{
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => allreserv()}
            >
              <img
                src="/images/mainclick002.jpg"
                alt="Health"
                style={{
                  display: "block",
                  maxWidth: "100%", // Box 내에서 최대 너비 100%
                  height: "auto", // 이미지의 원래 비율을 유지
                  borderRadius: "16px", // 위 모서리만 둥글게
                }}
              />
            </Box>
            <Box
              sx={{
                width: "90%",
                textAlign: "center",
              }}
            >
              <Typography
                variant="button"
                gutterBottom
                sx={{
                  textAlign: "left",
                  margin: "auto",
                  whiteSpace: "pre-line",
                  color: "red", // 붉은색 강조
                }}
              >
                * 독서실 보수공사 진행 중입니다.
              </Typography>
              <Alert
                severity="error"
                icon={false}
                sx={{
                  textAlign: "left", // 텍스트를 좌측으로 정렬
                  alignItems: "flex-start", // 내용을 상단에 정렬
                }}
              >
                * 다음달 독서실 이용료는 무료입니다.
                <br></br>
                독서실 누수로 천장 보수공사가 늦어지고 있습니다.
                <br></br>독서실은 예약이 가능하지만 공사가 끝나야 사용
                가능합니다.
                <br></br>
                이점 양해 부탁드립니다.
              </Alert>
            </Box>
          </>
        )}

        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {healthNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_02.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <ListItemSecondaryAction
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      borderRadius: "20px",
                      height: "25px",
                      padding: "0 8px",
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      fnHealthRankOpen();
                    }}
                  >
                    운동랭킹
                  </Button>
                </ListItemSecondaryAction>

                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationHealth.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_02.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <ListItemText
                          key={idx}
                          primary={details.reservDt}
                          secondary={
                            <Fragment>
                              <Typography
                                sx={{ display: "inline", mr: 1 }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {details.deviceNum}
                              </Typography>
                              <Typography
                                sx={{ display: "inline", color: "red" }}
                                component="span"
                                variant="body2"
                              >
                                {memberHealthDay}
                              </Typography>
                              일째 운동 성공
                              {userRank < 3 && (
                                <span
                                  style={{
                                    display: "inline-block",
                                    marginLeft: "2px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Avatar
                                    alt="Medal"
                                    src={getMedalSrc(userRank)}
                                    sx={{ width: 20, height: 20 }}
                                    component="span"
                                  />
                                </span>
                              )}
                            </Fragment>
                          }
                        />
                      )
                    )}
                    <ListItemSecondaryAction
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CropFreeIcon
                        onClick={() =>
                          qrClickOpen(reservData.userId, reservData.sk)
                        }
                        sx={{
                          marginRight: "8px",
                          cursor: "pointer",
                          border: "1px solid red",
                          borderRadius: "10%",
                        }}
                      />

                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          borderRadius: "20px",
                          height: "25px",
                          padding: "0 8px",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          fnHealthRankOpen();
                        }}
                      >
                        운동랭킹
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {golfNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_03.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationGolf.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_03.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <ListItemText
                          key={idx}
                          primary={details.reservDt}
                          secondary={
                            <Fragment>
                              <Typography
                                sx={{ display: "inline", mr: 2 }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {details.deviceNum}
                              </Typography>
                              {/* {details.reservOption} */}
                            </Fragment>
                          }
                        />
                      )
                    )}
                    <ListItemSecondaryAction
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          marginRight: "8px",
                          cursor: "pointer",
                          border: "1px solid red",
                          borderRadius: "3%",
                          padding: "3px 6px",
                        }}
                        onClick={() => fnScreenPage()}
                      >
                        스크린 예약
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          borderRadius: "20px",
                          height: "25px",
                          padding: "0 8px",
                          boxShadow: "none",
                        }}
                      >
                        회원
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {studyNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_04.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationStudy.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_04.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <ListItemText
                          key={idx}
                          primary={details.reservDt}
                          secondary={
                            <Fragment>
                              <Typography
                                sx={{ display: "inline", mr: 2 }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {details.deviceNum}
                              </Typography>
                              {details.reservOption}
                            </Fragment>
                          }
                        />
                      )
                    )}
                    <ListItemSecondaryAction>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          borderRadius: "20px",
                          height: "25px",
                          padding: "0 8px",
                          boxShadow: "none",
                        }}
                      >
                        회원
                      </Button>
                      {/* 'Confirm' button */}
                    </ListItemSecondaryAction>
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {lockerNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_05.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationLocker.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_05.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <Fragment key={idx}>
                          <ListItemText
                            primary={details.reservDt}
                            secondary={
                              <Fragment>
                                <Typography
                                  sx={{ display: "inline", mr: 2 }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {details.deviceNum}
                                </Typography>
                                {details.reservOption}
                                {details.reservOption.includes("H")
                                  ? ` ${lockerHPassword}`
                                  : details.reservOption.includes("G")
                                  ? ` ${lockerGPassword}`
                                  : null}
                              </Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                borderRadius: "20px",
                                height: "25px",
                                padding: "0 8px",
                                boxShadow: "none",
                                marginLeft: "10px",
                              }}
                              onClick={() =>
                                get_locker_info(
                                  details.reservOption,
                                  reservData.pk
                                )
                              }
                            >
                              패스워드 확인
                            </Button>
                          </ListItemSecondaryAction>
                        </Fragment>
                      )
                    )}
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>
        {/* <hr
          style={{ borderColor: "#f0f0f0", borderWidth: "0.2px", width: "85%" }}
        /> */}
      </Stack>

      <Toast ref={toastRef} />
      <CameraQrcodePage
        cameraOpen={qrOpen}
        userState={props.userState}
        qrPkData={qrPkData}
        qrSkData={qrSkData}
        cameraClickClose={qrClickClose}
      />
      <HealthRankPage
        rankOpen={rankOpen}
        userState={props.userState}
        qrPkData={qrPkData}
        qrSkData={qrSkData}
        rankClose={healRankClose}
        groupedList={groups}
      />
      <HealthOutPage
        popOpen={popOpen}
        userState={props.userState}
        popClickClose={popClickClose}
      />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MobileMainPage;
