import { useEffect, useRef, useState, ReactNode } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import * as XLSX from "xlsx";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CryptoJS from "crypto-js";
interface propsType {
  userState: userState;
}
const mainApi = new HttpMainApi();

const LockerPassWord = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState<any>([]);
  const [lockerNum, setLockerNum] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setDataList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_locker_list = async () => {
    setIsLoading(true);
    setDataList([]);
    const pk = `LOCKER/${selectData}`;
    let sk = "";
    if (selectData === "HEALTH") {
      if (!lockerNum.startsWith("H")) {
        sk = "H" + lockerNum;
      } else {
        sk = lockerNum;
      }
    } else {
      if (!lockerNum.startsWith("G")) {
        sk = "G" + lockerNum;
      } else {
        sk = lockerNum;
      }
    }

    const param: any = {
      pk: pk,
      sk: sk,
    };

    const res = await mainApi.get_locker_list(param);
    if (res.code === "200") {
      setDataList(res.response.result);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const renderSaveButton = (params: any) => (
    <Button
      color="primary"
      onClick={() =>
        handrUpdateButton(params.row.pk, params.row.sk, params.row.PASSWORD)
      }
    >
      <SaveAsIcon />
    </Button>
  );

  const handrUpdateButton = async (
    pk: string,
    sk: string,
    PASSWORD: string
  ) => {
    const { formattedDateTime } = getFormattedDateTime();
    if (PASSWORD.length > 4) {
      alert("패스워드는 4자리를 넘을수 없습니다.");
      return;
    }
    const confirmCancel = window.confirm(sk + ` 패드워드를 변경하시겠습니끼?`);
    if (confirmCancel) {
      const param: any = {
        pk: pk,
        sk: sk,
        PASSWORD: PASSWORD,
        updateDt: formattedDateTime,
      };

      const res = await mainApi.update_locker_password(param);
      if (res.code === "200") {
        alert("패스워드가 수정 되었습니다.");
        get_locker_list();
      } else if (res.code === "400") {
        alert("정보 업데이트에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "sk",
      headerName: "락커 번호",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "PASSWORD",
      headerName: "패스워드",
      width: 110,
      editable: true,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Update",
      headerName: "수정",
      width: 110,
      editable: false,
      renderCell: renderSaveButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "updateDt",
      headerName: "패스워드 수정일",
      type: "number",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userName",
      headerName: "(마지막 사용자) 이름",
      width: 250,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "readAddress",
      headerName: "동/호",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lockerInputDt",
      headerName: "락커 신청일",
      type: "number",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  const [selectData, setSelectData] = useState("HEALTH");
  const handleChange = (event: SelectChangeEvent) => {
    setSelectData(event.target.value as string);
  };

  const downloadExcel = (accountList: any[], columns: any[]) => {
    // 각 행에 대해 엑셀 파일에 포함될 값을 계산
    const dataForExcel = accountList.map((row) => {
      let newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
        if (col.valueGetter) {
          newRow[col.headerName] = col.valueGetter({ row });
        } else {
          newRow[col.headerName] = row[col.field];
        }
      });
      return newRow;
    });

    // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts");
    XLSX.writeFile(workbook, "accounts.xlsx");
  };
  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          margin: "10px",
          pt: "25px !important",
          pb: "25px !important",
        }}
      >
        <Box sx={{ flex: "1 1 100%" }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            락커 관리 (모바일 겸용)
          </Typography>
        </Box>
        <hr
          style={{ borderColor: "#ddd", borderWidth: "0.3px", width: "100%" }}
        />
        <Stack direction="row" spacing={2} sx={{ width: "100%", mb: 2 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ width: "100%", justifyContent: "flex-end" }}
          >
            <Box sx={{ flex: "1 1 10%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">시설</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectData}
                  label="시설"
                  onChange={handleChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"HEALTH"}>헬스장</MenuItem>
                  <MenuItem value={"GOLF"}>골프장</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              id="Id"
              value={lockerNum}
              placeholder="락커 번호"
              variant="outlined"
              label="락커 번호(01,02..)"
              autoFocus
              type="text"
              autoComplete="off"
              inputProps={{
                enterKeyHint: "Enter",
                style: { padding: "11px 14px" },
              }}
              sx={{
                height: 46,
                flex: "1 1 auto",
              }}
              onChange={(e) => {
                setLockerNum(e.target.value);
              }}
            />
            <Box sx={{ flex: "1 1 20%" }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  get_locker_list();
                }}
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  height: "46px",
                }}
              >
                <SearchIcon />
              </Button>
            </Box>
          </Stack>
        </Stack>

        <Box sx={{ flexGrow: 1, width: "100%" }}>
          {/* Box의 너비를 100%로 설정 */}
          <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
            {/* Grid 컨테이너의 너비를 100%로 설정하고 마진을 제거 */}
            <Grid
              item
              xs={12}
              style={{
                width: "100%",
                height: 500,
                paddingLeft: 0,
              }}
            >
              {/* Grid 아이템의 너비를 100%로 설정 */}
              <DataGrid
                rows={dataList}
                columns={columns}
                disableRowSelectionOnClick
                style={{ width: "100%", height: "100%" }}
                getRowId={(row) => (row.sk ? row.sk.toString() : "")}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default LockerPassWord;
