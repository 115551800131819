import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
interface propsType {
  userState: userState;
}
interface Month {
  value: string;
  label: string;
}
const mainApi = new HttpMainApi();

const AccountList = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [accountList, setAccountList] = useState<any>([]);
  const [userName, setUserName] = useState("");
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setAccountList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const formatAddress = (address: string): string => {
    // 주소를 동과 호로 분리
    const [dong, ho] = address.split(" ");

    // 호수가 3자리 숫자일 경우 앞에 '0'을 추가
    const formattedHo = ho?.length === 4 ? `0${ho}` : ho;

    // 포맷된 주소 반환
    return `${dong} ${formattedHo}`;
  };

  const get_account_list = async () => {
    setIsLoading(true);
    setAccountList([]);
    const pk = comunity;
    const param: any = {
      pk: pk,
      selectMonth: selectMonth,
      selectState: selectState,
    };

    let res;
    if (comunity === "ALL") {
      res = await mainApi.get_account_all_list(param);
    } else {
      res = await mainApi.get_account_list(param);
    }

    if (res.code === "200") {
      const result = res.response.result;
      // 주소별로 communityPrice 합산
      const totalPriceByAddr = result.reduce((acc: any, item: any) => {
        const addr = formatAddress(item.userAddr);
        if (!acc[addr]) {
          acc[addr] = {
            userAddr: addr,
            communityPrice: 0,
          }; // 초기화
        }
        // console.log("item.communityPrice : ", item.communityPrice);
        acc[addr].communityPrice += parseFloat(item.communityPrice); // Decimal 객체를 숫자로 변환하여 더하기

        return acc;
      }, {});

      // 객체를 배열로 변환하여 최종 결과 생성
      const resultArray = Object.keys(totalPriceByAddr).map(
        (key) => totalPriceByAddr[key]
      );

      console.log("Result Array:", resultArray);
      setAccountList(resultArray);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const [comunity, setComunity] = useState("ALL");
  const handleChange = (event: SelectChangeEvent) => {
    setAccountList([]);
    setComunity(event.target.value as string);
  };
  const [selectState, setSelectState] = useState("신청");
  const handleStateChange = (event: SelectChangeEvent) => {
    setSelectState(event.target.value as string);
  };

  const generateMonths = () => {
    const months = [];
    const currentDate = new Date();
    for (let i = -1; i < 6; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const yearMonth = `${date.getFullYear()}${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      const formattedMonth = `${date.getFullYear()}년 ${String(
        date.getMonth() + 1
      ).padStart(2, "0")}월`;

      months.push({ value: yearMonth, label: formattedMonth });
    }

    return months;
  };
  const [selectMonth, setSelectMonth] = useState<string>("");
  const [months, setMonths] = useState<Month[]>([]); // 여기에서 타입을 명시적으로 지정

  const handleMonthChange = (event: SelectChangeEvent) => {
    setSelectMonth(event.target.value as string);
  };

  useEffect(() => {
    const generatedMonths = generateMonths();
    setMonths(generatedMonths);
    if (generatedMonths.length > 0) {
      setSelectMonth(generatedMonths[1].value);
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "userAddr",
      headerName: "주소",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: true, // 정렬 활성화
    },
    {
      field: "communityPrice",
      headerName: "청구 금액",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  const downloadExcel = (accountList: any[], columns: any[]) => {
    // 각 행에 대해 엑셀 파일에 포함될 값을 계산
    const dataForExcel = accountList.map((row) => {
      let newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
        if (col.valueGetter) {
          newRow[col.headerName] = col.valueGetter({ row });
        } else {
          newRow[col.headerName] = row[col.field];
        }
      });
      return newRow;
    });

    // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts");
    XLSX.writeFile(workbook, "accounts.xlsx");
  };

  const addCommasToNumber = (value: number): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  useEffect(() => {
    const sumPrice = accountList.reduce(
      (acc: number, curr: any) => acc + (curr.communityPrice || 0),
      0
    );
    setTotalPrice(addCommasToNumber(sumPrice));
  }, [accountList]);

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box sx={{ position: "absolute", top: "60px", right: "20px" }}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">시설</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={comunity}
                  label="시설"
                  onChange={handleChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"ALL"}>전체</MenuItem>
                  <MenuItem value={"GOLF"}>골프장</MenuItem>
                  <MenuItem value={"HEALTH"}>헬스장</MenuItem>
                  <MenuItem value={"STUDY"}>독서실</MenuItem>
                  <MenuItem value={"HEALTHLOCKER"}>헬스장락커</MenuItem>
                  <MenuItem value={"GOLFLOCKER"}>골프장락커</MenuItem>
                  <MenuItem value={"SCREENROOM"}>스크린게임</MenuItem>
                  <MenuItem value={"SCREEN"}>스크린타석</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">상태</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectState}
                  label="상태"
                  onChange={handleStateChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"신청"}>신청</MenuItem>
                  <MenuItem value={"취소"}>취소</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">기준</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectMonth}
                  label="정산일"
                  onChange={handleMonthChange}
                  sx={{ height: 46 }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                get_account_list();
              }}
              sx={{
                borderRadius: "4px",
                width: "54px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => downloadExcel(accountList, columns)}
              sx={{
                borderRadius: "4px",
                width: "74px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              EXCEL
              <FileDownloadIcon />
            </Button>
          </Stack>
        </Box>
        <Box className="sub-header-root" sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            관리비 청구 관리
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid>
            <DataGrid
              rows={accountList}
              columns={columns}
              disableRowSelectionOnClick
              style={{ height: 700 }}
              getRowId={(row) => (row.userAddr ? row.userAddr.toString() : "")}
            />
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}></Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>총 금액: {totalPrice} 원</div>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default AccountList;
