// getFormattedDateTime.ts
export const getFormattedDateTime = (): {
  formatpk: string;
  formattedDate: string;
  formattedDateTime: string;
  compactDateTime: string;
  formatmonthpk: string;
  formatdaypk: string;
  formathourspk: string;
  qrcodehoures: string;
  formathhmmss: string;
  formattedTime: string;
  ssTime: string;
  formathoursTime: string;
  formatday: string;
  formatyear: string;
} => {
  const now = new Date();

  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const milliseconds = now.getMilliseconds().toString().padStart(3, "0");
  const hourstemp = now.getHours() + 2;
  const formathoursTime = hourstemp.toString().padStart(2, "0") + ":00";

  const formattedDateTime = `${year}년 ${month}월 ${day}일  ${hours}시 ${minutes}분`;
  const formattedDate = `${year}년 ${month}월 ${day}일`;
  const formattedTime = `${hours}시 ${minutes}분`;
  const compactDateTime = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
  const formatpk = `${year}${month}${day}`;
  const formatmonthpk = `${year}${month}`;
  const formatdaypk = `${day}${hours}${minutes}${seconds}${milliseconds}`;
  const formathourspk = `${hours}${minutes}${seconds}${milliseconds}`;
  const qrcodehoures = `${year}${month}${day}${hours}`;
  const formathhmmss = `${hours}${minutes}${seconds}`;
  const formatday = `${day}`;
  const formatyear = `${year}`;

  const ssTime = `${seconds}`;
  return {
    formatpk,
    formattedDate,
    formattedDateTime,
    compactDateTime,
    formatmonthpk,
    formatdaypk,
    formathourspk,
    qrcodehoures,
    formathhmmss,
    formattedTime,
    ssTime,
    formathoursTime,
    formatday,
    formatyear,
  };
};
