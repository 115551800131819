import { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme, useMediaQuery } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { appConfig } from "../../config/Config";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const MemberPassword = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [findPassword, setFindPassword] = useState(false);
  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserName("");
      setUserId("");
      setPassword("");
      setPassword2("");
      setFindPassword(false);
    };
  }, []);

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };

  const fncGetPwd = async () => {
    if (userName === "" || userName === undefined) {
      toastRef.current?.toast("이름을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (userId === "" || userId === undefined) {
      toastRef.current?.toast("아이디를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    setIsLoading(true);
    const pk = "COMMUNITY/USER";
    const userLevel = "회원"; //추후 수정
    const userType = "일반회원"; //관리자, 일반회원
    const param: any = {
      pk: pk,
      sk: userId,
      userName: userName,
    };
    const res = await mainApi.get_password_check(param);

    if (res.code === "200") {
      setFindPassword(true);
    } else if (res.code === "301") {
      setFindPassword(false);
      alert(`[${userId}]은 탈퇴 회원입니다.`);
    } else if (res.code === "400") {
      setFindPassword(false);
      alert("일치하는 회원이 없습니다.");
    } else {
      setFindPassword(false);
      alert(
        `알 수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const fncChPwd = async () => {
    if (password === "" || password === undefined) {
      toastRef.current?.toast("비밀번호를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password2 === "" || password2 === undefined) {
      toastRef.current?.toast("비밀번호 확인을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password !== password2) {
      toastRef.current?.toast("비밀번호가 일치하지 않습니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return false;
    }

    const confirmCancel = window.confirm("비밀번호를 재등록 하시겠습니까?");

    if (confirmCancel) {
      const encryptedPassword = hashPassword(password);
      setIsLoading(true);
      const pk = "COMMUNITY/USER";
      const param: any = {
        pk: pk,
        sk: userId,
        password: encryptedPassword,
      };
      const res = await mainApi.update_user_password(param);

      if (res.code === "200") {
        alert("비밀번호 변경이 완료되었습니다.");
        navigate("/memberlogin");
      } else {
        setFindPassword(false);
        alert(
          `알 수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
    setIsLoading(false);
  };

  const handleImageClick = () => {
    navigate("/memberlogin");
  };

  return (
    <>
      <Box
        sx={{
          p: isMobile ? 1 : 2,
          display: "flex",
          height: "calc(100vh - 100px)",
        }}
      >
        <Box
          component={"div"}
          sx={{
            width: "90%",
            maxWidth: isMobile ? "100%" : "40rem",
            margin: "auto",
          }}
        >
          <Paper elevation={4} sx={{ p: 2 }}>
            <Box
              sx={{
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              <img
                className="logo-img"
                src="/images/password01.png"
                alt="logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
                onClick={handleImageClick}
              />
            </Box>
            <Stack spacing={2} direction="column">
              {!findPassword ? (
                <>
                  <TextField
                    id="userName"
                    value={userName}
                    placeholder="홍길동"
                    variant="outlined"
                    label="이름"
                    autoFocus
                    fullWidth
                    required
                    type="text"
                    autoComplete="off"
                    inputProps={{
                      enterKeyHint: "Enter",
                      style: { padding: "12px 16px 10px 16px" },
                    }}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />

                  <TextField
                    id="userId"
                    value={userId}
                    placeholder="아이디를 입력해주세요"
                    variant="outlined"
                    label="아이디(영문 또는 숫자)"
                    fullWidth
                    required
                    type="text"
                    autoComplete="off"
                    inputProps={{
                      enterKeyHint: "Enter",
                      style: { padding: "12px 16px 10px 16px" },
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setUserId(value.replace(/[^a-z0-9]/g, "").slice(0, 15));
                    }}
                  />
                  <Button variant="contained" onClick={fncGetPwd}>
                    기본 정보 확인
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    id="password"
                    value={password}
                    placeholder="비밀번호를 입력하세요"
                    variant="outlined"
                    label="비밀번호"
                    fullWidth
                    autoFocus
                    required
                    type="password"
                    autoComplete="off"
                    inputProps={{
                      enterKeyHint: "Enter",
                      style: { padding: "12px 16px 10px 16px" },
                    }}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <TextField
                    id="password2"
                    value={password2}
                    placeholder="동일한 비밀번호를 입력하세요"
                    variant="outlined"
                    label="비밀번호 확인"
                    fullWidth
                    required
                    type="password"
                    autoComplete="off"
                    inputProps={{
                      enterKeyHint: "Enter",
                      style: { padding: "12px 16px 10px 16px" },
                    }}
                    onChange={(e) => {
                      setPassword2(e.target.value);
                    }}
                  />
                  <Button variant="contained" onClick={fncChPwd}>
                    비밀번호 변경
                  </Button>
                </>
              )}
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MemberPassword;
