export interface userState {
  userId: string;
  userName: string;
  userDong: string;
  userHo: string;
  userLevel: string;
  readInputDt: string;
  userType: string;
  readAddress: string;
  isAuth: boolean;
}
export interface IMenuItem {
  name: string;
  iconType: string;
  icon: any;
  path: string;
  adminOnly?: boolean;
  children?: Array<IMenuItem>;
}

export let aiApiUrl =
  "https://b8v073ogbg.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain";
