import { useState } from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { userState } from "../../interface/MainInterface";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import WidgetsIcon from "@mui/icons-material/Widgets";
import "react-calendar/dist/Calendar.css";
import ReservationStudyEnd from "./ReservationStudyEnd";
import ReservationHealthEnd from "./ReservationHealthEnd";
import ReservationLockerEnd from "./ReservationLockerEnd";
import ReservationGolfEnd from "./ReservationGolfEnd";
interface propsType {
  userState: userState;
}
interface ReservationDetails {
  userName: string;
  userId: string;
  inputDt: string;
  reservDt: string;
  reservTime: string;
  deviceNum: string;
}

interface Reservations {
  [key: string]: ReservationDetails;
}

interface ReservationInfo {
  sk: string;
  pk: string;
  reservations: Reservations;
}

interface Reservation {
  sk: string;
  pk: string;
  reservInfo: ReservationInfo;
  state: string;
}

const ReservationEnd = (props: propsType) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
          marginTop: "2em",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon tabs example"
        >
          <Tab icon={<FitnessCenterIcon />} label="헬스장" />
          <Tab icon={<GolfCourseIcon />} label="골프연습장" />
          <Tab icon={<AutoStoriesIcon />} label="독서실" />
          <Tab icon={<WidgetsIcon />} label="락커" />
        </Tabs>
      </Stack>
      <Stack>
        {value === 0 && <ReservationHealthEnd userState={props.userState} />}
        {value === 1 && <ReservationGolfEnd userState={props.userState} />}
        {value === 2 && <ReservationStudyEnd userState={props.userState} />}
        {value === 3 && <ReservationLockerEnd userState={props.userState} />}
      </Stack>
    </>
  );
};

export default ReservationEnd;
