import {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import * as CryptoJS from "crypto-js";
import * as Paho from "paho-mqtt";
import * as AWS from "aws-sdk";
import { Button } from "@mui/material";
import { appConfig } from "../../config/Config";

const QrcodeIoTClient = () => {
  const iotRegion: string = appConfig.iotRegion;
  const iotIdentityPollId: string = appConfig.iotIdentityPollId;
  const iotEndPoint: string = appConfig.iotEndPoint;
  let clientId = useRef<string>("");
  let mqtt_client: any = useRef();
  useEffect(() => {
    AWS.config.region = iotRegion;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: iotIdentityPollId,
    });
    AWS.config.getCredentials((err) => {
      if (err) console.error(err.stack);
      else {
        if (AWS.config.credentials !== undefined) {
          mqtt_client.current = initMqttClient();

          mqtt_client.current.onConnectionLost = function (err: any) {
            console.info("MQTT Connection Lost : ", err);
          };

          var connectOptions: any = {
            onSuccess: function () {
              try {
                mqtt_client.current.subscribe(`member/coummnity/count`, {
                  onSuccess: function () {
                    mqtt_client.current.publish(
                      "member/coummnity/count",
                      JSON.stringify("테스트2")
                    );
                  },
                  onFailure: function () {
                    console.error("subscribeFailed");
                  },
                });
              } catch (e) {
                console.error("subscribeFailed", e);
              }
            },
            useSSL: true,
            timeout: 3,
            mqttVersion: 4,
            onFailure: function (errorMessage: any) {
              console.error("connectionLost", errorMessage);
            },
          };
          mqtt_client.current.connect(connectOptions);
        }
      }
    });
  }, []);

  function SigV4Utils() {}

  SigV4Utils.sign = function (key: any, msg: any) {
    var hash = CryptoJS.HmacSHA256(msg, key);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.sha256 = function (msg: any) {
    var hash = CryptoJS.SHA256(msg);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.getSignatureKey = function (
    key: any,
    dateStamp: any,
    regionName: any,
    serviceName: any
  ) {
    var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
    var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
    var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
    var kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
    return kSigning;
  };

  const initMqttClient = () => {
    var time = moment.utc();
    var dateStamp = time.format("YYYYMMDD");
    var amzdate = dateStamp + "T" + time.format("HHmmss") + "Z";
    var service = "iotdevicegateway";
    var region = AWS.config.region;
    var accessKey = AWS.config.credentials?.accessKeyId;
    var secretKey = AWS.config.credentials?.secretAccessKey;
    var algorithm = "AWS4-HMAC-SHA256";
    var method = "GET";
    var canonicalUri = "/mqtt";
    var host = iotEndPoint;

    var credentialScope =
      dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
    var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
    canonicalQuerystring +=
      "&X-Amz-Credential=" +
      encodeURIComponent(accessKey + "/" + credentialScope);
    canonicalQuerystring += "&X-Amz-Date=" + amzdate;
    canonicalQuerystring += "&X-Amz-Expires=86400";
    canonicalQuerystring += "&X-Amz-SignedHeaders=host";

    var canonicalHeaders = "host:" + host + "\n";
    var payloadHash = SigV4Utils.sha256("");
    var canonicalRequest =
      method +
      "\n" +
      canonicalUri +
      "\n" +
      canonicalQuerystring +
      "\n" +
      canonicalHeaders +
      "\nhost\n" +
      payloadHash;

    var stringToSign =
      algorithm +
      "\n" +
      amzdate +
      "\n" +
      credentialScope +
      "\n" +
      SigV4Utils.sha256(canonicalRequest);
    var signingKey = SigV4Utils.getSignatureKey(
      secretKey,
      dateStamp,
      region,
      service
    );
    var signature = SigV4Utils.sign(signingKey, stringToSign);
    canonicalQuerystring += "&X-Amz-Signature=" + signature;
    if (AWS.config.credentials?.sessionToken !== undefined) {
      canonicalQuerystring +=
        "&X-Amz-Security-Token=" +
        encodeURIComponent(AWS.config.credentials.sessionToken);
    }

    var requestUrl =
      "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
    clientId.current = "member-client-" + uuidv4();
    var mqtt_client = new Paho.Client(requestUrl, clientId.current);

    return mqtt_client;
  };

  return <></>;
};

export default QrcodeIoTClient;
