import { useState, useRef, useEffect } from "react";
import * as AuthMain from "../../auth/AuthMain";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { useTheme, useMediaQuery, Typography } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { appConfig } from "../../config/Config";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const MemberLogin = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [rememberId, setRememderId] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserId("");
      setPassword("");
    };
  }, []);

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };

  const fncSignIn = async () => {
    if (userId === "" || userId === undefined) {
      toastRef.current?.toast("아이디를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password === "" || password === undefined) {
      toastRef.current?.toast("비밀번호를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const encryptedPassword = hashPassword(password);
    setIsLoading(true);
    const pk = "COMMUNITY/USER";
    const param: any = {
      pk: pk,
      sk: userId,
      password: encryptedPassword,
    };
    const res = await mainApi.get_member_login(param);
    if (res.code === "200") {
      procSesson(res.response.result);
    } else if (res.code === "201") {
      alert(res.response.result);
    } else if (res.code === "301") {
      alert(res.response.result);
    } else if (res.code === "401") {
      alert(res.response.result);
    } else if (res.code === "400") {
      alert(res.response.result);
    } else {
      alert(
        `알 수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  // 로그인 성공
  const procSesson = (sessionData: any) => {
    toastRef.current?.toast("로그인 성공", "info", 3000, {
      vertical: "top",
      horizontal: "center",
    });
    AuthMain.setSession(sessionData);
    // 아이디 저장 기억하기
    const rememberIdObj: any = {
      userId: sessionData.userId,
      remember: "TRUE",
    };
    const objRememberId = JSON.stringify(rememberIdObj);
    if (rememberId) {
      window.localStorage.setItem(appConfig.rememberidSSName, objRememberId);
    }

    window.location.href = "/mobilemain";
  };

  const handleJoinClick = () => {
    navigate("/memberjoin");
  };
  const handlePasswordClick = () => {
    navigate("/memberpassword");
  };

  return (
    <>
      <Box
        sx={{
          p: isMobile ? 1 : 2,
          display: "flex",
          height: "calc(100vh - 100px)",
        }}
      >
        <Box
          component={"div"}
          sx={{
            width: "90%",
            maxWidth: isMobile ? "100%" : "40rem",
            margin: "auto",
          }}
        >
          <Paper elevation={4} sx={{ p: 2 }}>
            <Box
              sx={{
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              <img
                className="logo-img"
                src="/images/login_02.png"
                alt="logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Box>
            <Stack spacing={2} direction="column">
              <TextField
                id="userId"
                value={userId}
                placeholder="아이디를 입력해주세요"
                variant="outlined"
                label="아이디"
                autoFocus
                fullWidth
                required
                type="text"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
              />
              <TextField
                id="password"
                value={password}
                placeholder="비밀번호를 입력하세요"
                variant="outlined"
                label="비밀번호"
                fullWidth
                required
                type="password"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    fncSignIn();
                  }
                }}
              />

              <Button variant="contained" onClick={fncSignIn}>
                로그인
              </Button>

              <Box textAlign="right">
                <Link onClick={handleJoinClick} variant="body2">
                  회원가입
                </Link>
                <span> | </span>
                <Link onClick={handlePasswordClick} variant="body2">
                  비밀번호 찾기
                </Link>
              </Box>
              <Box textAlign="left">
                <Typography
                  variant="button"
                  sx={{
                    display: "flex", // Flexbox 레이아웃 사용
                    alignItems: "center", // 수직 중앙 정렬
                  }}
                >
                  <ErrorOutlineIcon sx={{ mr: 1 }} /> 주말 가입 신청 시 월요일
                  승인됩니다.
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MemberLogin;
