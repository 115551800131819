import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Toast from "../../utils/Toast";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { float } from "aws-sdk/clients/cloudfront";
interface propsType {
  userState: userState;
}
interface Month {
  value: string;
  label: string;
}
const mainApi = new HttpMainApi();

const UseDetailList = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [accountList, setAccountList] = useState<any>([]);
  const [userName, setUserName] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setAccountList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_account_list = async () => {
    setIsLoading(true);
    setAccountList([]);
    const pk = comunity;
    const param: any = {
      pk: pk,
      selectMonth: selectMonth,
      selectState: selectState,
    };

    let res;
    res = await mainApi.get_account_list(param);
    if (res.code === "200") {
      setAccountList(res.response.result);
      // console.log("res.response.result[0]: ", res.response.result);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  function formatInputDt(inputDt: string): string {
    // 정규 표현식을 사용하여 년, 월, 일을 추출합니다.
    const matches = inputDt.match(/(\d{4})년 (\d{2})월 (\d{2})일/);

    if (matches) {
      // matches[1]은 년, matches[2]는 월, matches[3]는 일에 해당합니다.
      // 추출된 값들을 연결하여 반환합니다.
      return `${matches[1]}${matches[2]}${matches[3]}`;
    } else {
      // 일치하는 부분이 없으면 빈 문자열을 반환합니다.
      return "";
    }
  }
  function findLaterDate(inputDt: string, firstDt: string): string {
    // 날짜 형식을 ISO 8601로 변환
    const formattedInputDt = `${inputDt.slice(0, 4)}-${inputDt.slice(
      4,
      6
    )}-${inputDt.slice(6)}`;
    const formattedFirstDt = `${firstDt.slice(0, 4)}-${firstDt.slice(
      4,
      6
    )}-${firstDt.slice(6)}`;

    // Date 객체 생성
    const dateInputDt = new Date(formattedInputDt);
    const dateFirstDt = new Date(formattedFirstDt);

    // 두 날짜 비교
    if (dateInputDt > dateFirstDt) {
      return inputDt; // inputDt가 더 큰 날짜
    } else {
      return firstDt; // firstDt가 더 크거나 두 날짜가 같은 경우
    }
  }
  function findFasterDate(inputDt: string, firstDt: string): string {
    // 날짜 형식을 ISO 8601로 변환
    const formattedInputDt = `${inputDt.slice(0, 4)}-${inputDt.slice(
      4,
      6
    )}-${inputDt.slice(6)}`;
    const formattedFirstDt = `${firstDt.slice(0, 4)}-${firstDt.slice(
      4,
      6
    )}-${firstDt.slice(6)}`;

    // Date 객체 생성
    const dateInputDt = new Date(formattedInputDt);
    const dateFirstDt = new Date(formattedFirstDt);

    // 두 날짜 비교
    if (dateInputDt < dateFirstDt) {
      return inputDt; // inputDt가 더 큰 날짜
    } else {
      return firstDt; // firstDt가 더 크거나 두 날짜가 같은 경우
    }
  }

  function fn_useDt(row: any): string {
    const { formatpk } = getFormattedDateTime();
    const firstDt = row.pk.split("/")[0] + "01";
    const year = parseInt(row.pk.split("/")[0].substring(0, 4), 10);
    const month = parseInt(row.pk.split("/")[0].substring(4, 6), 10) - 1;

    const lastDate = new Date(year, month + 1, 0);
    const lastDt = `${lastDate.getFullYear()}${(lastDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${lastDate.getDate().toString().padStart(2, "0")}`;

    const startDtTemp = formatInputDt(row.inputDt);
    const endDtTemp = row.cancelDt ? formatInputDt(row.cancelDt) : formatpk;
    const startDt = findLaterDate(firstDt, startDtTemp);
    const endDt = findFasterDate(lastDt, endDtTemp);

    const today = new Date(
      endDt.slice(0, 4) + "-" + endDt.slice(4, 6) + "-" + endDt.slice(6)
    ).getTime();
    const firstDay = new Date(
      startDt.slice(0, 4) + "-" + startDt.slice(4, 6) + "-" + startDt.slice(6)
    ).getTime();
    const diffInDays =
      Math.floor((today - firstDay) / (1000 * 60 * 60 * 24)) + 1;

    return diffInDays.toString();
  }
  function fn_useTime(row: any): string {
    const reservations = row.reservInfo?.reservations;
    const firstReservationKey = reservations
      ? Object.keys(reservations)[0]
      : null;
    const reservOption = firstReservationKey
      ? reservations[firstReservationKey].reservOption
      : null;

    if (!reservOption) {
      return "—"; // 예약 옵션이 없는 경우 대시 반환
    }
    // console.log(reservOption);
    const splitTime = reservOption.split(",");
    const roundTime = Math.round(splitTime.length / 2);
    return String(roundTime);
  }

  const [comunity, setComunity] = useState("INOUT");
  const handleChange = (event: SelectChangeEvent) => {
    setAccountList([]);
    setComunity(event.target.value as string);
  };
  const [selectState, setSelectState] = useState("신청");
  const handleStateChange = (event: SelectChangeEvent) => {
    setSelectState(event.target.value as string);
  };

  const generateMonths = () => {
    const months = [];
    const currentDate = new Date();
    for (let i = -1; i < 6; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const yearMonth = `${date.getFullYear()}${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      const formattedMonth = `${date.getFullYear()}년 ${String(
        date.getMonth() + 1
      ).padStart(2, "0")}월`;

      months.push({ value: yearMonth, label: formattedMonth });
    }

    return months;
  };
  const [selectMonth, setSelectMonth] = useState<string>("");
  const [months, setMonths] = useState<Month[]>([]); // 여기에서 타입을 명시적으로 지정

  const handleMonthChange = (event: SelectChangeEvent) => {
    setSelectMonth(event.target.value as string);
  };

  useEffect(() => {
    const generatedMonths = generateMonths();
    setMonths(generatedMonths);
    if (generatedMonths.length > 0) {
      setSelectMonth(generatedMonths[1].value);
    }
  }, []);

  // 관리비 수정
  const renderUpdatePriceButton = (params: any) => (
    <Button
      color="primary"
      onClick={() =>
        handleUpdatePrice(
          params.row.pk,
          params.row.sk,
          params.row.communityPrice,
          params.row.userId
        )
      }
    >
      <SaveAsIcon />
    </Button>
  );

  const handleUpdatePrice = async (
    pk: string,
    sk: string,
    communityPrice: string,
    userId: string
  ) => {
    const confirmCancel = window.confirm(
      userId + " 회원의 청구 금액을 수정 하시겠습니까?"
    );
    const communityPriceNumber = communityPrice.replace(/[^\d.]/g, "");
    const formattedCommunityPrice = parseFloat(communityPriceNumber) || 0;

    if (confirmCancel) {
      const { formatpk } = getFormattedDateTime();

      const param: any = {
        pk: pk,
        sk: sk,
        communityPrice: formattedCommunityPrice,
        priceUpdateDt: formatpk,
        priceUpdateId: props.userState.userId,
      };

      const res = await mainApi.update_price(param);
      if (res.code === "200") {
        alert("수정 되었습니다.");
        get_account_list();
      } else if (res.code === "400") {
        alert("수정에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };
  // 관리비 수정
  const renderCancelScreenButton = (params: any) => (
    <Button
      color="primary"
      onClick={() => reservation_cancel(params.row.pk, params.row.sk)}
    >
      <EventBusyIcon />
    </Button>
  );

  const reservation_cancel = async (pk: string, sk: string) => {
    const communityPrice = 0;
    const confirmCancel = window.confirm(
      `예약을 취소하시겠습니까?\n[사용료] ${communityPrice}원`
    );
    if (confirmCancel) {
      const { formattedDateTime, formatpk } = getFormattedDateTime();
      const param: any = {
        pk: pk,
        sk: sk,
        inputDt: formattedDateTime,
        communityPrice: communityPrice,
        CancelUpdateDt: formatpk,
        CancelUpdateId: props.userState.userId,
      };
      const res = await mainApi.put_cancel_my_reservation_price(param);
      if (res.code === "200") {
        alert("예약이 취소되었습니다.");
        get_account_list();
      } else if (res.code === "400") {
        alert("예약 취소에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    const commonColumns: GridColDef[] = [
      {
        field: "userAddr",
        headerName: "주소",
        width: 150,
        editable: false,
        align: "center",
        headerAlign: "center",
        sortable: true, // 정렬 활성화
      },
      {
        field: "userName",
        headerName: "회원 이름",
        width: 110,
        editable: false,
        align: "center",
        headerAlign: "center",
      },
    ];

    if (comunity === "SCREENROOM" || comunity === "SCREEN") {
      // commonColumns의 처음부터 원하는 인덱스까지와 그 이후 부분을 나누어 관리
      const before = commonColumns.slice(0, 3); // 첫 번째부터 세 번째 컬럼까지
      const after = commonColumns.slice(3); // 네 번째 컬럼부터 끝까지

      const screenColumns: GridColDef[] = [
        ...before, // 첫 부분
        {
          field: "reservDt",
          headerName: "이용일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservDt = firstReservationKey
              ? reservations[firstReservationKey].reservDt
              : "—";
            return reservDt;
          },
        },
        {
          field: "reservOption",
          headerName: "이용 시간",
          width: 250,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservOption = firstReservationKey
              ? reservations[firstReservationKey].reservOption
              : "—";
            return reservOption;
          },
        },
        {
          field: "useTime",
          headerName: "총 시간",
          width: 100,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => fn_useTime(params.row) || "—",
        },
        {
          field: "info_01",
          headerName: "이용 홀",
          width: 100,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.info_01 || "—",
        },
        ...after, // 추가 컬럼 이후 부분
      ];
      setColumns(screenColumns);
    } else if (comunity === "INOUT") {
      // commonColumns의 처음부터 원하는 인덱스까지와 그 이후 부분을 나누어 관리
      const before = commonColumns.slice(0, 3); // 첫 번째부터 세 번째 컬럼까지
      const after = commonColumns.slice(3); // 네 번째 컬럼부터 끝까지

      const healthuseColumns: GridColDef[] = [
        ...before, // 첫 부분
        {
          field: "inputDt",
          headerName: "이용일",
          width: 250,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "displayTime",
          headerName: "입장시간",
          width: 150,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "outDisplayTime",
          headerName: "퇴장시간",
          width: 150,
          editable: false,
          align: "center",
          headerAlign: "center",
        },

        ...after, // 추가 컬럼 이후 부분
      ];
      setColumns(healthuseColumns);
    }
  }, [comunity]);

  const downloadExcel = (accountList: any[], columns: any[]) => {
    // 각 행에 대해 엑셀 파일에 포함될 값을 계산
    const dataForExcel = accountList.map((row) => {
      let newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
        if (col.valueGetter) {
          newRow[col.headerName] = col.valueGetter({ row });
        } else {
          newRow[col.headerName] = row[col.field];
        }
      });
      return newRow;
    });

    // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "use_detail.xlsx");
  };

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box sx={{ position: "absolute", top: "60px", right: "20px" }}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">시설</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={comunity}
                  label="시설"
                  onChange={handleChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"INOUT"}>헬스장</MenuItem>
                  <MenuItem value={"SCREENROOM"}>스크린게임</MenuItem>
                  <MenuItem value={"SCREEN"}>스크린타석</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">상태</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectState}
                  label="상태"
                  onChange={handleStateChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"신청"}>신청</MenuItem>
                  <MenuItem value={"취소"}>취소</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">기준</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectMonth}
                  label="정산일"
                  onChange={handleMonthChange}
                  sx={{ height: 46 }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                get_account_list();
              }}
              sx={{
                borderRadius: "4px",
                width: "54px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => downloadExcel(accountList, columns)}
              sx={{
                borderRadius: "4px",
                width: "74px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              EXCEL
              <FileDownloadIcon />
            </Button>
          </Stack>
        </Box>
        <Box className="sub-header-root" sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            시설 사용 현황 관리
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid>
            <DataGrid
              rows={accountList}
              columns={columns}
              disableRowSelectionOnClick
              style={{ height: 500 }}
              getRowId={(row) => (row.sk ? row.sk.toString() : "")}
            />
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}></Grid>
          </Grid>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default UseDetailList;
