import { useEffect, useState, useRef } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import MainPage from "../page/mainPage/mainPage";
import SignUp from "../page/signUp/SignUp";
import SignIn from "../page/signIn/SignIn";
import { userState } from "../interface/MainInterface";
import * as AuthMain from "../auth/AuthMain";
import Toast from "../utils/Toast";
import MemberJoin from "../page/mobilePage/MemberJoin";
import UserList from "../page/adminPage/UserList";
import AccountList from "../page/adminPage/AccountList";
import Reservation from "../page/mobilePage/Reservation";
import ReservationEnd from "../page/mobilePage/ReservationEnd";
import MobileMypage from "../page/mobilePage/MobileMypage";
import MobileRealTime from "../page/mobilePage/MobileRealTime";
import MemberLogin from "../page/mobilePage/MemberLogin";
import MobileMainPage from "../page/mobilePage/MobileMainPage";
import ReservationStudy from "../page/mobilePage/ReservationStudy";
import ReservationStudyEnd from "../page/mobilePage/ReservationStudyEnd";
import ReservationHealth from "../page/mobilePage/ReservationHealth";
import ReservationHealthEnd from "../page/mobilePage/ReservationHealthEnd";
import HealthUseMember from "../page/mobilePage/HealthUseMember";
import QrcodeIoTClient from "../page/components/QrcodeIoTClient";
import QrcodeReaderPage from "../page/mobilePage/QrcodeReaderPage";
import ReservationLocker from "../page/mobilePage/ReservationLocker";
import ReservationLockerEnd from "../page/mobilePage/ReservationLockerEnd";
import ReservationScreen from "../page/mobilePage/ReservationScreen";
import ReservationScreenEnd from "../page/mobilePage/ReservationScreenEnd";
import ReservationGolf from "../page/mobilePage/ReservationGolf";
import ReservationGolfEnd from "../page/mobilePage/ReservationGolfEnd";
import MemberPassword from "../page/mobilePage/MemberPassword";
import HealthOut from "../page/adminPage/HealthOut";
import AccountDetailList from "../page/adminPage/AccountDetailList";
import ReservationAccount from "../page/mobilePage/ReservationAccount";
import AdminManual from "../page/adminPage/AdminManual";
import ReservationHealthRank from "../page/mobilePage/ReservationHealthRank";
import MobilePassChpage from "../page/mobilePage/MobilePassChpage";
import UseDetailList from "../page/adminPage/UseDetailList";
import MobileScreenView from "../page/mobilePage/MobileScreenView";
import UserFingerList from "../page/adminPage/UserFingerList";
import LockerPassWord from "../page/adminPage/LockerPassWord";
import ReservationNext from "../page/mobilePage/ReservationNext";
import AdminReservPage from "../page/adminPage/AdminReservPage";
import ReservationHealthAdmin from "../page/mobilePage/ReservationHealthAdmin";
import CommunityInfo from "../page/adminPage/CommunityInfo";
import MobileBookList from "../page/mobilePage/MobileBookList";
import ReservationHealthChart from "../page/mobilePage/ReservationHealthChart";
import KvsPlayerView from "../page/adminPage/KvsPlayerView";

let userParam: userState = {
  userId: "",
  userName: "",
  userDong: "",
  userHo: "",
  userLevel: "",
  readInputDt: "",
  userType: "",
  readAddress: "",
  isAuth: false,
};

interface propsType {
  userState: userState;
  drawerWidth: number;
  setSelectedMenu: any;
  winSize: string;
  getLeftCount: any;
  searchResult: any;
  setSearchResult: any;
}

const MainConteiner = (props: propsType) => {
  const location = useLocation();
  const toastRef: any = useRef();

  useEffect(() => {
    const sessionCheck = async () => {
      const result = AuthMain.getSession();
      if (result !== null) {
        userParam.userId = result.userId;
        userParam.isAuth = true;
      } else {
        moveHome();
      }
    };

    const moveHome = () => {
      toastRef.current?.toast(
        "세션이 종료되었습니다.\n로그인 화면으로 이동합니다.",
        "error",
        4000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      setTimeout(() => {
        window.location.href = "/memberlogin";
      }, 3000);
    };

    if (
      location.pathname === "/" ||
      location.pathname === "/signin" ||
      location.pathname === "/memberjoin" ||
      location.pathname === "/memberlogin" ||
      location.pathname === "/memberpassword" ||
      location.pathname === "/qrcodemember" ||
      location.pathname === "/qrcodereader" ||
      location.pathname === "/mobilescreenview" ||
      location.pathname === "/signup"
    ) {
      props.setSelectedMenu(location.pathname);
    } else {
      props.setSelectedMenu(location.pathname);
      sessionCheck();
    }
  }, [location, props]);
  return (
    <Box
      sx={{
        ml: `${props.drawerWidth}px`,
        transition: "margin 0.5s",
        height: "calc(100vh - 44px)",
      }}
    >
      <Routes>
        <Route path="/" element={<MemberLogin userState={props.userState} />} />
        <Route
          path="/signup"
          element={<SignUp userState={props.userState} />}
        />
        <Route
          path="/signin"
          element={<SignIn userState={props.userState} />}
        />
        <Route
          path="/memberjoin"
          element={<MemberJoin userState={props.userState} />}
        />
        <Route
          path="/memberlogin"
          element={<MemberLogin userState={props.userState} />}
        />
        <Route
          path="/memberpassword"
          element={<MemberPassword userState={props.userState} />}
        />
        <Route
          path="/mobilemain"
          element={<MobileMainPage userState={props.userState} />}
        />
        <Route
          path="/healthusemember"
          element={<HealthUseMember userState={props.userState} />}
        />
        <Route path="/qrcodemember" element={<QrcodeIoTClient />} />
        <Route path="/qrcodereader" element={<QrcodeReaderPage />} />

        <Route
          path="/reservationscreen"
          element={<ReservationScreen userState={props.userState} />}
        />

        <Route
          path="/reservationscreenend"
          element={<ReservationScreenEnd userState={props.userState} />}
        />
        <Route
          path="/reservationgolf"
          element={<ReservationGolf userState={props.userState} />}
        />
        <Route
          path="/reservationgolfend"
          element={<ReservationGolfEnd userState={props.userState} />}
        />
        <Route
          path="/reservationstudy"
          element={<ReservationStudy userState={props.userState} />}
        />
        <Route
          path="/reservationstudyend"
          element={<ReservationStudyEnd userState={props.userState} />}
        />
        <Route
          path="/reservationhealth"
          element={<ReservationHealth userState={props.userState} />}
        />
        <Route
          path="/reservationhealthend"
          element={<ReservationHealthEnd userState={props.userState} />}
        />
        <Route
          path="/reservationhealthchart"
          element={<ReservationHealthChart userState={props.userState} />}
        />
        <Route
          path="/reservationlocker"
          element={<ReservationLocker userState={props.userState} />}
        />
        <Route
          path="/reservationlockerend"
          element={<ReservationLockerEnd userState={props.userState} />}
        />
        <Route
          path="/reservation"
          element={<Reservation userState={props.userState} />}
        />
        <Route
          path="/reservationend"
          element={<ReservationEnd userState={props.userState} />}
        />
        <Route
          path="/reservationnext"
          element={<ReservationNext userState={props.userState} />}
        />
        <Route
          path="/reservationaccount"
          element={<ReservationAccount userState={props.userState} />}
        />
        <Route
          path="/reservationhealthrank"
          element={<ReservationHealthRank userState={props.userState} />}
        />
        <Route
          path="/mobilemypage"
          element={<MobileMypage userState={props.userState} />}
        />
        <Route
          path="/mobilepasschpage"
          element={<MobilePassChpage userState={props.userState} />}
        />
        <Route path="/kvsplayer" element={<KvsPlayerView />} />
        <Route
          path="/mobilerealtime"
          element={
            <MobileRealTime
              userState={props.userState}
              startMode={"view"}
              selectTheme={() => {}}
              height="calc(100vh - 151px)"
            />
          }
        />
        <Route
          path="/userlist"
          element={<UserList userState={props.userState} />}
        />
        <Route
          path="/userfingerlist"
          element={<UserFingerList userState={props.userState} />}
        />
        <Route
          path="/adminreserv"
          element={<AdminReservPage userState={props.userState} />}
        />
        <Route
          path="/lockerpassword"
          element={<LockerPassWord userState={props.userState} />}
        />
        <Route
          path="/mobilebooklist"
          element={<MobileBookList userState={props.userState} />}
        />
        <Route
          path="/accountlist"
          element={<AccountList userState={props.userState} />}
        />
        <Route
          path="/accountdetaillist"
          element={<AccountDetailList userState={props.userState} />}
        />
        <Route
          path="/usedetaillist"
          element={<UseDetailList userState={props.userState} />}
        />
        <Route
          path="/healthout"
          element={<HealthOut userState={props.userState} />}
        />
        <Route
          path="/adminmanual"
          element={<AdminManual userState={props.userState} />}
        />
        <Route
          path="/mobilecommunityinfo"
          element={<CommunityInfo userState={props.userState} />}
        />
        <Route path="/mobilescreenview" element={<MobileScreenView />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Toast ref={toastRef} />
    </Box>
  );
};

export default MainConteiner;
