import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { userState } from "../interface/MainInterface";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { HttpMainApi } from "../interface/main-api";
import { getFormattedDateTime } from "../utils/getFormattedDateTime";
const mainApi = new HttpMainApi();

interface propsType {
  popOpen: boolean;
  userState: userState;
  popClickClose: () => void;
}

const HealthOutPage = (props: propsType) => {
  const [memberHealthDay, setMemberHealthDay] = useState(0);
  const get_my_health_day = async () => {
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = `${formatmonthpk}/HEALTHDATA`;
    const sk = "헬스기록";
    const param: any = {
      pk: pk,
      sk: sk,
    };

    const res = await mainApi.get_my_health_day(param);
    if (res.code === "200") {
      let count = 0;
      const reservations = res.response.result[0].reservations;

      Object.values(reservations).forEach((day: any) => {
        if (day.includes(userId)) {
          count += 1;
        }
      });
      setMemberHealthDay(count);
    }
  };

  useEffect(() => {
    if (props.popOpen) {
      get_my_health_day();
    }
  }, [props.popOpen]);

  const images = [
    "/images/healthout01.png",
    "/images/healthout02.png",
    "/images/healthout03.png",
    "/images/healthout04.png",
  ];
  const [selectedImage, setSelectedImage] = useState("");
  useEffect(() => {
    // 컴포넌트가 마운트될 때 랜덤 이미지 선택
    const randomIndex = Math.floor(Math.random() * images.length);
    setSelectedImage(images[randomIndex]);
  }, [props.popOpen]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        open={props.popOpen}
      >
        <Stack
          direction="column"
          style={{ height: "100%", paddingTop: "50%", border: "4px" }}
        >
          <Box
            sx={{
              position: "relative", // 첫 번째 이미지를 컨테이너로 사용
              display: "inline-block",
              maxWidth: "100%",
            }}
          >
            <img
              src={selectedImage}
              alt="Health"
              style={{
                display: "block",
                maxWidth: "100%",
                height: "auto",
                borderRadius: "20px",
              }}
            />
            <Box
              sx={{
                position: "absolute", // 이 Box를 첫 번째 이미지에 대해 절대적으로 위치시킴
                bottom: "4%", // 첫 번째 이미지의 하단에서부터의 위치
                left: "50%", // 좌우 중앙 정렬
                transform: "translateX(-50%)", // 좌우 중앙으로 정확히 배치
                width: "60%", // 이 Box의 너비
              }}
            >
              <img
                src="/images/healthout05.png"
                alt="Overlay"
                style={{
                  width: "100%", // 이 이미지를 Box 내부에 꽉 채움
                  height: "100%", // 이 이미지의 높이를 Box 높이에 맞춤
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  whiteSpace: "nowrap", // 텍스트를 줄바꿈 없이 한 줄에 표시
                  overflow: "hidden", // 넘치는 텍스트 숨김
                  textOverflow: "ellipsis", // 넘치는 텍스트를 '...'으로 표시
                  maxWidth: "90%", // 최대 너비 설정 (부모 컨테이너에 따라 조정 가능)
                }}
              >
                <Typography
                  variant="button"
                  sx={{
                    color: "#FF810E",
                    fontSize: "20px",
                  }}
                >
                  이번 달 {memberHealthDay}일 운동 성공!
                </Typography>
              </Box>
            </Box>
          </Box>

          <Button
            variant="text"
            sx={{ marginTop: 2, color: "#fff" }}
            onClick={props.popClickClose}
          >
            <CloseIcon style={{ fontSize: "48px" }} />
          </Button>
        </Stack>
      </Backdrop>
    </>
  );
};

export default HealthOutPage;
