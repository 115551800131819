import { useState, useRef, useEffect } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme, useMediaQuery } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const MemberJoin = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userDong, setUserDong] = useState("");
  const [userName, setUserName] = useState("");
  const [userHo, setUserHo] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [agecheck, setAgecheck] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserDong("");
      setUserHo("");
      setUserName("");
      setUserId("");
      setPassword("");
      setPassword2("");
    };
  }, []);

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };

  const fncSignUp = async () => {
    if (userName === "" || userName === undefined) {
      toastRef.current?.toast("이름을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (userDong === "" || userDong === undefined) {
      toastRef.current?.toast("동을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (userHo === "" || userHo === undefined) {
      toastRef.current?.toast("호를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (userId === "" || userId === undefined) {
      toastRef.current?.toast("아이디를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password === "" || password === undefined) {
      toastRef.current?.toast("비밀번호를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password2 === "" || password2 === undefined) {
      toastRef.current?.toast("비밀번호 확인을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password !== password2) {
      toastRef.current?.toast("비밀번호가 일치하지 않습니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return false;
    }
    if (!agecheck) {
      alert("13세 이상 체크박스를 확인해 주세요.");
      return false;
    }
    const confirmCancel = window.confirm("가입 신청 하시겠습니까?");

    if (confirmCancel) {
      const encryptedPassword = hashPassword(password);
      setIsLoading(true);
      const { formattedDateTime, compactDateTime } = getFormattedDateTime();
      const pk = "COMMUNITY/USER";
      const userLevel = "신청"; //추후 수정
      const userType = "일반회원"; //관리자, 일반회원
      const param: any = {
        pk: pk,
        sk: userId,
        userName: userName,
        userDong: userDong,
        userHo: userHo,
        userId: userId,
        password: encryptedPassword,
        userLevel: userLevel,
        userType: userType,
        inputDt: compactDateTime,
        updateDt: compactDateTime,
        readInputDt: formattedDateTime,
      };
      const res = await mainApi.put_member_join(param);

      if (res.code === "200") {
        alert(
          "가입 신청이 완료되었습니다.\n관리실 [승인] 후 이용하실 수 있습니다."
        );
        navigate("/memberlogin");
      } else if (res.code === "202") {
        alert(`[${userId}]와 동일한 아이디가 있습니다.`);
      } else {
        alert(
          `알 수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
      setIsLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === "관리소") {
      setUserHo("관리소");
    } else {
      setUserHo("");
    }
    setUserDong(event.target.value as string);
  };
  const handleImageClick = () => {
    navigate("/memberlogin");
  };
  const handleAgeCheckChange = (event: any) => {
    setAgecheck(event.target.checked);
  };
  return (
    <>
      <Box
        sx={{
          p: isMobile ? 1 : 2,
          display: "flex",
          height: "calc(100vh - 100px)",
        }}
      >
        <Box
          component={"div"}
          sx={{
            width: "90%",
            maxWidth: isMobile ? "100%" : "40rem",
            margin: "auto",
          }}
        >
          <Paper elevation={4} sx={{ p: 2 }}>
            <Box
              sx={{
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              <img
                className="logo-img"
                src="/images/d_join.png"
                alt="logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  cursor: "pointer",
                }}
                onClick={handleImageClick}
              />
            </Box>
            <Stack spacing={2} direction="column">
              <TextField
                id="userName"
                value={userName}
                placeholder="홍길동"
                variant="outlined"
                label="이름"
                autoFocus
                fullWidth
                required
                type="text"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">동</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userDong}
                  required
                  label="동"
                  onChange={handleChange}
                >
                  <MenuItem value={"1301"}>1301동</MenuItem>
                  <MenuItem value={"1302"}>1302동</MenuItem>
                  <MenuItem value={"1303"}>1303동</MenuItem>
                  <MenuItem value={"1304"}>1304동</MenuItem>
                  <MenuItem value={"1305"}>1305동</MenuItem>
                  <MenuItem value={"1306"}>1306동</MenuItem>
                  <MenuItem value={"관리소"}>관리소</MenuItem>
                </Select>
              </FormControl>

              <TextField
                id="userDong"
                value={userHo}
                placeholder="000(숫자만 입력)"
                variant="outlined"
                label="호"
                fullWidth
                required
                type="text"
                autoComplete="current-password"
                inputProps={{
                  autoComplete: "new-password",
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                  inputMode: "numeric", // 모바일 키보드에서 숫자 키보드를 먼저 보여줌
                  pattern: "[0-9]*", // 숫자만 입력 가능
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  // 숫자만 입력되도록 필터링
                  setUserHo(value.replace(/[^0-9]/g, ""));
                }}
              />
              <TextField
                id="userId"
                value={userId}
                placeholder="아이디를 입력해주세요"
                variant="outlined"
                label="아이디(영문 또는 숫자)"
                fullWidth
                required
                type="text"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setUserId(value.replace(/[^a-z0-9]/g, "").slice(0, 15));
                }}
              />
              <TextField
                id="password"
                value={password}
                placeholder="비밀번호를 입력하세요"
                variant="outlined"
                label="비밀번호"
                fullWidth
                required
                type="password"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <TextField
                id="password2"
                value={password2}
                placeholder="동일한 비밀번호를 입력하세요"
                variant="outlined"
                label="비밀번호 확인"
                fullWidth
                required
                type="password"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
              />
              <FormControlLabel
                checked={agecheck}
                onChange={handleAgeCheckChange}
                control={<Checkbox />}
                label="만13세 이상만 가입시 승인됩니다."
              />
              <Button variant="contained" onClick={fncSignUp}>
                가입 신청
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MemberJoin;
