import { Auth } from "aws-amplify";
import { HttpMainApi } from "../interface/main-api";
import { AES, enc } from "crypto-js";
import { appConfig } from "../config/Config";

const mainApi = new HttpMainApi();

export async function checkAuthenticated() {
  let result = false;
  await Auth.currentSession()
    .then(async (data) => {
      await Auth.currentAuthenticatedUser()
        .then((data) => {
          result = true;
        })
        .catch((err) => {
          result = false;
        });
    })
    .catch((err) => {
      result = false;
    });
  return result;
}

export async function getUserAttr() {
  try {
    if (!(await checkAuthenticated())) throw Error("user not loged in");
    const user = await Auth.currentAuthenticatedUser();
    const { attributes } = user;
    let rtn_pay_load = {
      email: attributes.email,
      name: attributes.name,
      company: attributes["custom:company"],
    };
    return rtn_pay_load;
  } catch (e) {
    return {
      email: "",
      name: "",
      company: "",
    };
  }
}

export async function checkAdmin() {
  let admin_info: any = {
    isAdmin: false,
    isSuperAdmin: false,
  };
  if (!(await checkAuthenticated())) return admin_info;
  const user = await Auth.currentAuthenticatedUser();
  const param: any = {
    command: "get_admin_info",
    user_id: user.attributes.email,
  };
  const res = await mainApi.post(param);
  if (res.code === "200") {
    admin_info.isAdmin = res.response.user_info.is_admin;
    admin_info.isSuperAdmin = res.response.user_info.is_super_admin;
  }
  return admin_info;
}

export async function setChatName(newChatName: string) {
  let loginUser = await Auth.currentAuthenticatedUser();
  let result = await Auth.updateUserAttributes(loginUser, {
    "custom:chat_name": newChatName,
  });
  return result;
}

export async function getChatName() {
  let loginUser = await Auth.currentAuthenticatedUser();
  const { attributes } = loginUser;
  if (
    attributes["custom:chat_name"] === undefined ||
    attributes["custom:chat_name"] === ""
  ) {
    return "";
  } else {
    return attributes["custom:chat_name"];
  }
}

export async function setPicture(path: string) {
  let loginUser = await Auth.currentAuthenticatedUser();
  let result = await Auth.updateUserAttributes(loginUser, {
    picture: path,
  });
  return result;
}

export function getSession() {
  const encObjString = window.localStorage.getItem(appConfig.sessionName);

  if (!encObjString) return null;
  try {
    const bytes = AES.decrypt(encObjString, appConfig.seed);
    const decrypted = bytes.toString(enc.Utf8);
    let obj = JSON.parse(decrypted);
    if (Date.now() > obj.expire) {
      window.localStorage.removeItem(appConfig.sessionName);
      return false;
    } else {
      obj.expire = Date.now() + appConfig.sessionTimeout;
      const objString = JSON.stringify(obj);
      const encObjString = AES.encrypt(objString, appConfig.seed).toString();
      window.localStorage.setItem(appConfig.sessionName, encObjString);
    }

    return obj;
  } catch (error) {
    window.localStorage.removeItem(appConfig.sessionName);
    return null;
  }
}
export function setSession(sessionData: any) {
  console.log("sessionData : ", sessionData);
  const sessionObj: any = {
    userId: sessionData[0].userId,
    userName: sessionData[0].userName,
    userDong: sessionData[0].userDong,
    userHo: sessionData[0].userHo,
    userLevel: sessionData[0].userLevel,
    readInputDt: sessionData[0].readInputDt,
    userType: sessionData[0].userType,
    readAddress: sessionData[0].readAddress,
    expire: Date.now() + appConfig.sessionTimeout,
  };
  const objString = JSON.stringify(sessionObj);
  const encObjString = AES.encrypt(objString, appConfig.seed).toString();
  window.localStorage.setItem(appConfig.sessionName, encObjString);
}

export function signOut() {
  window.localStorage.removeItem(appConfig.sessionName);
  return true;
}
