import { useState, useRef, useEffect } from "react";
import { Stack, Typography, useTheme, useMediaQuery } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import ReservRoomView from "../components/ReservRoomView";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
const mainApi = new HttpMainApi();

interface ReservationDetail {
  deviceNum: string;
  reservTime: string;
  reservDt: string;
  userName: string;
  userId: string;
  inputDt: string;
}
interface Reservation {
  sk: string;
  pk: string;
  reservations: { [key: string]: ReservationDetail };
}
const MobileScreenView = () => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [times, setTimes] = useState<string[]>([]);
  const extractReservationTimes = (
    reservationData: Reservation[]
  ): string[] => {
    return reservationData.flatMap((item) =>
      Object.keys(item.reservations).flatMap((time) => time.split(","))
    );
  };

  const get_day_reservation = async () => {
    setIsLoading(true);
    const { formatpk } = getFormattedDateTime();
    const pk = `${formatpk}/SCREEN`;
    // const pk = `20240207/SCREEN`;
    const sk = "ROOM";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_day_reservation(param);
    if (res.code === "200") {
      const extractedTimes = extractReservationTimes(res.response.result);
      setTimes(extractedTimes);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    get_day_reservation();
    const intervalId = setInterval(get_day_reservation, 600000);

    return () => clearInterval(intervalId);
  }, []);

  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [reserv_room, setReserv_room] = useState<string[]>([]);
  const get_date_time = async () => {
    const today = new Date().getDay(); // 현재 요일을 가져옵니다 (0: 일요일, 1: 월요일, ..., 6: 토요일)

    if (today >= 1 && today <= 5) {
      setReserv_room([
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
      ]);
    } else if (today === 0 || today === 6) {
      setReserv_room([
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
      ]);
    }
  };

  useEffect(() => {
    get_date_time();
  }, []);

  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: isMobile ? "100%" : "100%",
          margin: "auto",
          backgroundColor: "#595959",
        }}
      >
        <ReservRoomView
          reserv_time={reserv_room}
          selectedTimes={selectedTimes}
          times={times}
        />
      </Stack>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MobileScreenView;
