import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { CommonUtils } from "../../utils/common_utils";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import ReservTimeList from "../components/ReservTimeList";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";

interface propsType {
  userState: userState;
  themeInfo: any;
  startMode: string;
  selectTheme: any;
}
interface ReservationDetail {
  deviceNum: string;
  reservTime: string;
  reservDt: string;
  userName: string;
  userId: string;
  inputDt: string;
}
interface Reservation {
  sk: string;
  pk: string;
  reservations: { [key: string]: ReservationDetail };
}

const mainApi = new HttpMainApi();
const utils = new CommonUtils();

const MobileRealTimeCard = (props: propsType) => {
  const toastRef: any = useRef();
  const [selectedTime, setSelectedTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setChangeMode] = useState(props.startMode);
  const [open, setOpen] = useState(false);
  const handleTimeButtonClick = (time: any) => {};

  const reserv_am = [
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
  ];
  const reserv_pm = [
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
  ];

  const [times, setTimes] = useState<string[]>([]);
  const extractReservationTimes = (
    reservationData: Reservation[]
  ): string[] => {
    const times: string[] = [];

    reservationData.forEach((item) => {
      Object.keys(item.reservations).forEach((time) => {
        times.push(time);
      });
    });
    return times;
  };

  const get_day_reservation = async () => {
    setIsLoading(true);
    const { formatpk } = getFormattedDateTime();
    const pk = `${formatpk}/SCREEN`;
    const sk = props.themeInfo.title;
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_day_reservation(param);
    if (res.code === "200") {
      const extractedTimes = extractReservationTimes(res.response.result);
      setTimes(extractedTimes);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    get_day_reservation();
  }, []);

  return (
    <>
      <Box sx={{ position: "relative", zIndex: "100", minWidth: "250px" }}>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "7px",
            backgroundColor: "rgba(70, 117, 231, 0.07)",
            color: "inherit",
            padding: "16px",
            boxSizing: "border-box",
          }}
          elevation={0}
        >
          <Stack direction={"column"} spacing={1}>
            <Box>
              <Button
                fullWidth
                sx={{
                  fontSize: "18px !important",
                  fontWeight: "800 !important",
                  textOverflow: "ellipsis",
                  justifyContent: "left",
                }}
              >
                {props.themeInfo.category !== "" &&
                props.themeInfo.category !== undefined
                  ? `[${props.themeInfo.category}] `
                  : ""}
                {props.themeInfo.title}
              </Button>
            </Box>
            <Stack direction={"column"} spacing={0}>
              <Stack
                direction={"row"}
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    fontSize: "11px !important",
                    color: "#00000080 !important",
                    lineHeight: "20px !important",
                  }}
                >{`searched : ${dayjs(props.themeInfo.create_time).format(
                  "YYYY-MM-DD"
                )}`}</Typography>
              </Stack>

              <Stack
                direction={"row"}
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Stack
                  direction={"row"}
                  spacing={0}
                  sx={{ justifyContent: "flex-start" }}
                >
                  <Tooltip title={open ? "상세정보 닫기" : "상세정보 열기"}>
                    <IconButton
                      onClick={() => {
                        setOpen(!open);
                        setChangeMode(mode);
                      }}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack
              direction={"column"}
              spacing={1}
              className="template-form-list"
              sx={open ? { display: "" } : { display: "none" }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontWeight: 300,
                    textAlign: "left",
                    margin: "auto",
                    mt: 2,
                    ml: 3,
                  }}
                >
                  오전
                </Typography>
              </Box>
              <ReservTimeList
                userState={props.userState}
                reserv_time={reserv_am}
                handleTimeButtonClick={handleTimeButtonClick}
                selectedTime={selectedTime}
                chunkNum={3}
                times={times}
              />
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontWeight: 300,
                    textAlign: "left",
                    margin: "auto",
                    mt: 2,
                    ml: 3,
                  }}
                >
                  오후
                </Typography>
              </Box>
              <ReservTimeList
                userState={props.userState}
                reserv_time={reserv_pm}
                handleTimeButtonClick={handleTimeButtonClick}
                selectedTime={selectedTime}
                chunkNum={3}
                times={times}
              />
            </Stack>
          </Stack>
        </Paper>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MobileRealTimeCard;
