import React, { Fragment, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Stack, useTheme, useMediaQuery, Button } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
//IoT end
interface propsType {
  userState: userState;
}
interface ReservationDetails {
  userName: string;
  userId: string;
  inputDt: string;
  reservDt: string;
  reservOption: string;
  deviceNum: string;
}

interface Reservations {
  [key: string]: ReservationDetails;
}

interface ReservationInfo {
  sk: string;
  pk: string;
  userId: string;
  reservations: Reservations;
}

interface Reservation {
  sk: string;
  pk: string;
  userId: string;
  reservInfo: ReservationInfo;
  nextInfo: boolean;
  reservState: string;
}
interface PriceItem {
  sk: string;
  PRICE: string;
  pk: string;
}
const mainApi = new HttpMainApi();

const ReservationNext = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [reservationGolf, setReservationGolf] = useState<Reservation[]>([]);
  const [reservationStudy, setReservationStudy] = useState<Reservation[]>([]);
  const [reservationHealth, setReservationHealth] = useState<Reservation[]>([]);
  const [reservationLocker, setReservationLocker] = useState<Reservation[]>([]);
  const [golfNoData, setGolfNoData] = useState(true);
  const [studyNoData, setStudyNoData] = useState(true);
  const [healthNoData, setHealthNoData] = useState(true);
  const [lockerNoData, setLockerNoData] = useState(true);
  const [memberHealthDay, setMemberHealthDay] = useState(0);
  const [months, setMonths] = useState<string[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [allOk, setAllOk] = useState(false);
  const [selectYearMonth, setSelectYearMonth] = useState<string>("");
  const [price, setPrice] = useState<PriceItem[]>([]);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // 현재 월과 다음 두 달을 계산합니다.
    const monthsArray = [
      new Date(currentYear, currentMonth - 1, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
      new Date(currentYear, currentMonth, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
      new Date(currentYear, currentMonth + 1, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
    ];

    setMonths(monthsArray);
    setSelectYearMonth(monthsArray[2]);
  }, []);

  function formatYearMonth(selectYearMonth: string) {
    const regex = /(\d{4})년 (\d{1,2})월/;
    const matches = selectYearMonth.match(regex);
    if (matches) {
      const year = matches[1];
      const month = matches[2].padStart(2, "0");
      return `${year}${month}`;
    }

    return ""; // 매칭되지 않는 경우 빈 문자열 반환
  }

  function getNextMonth() {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    month += 1;
    if (month > 12) {
      month = 1;
      year += 1;
    }
    const formattedMonth = month.toString().padStart(2, "0");
    return { year, month: formattedMonth };
  }

  const displayMonthName = (monthName: string) => {
    return monthName?.substring(6);
  };

  const get_my_reservation1 = async () => {
    setIsLoading(true);

    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "GOLF",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setGolfNoData(false);
      setReservationGolf(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setGolfNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_reservation2 = async () => {
    setIsLoading(true);

    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "HEALTH",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setHealthNoData(false);
      setReservationHealth(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setHealthNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_reservation3 = async () => {
    setIsLoading(true);
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "STUDY",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setStudyNoData(false);
      const reservationWithNextInfo = await Promise.all(
        res.response.result.map(async (reservation: any) => {
          const reservationOptions = reservation.reservInfo.reservations;
          const keys = Object.keys(reservationOptions);
          const firstKey = keys[0];
          // get_locker_info 함수가 예약 옵션에 따른 추가 정보를 비동기적으로 반환한다고 가정
          const nextInfo = await get_next_month_info(
            firstKey,
            reservation.reservInfo.pk,
            reservation.reservInfo.sk
          );
          return { ...reservation, nextInfo };
        })
      );

      setReservationStudy(reservationWithNextInfo as Reservation[]);
    } else if (res.code === "400") {
      setStudyNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_reservation4 = async () => {
    setIsLoading(true);
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "LOCKER",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setLockerNoData(false);
      const reservationWithNextInfo = await Promise.all(
        res.response.result.map(async (reservation: any) => {
          const reservationOptions = reservation.reservInfo.reservations;
          const keys = Object.keys(reservationOptions);
          const firstKey = keys[0];
          // get_locker_info 함수가 예약 옵션에 따른 추가 정보를 비동기적으로 반환한다고 가정
          const nextInfo = await get_next_month_info(
            firstKey,
            reservation.reservInfo.pk,
            reservation.reservInfo.sk
          );
          return { ...reservation, nextInfo };
        })
      );

      setReservationLocker(reservationWithNextInfo as Reservation[]);
    } else if (res.code === "400") {
      setLockerNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const get_my_health_day = async () => {
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = `${formatmonthpk}/HEALTHDATA`;
    const sk = "헬스기록";
    const param: any = {
      pk: pk,
      sk: sk,
    };

    const res = await mainApi.get_my_health_day(param);
    if (res.code === "200") {
      let count = 0;
      const reservations = res.response.result[0].reservations;

      Object.values(reservations).forEach((day: any) => {
        if (day.includes(userId)) {
          count += 1;
        }
      });
      setMemberHealthDay(count);
    }
  };

  const get_next_month_info = async (
    optionNum: string,
    optionPk: string,
    optionSk: string
  ) => {
    try {
      const { year, month } = getNextMonth(); // 다음 달을 계산하는 로직
      const tempPk = optionPk.split("/")[1]; // PK에서 필요한 부분 추출
      const param = {
        pk: `${year}${month}/${tempPk}`, // 다음 달 정보를 포함한 PK 생성
        sk: optionSk,
        optionNum: optionNum,
      };
      const res = await mainApi.get_next_month_info(param); // API 호출

      if (res.code === "200") {
        setAllOk(true);
        return false; //예약 불가
      } else {
        return true; // 예약 가능
      }
    } catch (error) {
      console.error("Error in get_next_month_info:", error);
      setAllOk(true);
      return false; // 예외 발생 시 오류 메시지 반환
    }
  };

  useEffect(() => {
    get_my_reservation1();
    get_my_reservation2();
    get_my_reservation3();
    get_my_reservation4();
    get_my_health_day();
  }, []);

  const handleClickOpen = () => {
    setAlertOpen(true);
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const get_price = async () => {
    const pk = "COMMUNITY/ACCOUNT";
    const sk = "ALL";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_price(param);
    if (res.code === "200") {
      setPrice(res.response.result);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  const reservation_health_save = async () => {
    const item = price.find((p) => p.sk === "헬스장");
    const formatted = formatYearMonth(selectYearMonth);
    const { formattedDateTime, formatdaypk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const userAddr = props.userState.readAddress;
    const userName = props.userState.userName;
    const pk = `${formatted}/HEALTH`;
    const selectedGubun = "헬스장";
    const param: any = {
      pk: pk,
      sk: selectedGubun,
      option: userId,
      userId: userId,
      userAddr: userAddr,
      userName: userName,
      inputDt: formattedDateTime,
      reservDt: selectYearMonth,
      my_pk: pk,
      my_sk: `${formatted}${formatdaypk}`,
      communityPrice: item?.PRICE,
    };

    const res = await mainApi.put_month_reserv_data(param);
    if (res.code === "200") {
      navigate("/reservationend");
    } else if (res.code === "301") {
      setAlertOpen(false);
      alert("이미 신청된 내역이 있습니다.\n다시 조회 후 진행해주세요.");
    } else {
      setAlertOpen(false);
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  const reservation_golf_save = async () => {
    const item = price.find((p) => p.sk === "골프연습장");
    const formatted = formatYearMonth(selectYearMonth);
    const { formattedDateTime, formatdaypk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const userAddr = props.userState.readAddress;
    const userName = props.userState.userName;
    const pk = `${formatted}/GOLF`;
    const selectedGubun = "골프연습장";
    const param: any = {
      pk: pk,
      sk: selectedGubun,
      option: userId,
      userId: userId,
      userAddr: userAddr,
      userName: userName,
      inputDt: formattedDateTime,
      reservDt: selectYearMonth,
      my_pk: pk,
      my_sk: `${formatted}${formatdaypk}`,
      communityPrice: item?.PRICE,
    };
    const res = await mainApi.put_month_reserv_data(param);
    if (res.code === "200") {
      navigate("/reservationend");
    } else if (res.code === "301") {
      setAlertOpen(false);
      alert("이미 신청된 내역이 있습니다.\n다시 조회 후 진행해주세요.");
    } else {
      setAlertOpen(false);
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  const reservation_study_save = async () => {
    //map 으로 리스트 만큼 돌아야함
    const item = price.find((p) => p.sk === "독서실");
    const formatted = formatYearMonth(selectYearMonth);
    const userId = props.userState.userId;
    const userAddr = props.userState.readAddress;
    const userName = props.userState.userName;
    const pk = `${formatted}/STUDY`;
    const tasks = reservationStudy.map(async (reservation) => {
      const { formattedDateTime, formatdaypk } = getFormattedDateTime();
      const reservationOptions = reservation.reservInfo.reservations;
      const keys = Object.keys(reservationOptions);
      const firstKey = keys[0];
      const firstChar = firstKey.charAt(0);
      const prefix = formatdaypk.slice(0, -1);
      const lastDigit = firstChar === "M" ? "1" : "2";
      const newFormatdaypk = prefix + lastDigit;

      const param = {
        pk: pk,
        sk: reservation.reservInfo.sk,
        option: firstKey,
        userId: userId,
        userAddr: userAddr,
        userName: userName,
        inputDt: formattedDateTime,
        reservDt: selectYearMonth,
        my_pk: pk,
        my_sk: `${formatted}${newFormatdaypk}`,
        communityPrice: item?.PRICE,
      };

      const res = await mainApi.put_reserv_month(param);
      return res;
    });
    const results = await Promise.all(tasks);
    // Check if all responses are successful
    const allSuccess = results.every((res) => res.code === "200");

    if (allSuccess) {
      navigate("/reservationend");
    } else {
      // Handle case where not all requests were successful
      console.error("Not all reservations were successful");
    }
  };

  const reservation_locker_save = async () => {
    const formatted = formatYearMonth(selectYearMonth);
    const userId = props.userState.userId;
    const userAddr = props.userState.readAddress;
    const userName = props.userState.userName;
    const pk = `${formatted}/LOCKER`;

    const tasks = reservationLocker.map(async (reservation) => {
      console.log("reservation.reservInfo.sk : ", reservation.reservInfo.sk);

      const item = price.find((p) => p.sk === reservation.reservInfo.sk);
      console.log("reservation.reservInfo.sk : ", item?.PRICE);
      const { formattedDateTime, formatdaypk } = getFormattedDateTime();
      const reservationOptions = reservation.reservInfo.reservations;
      const keys = Object.keys(reservationOptions);
      const firstKey = keys[0];
      const firstChar = firstKey.charAt(0);
      const prefix = formatdaypk.slice(0, -1);
      const lastDigit = firstChar === "G" ? "1" : "2";
      const newFormatdaypk = prefix + lastDigit;
      const param = {
        pk: pk,
        sk: reservation.reservInfo.sk,
        option: firstKey,
        userId: userId,
        userAddr: userAddr,
        userName: userName,
        inputDt: formattedDateTime,
        reservDt: selectYearMonth,
        my_pk: pk,
        my_sk: `${formatted}${newFormatdaypk}`,
        communityPrice: item?.PRICE,
      };

      const res = await mainApi.put_reserv_month(param);
      return res;
    });
    const results = await Promise.all(tasks);
    // Check if all responses are successful
    const allSuccess = results.every((res) => res.code === "200");

    if (allSuccess) {
      navigate("/reservationend");
    } else {
      // Handle case where not all requests were successful
      console.error("Not all reservations were successful");
    }
  };

  const reservation_save = async () => {
    if (
      reservationGolf.length === 0 &&
      reservationHealth.length === 0 &&
      reservationStudy.length === 0 &&
      reservationLocker.length === 0
    ) {
      alert("신청 대상이 없습니다");
      setAlertOpen(false);
      return;
    }

    if (reservationGolf.length > 0) {
      //골프장
      reservation_golf_save();
    }
    if (reservationHealth.length > 0) {
      //헬스장

      reservation_health_save();
    }
    if (reservationStudy.length > 0) {
      //독서실
      reservation_study_save();
    }
    if (reservationLocker.length > 0) {
      //락커
      reservation_locker_save();
    }
  };

  useEffect(() => {
    get_price();
  }, []);

  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box sx={{ padding: 2 }}></Box>
        <Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              style={{ color: "#0000FF" }}
              sx={{
                textAlign: "left",
                margin: "auto",
                mt: 2,
                ml: 3,
              }}
            >
              {displayMonthName(months[1])} 이용 현황
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {healthNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_02.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationHealth.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_02.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <ListItemText
                          key={idx}
                          primary={details.reservDt}
                          secondary={
                            <Fragment>
                              <Typography
                                sx={{ display: "inline", mr: 1 }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {details.deviceNum}
                              </Typography>
                              {memberHealthDay}일 운동 하였습니다.
                            </Fragment>
                          }
                        />
                      )
                    )}
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {golfNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_03.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationGolf.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_03.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <ListItemText
                          key={idx}
                          primary={details.reservDt}
                          secondary={
                            <Fragment>
                              <Typography
                                sx={{ display: "inline", mr: 2 }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {details.deviceNum}
                              </Typography>
                              {/* {details.reservOption} */}
                            </Fragment>
                          }
                        />
                      )
                    )}
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {studyNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_04.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationStudy.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_04.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <Fragment key={idx}>
                          <ListItemText
                            primary={details.reservDt}
                            secondary={
                              <Fragment>
                                <Typography
                                  sx={{ display: "inline", mr: 2 }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {details.deviceNum}
                                </Typography>
                                {details.reservOption}
                              </Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                borderRadius: "20px",
                                height: "25px",
                                padding: "0 8px",
                                boxShadow: "none",
                                marginLeft: "10px",
                                backgroundColor: reservData.nextInfo
                                  ? "#000000"
                                  : "#FF0000",
                              }}
                            >
                              {reservData.nextInfo ? "신청 가능" : "신청 불가"}
                            </Button>
                          </ListItemSecondaryAction>
                        </Fragment>
                      )
                    )}
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {lockerNoData ? (
              <>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src="images/main_05.png" />
                  </ListItemAvatar>
                  <ListItemText primary="신청 내역이 없습니다." />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            ) : (
              reservationLocker.map((reservData, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src="images/main_05.png" />
                    </ListItemAvatar>
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <Fragment key={idx}>
                          <ListItemText
                            primary={details.reservDt}
                            secondary={
                              <Fragment>
                                <Typography
                                  sx={{ display: "inline", mr: 2 }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {details.deviceNum}
                                </Typography>
                                {details.reservOption}
                              </Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                borderRadius: "20px",
                                height: "25px",
                                padding: "0 8px",
                                boxShadow: "none",
                                marginLeft: "10px",
                                backgroundColor: reservData.nextInfo
                                  ? "#000000"
                                  : "#FF0000",
                              }}
                            >
                              {reservData.nextInfo ? "신청 가능" : "신청 불가"}
                            </Button>
                          </ListItemSecondaryAction>
                        </Fragment>
                      )
                    )}
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </div>
              ))
            )}
          </List>
        </Box>
        {/* <hr
          style={{ borderColor: "#f0f0f0", borderWidth: "0.2px", width: "85%" }}
        /> */}
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            width: "95%", // 전체 너비를 사용하도록 설정
            height: "4em",
            marginTop: "3em",
          }}
        >
          {allOk && (
            <Alert
              severity="warning"
              icon={false}
              sx={{
                textAlign: "left", // 텍스트를 좌측으로 정렬
                alignItems: "flex-start", // 내용을 상단에 정렬
                marginBottom: "1em",
              }}
            >
              * 락커 번호 및 독서실 번호가 이미 예약되어있을 경우 일괄 예약
              기능을 사용할수 없습니다.
            </Alert>
          )}

          <Button
            sx={{
              borderRadius: "10px",
              border: "1px solid #ccc",
              width: "100%",
            }}
            color="primary"
            disabled={allOk}
            variant="contained"
            endIcon={<CheckIcon />}
            onClick={() => handleClickOpen()}
          >
            <Typography variant="h6">
              {displayMonthName(months[2])} 일괄 신청 하기
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: "500px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {`${displayMonthName(months[2])} 일괄 신청 하시겠습니까?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            신청자 : {props.userState.userName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={reservation_save} autoFocus>
            신청
          </Button>
        </DialogActions>
      </Dialog>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationNext;
