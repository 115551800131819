import { useState, useEffect, useMemo } from "react";
import {
  Stack,
  useTheme,
  useMediaQuery,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import "react-calendar/dist/Calendar.css";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
interface propsType {
  userState: userState;
}
interface ReservationDetails {
  userName: string;
  userId: string;
  inputDt: string;
  reservDt: string;
  reservTime: string;
  deviceNum: string;
}

interface Reservations {
  [key: string]: ReservationDetails;
}

interface AttendanceData {
  [date: string]: string[];
}

interface ReservationInfo {
  sk: string;
  pk: string;
  reservations: Reservations;
}

interface Reservation {
  sk: string;
  pk: string;
  reservInfo: ReservationInfo;
  state: string;
}

HighchartsSankey(Highcharts);
interface Exercise {
  from: string;
  to: string;
  weight: number;
}

const monthNames = [
  "1월",
  "2월",
  "3월",
  "4월",
  "5월",
  "6월",
  "7월",
  "8월",
  "9월",
  "10월",
  "11월",
  "12월",
];

const ReservationHealthChart = (props: propsType) => {
  const theme = useTheme();
  const mainApi = new HttpMainApi();
  const [healthChartData, setHealthChartData] = useState<number[]>([]);
  const [ChartDate, setChartDate] = useState<string[]>([]);
  const [averageAttendance, setAverageAttendance] = useState<number[]>([]);

  const calculateAttendanceDays = (
    reservations: Record<string, string[]>,
    userId: string
  ) => {
    // 해당 사용자가 포함된 날짜를 필터링하고 길이를 계산하여 출근 일수를 반환
    return Object.values(reservations).filter((attendees) =>
      attendees.includes(userId)
    ).length;
  };

  const get_my_health_day = async () => {
    const userId = props.userState.userId;
    const sk = "헬스기록";
    const attendanceDays: number[] = []; // 각 달의 출근 일수를 저장할 배열

    // 현재 날짜와 12개월 전 날짜 구하기
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1; // JavaScript의 getMonth()는 0부터 시작하므로 +1
    let average_list = [];
    // 12개월 동안의 데이터를 조회
    for (let i = 0; i < 12; i++) {
      // 월이 0보다 작아지면 작년으로 넘어가야 함
      if (month === 0) {
        month = 12;
        year--;
      }

      const formatmonthpk = month.toString().padStart(2, "0");
      const pk = `${year}${formatmonthpk}/HEALTHDATA`;

      const param: any = {
        pk: pk,
        sk: sk,
      };

      try {
        const res = await mainApi.get_my_health_day(param);
        if (res.code === "200") {
          const reservations = res.response.result[0]?.reservations || {}; // 해당 달에 데이터가 없을 경우 빈 객체로 처리
          // console.log("reservations : ", reservations);
          average_list.push(get_average_day(reservations));
          const attendanceForMonth = calculateAttendanceDays(
            reservations,
            userId
          );
          attendanceDays.push(attendanceForMonth); // 월별 출근 일수를 리스트에 추가
        } else {
          attendanceDays.push(0); // API 요청 실패 시 출근 일수를 0으로 처리
          average_list.push(0);
        }
      } catch (error) {
        console.error(`Error fetching data for ${pk}:`, error);
        attendanceDays.push(0); // 요청 실패 시에도 0으로 처리
      }

      // 이전 달로 이동
      month--;
    }

    // console.log(`전체 출근 일수: ${attendanceDays}`);
    setHealthChartData(attendanceDays.reverse());
    setAverageAttendance(average_list.reverse());
  };

  const options: Highcharts.Options = {
    chart: {
      zooming: {
        type: "xy",
      },
    },
    title: {
      text: "나의 운동 기록",
      align: "left",
    },

    xAxis: [
      {
        categories: ChartDate,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value} 일",
          style: {},
        },
        title: {
          text: "운동일",
          style: {},
        },
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "left",
      verticalAlign: "top",
      backgroundColor:
        Highcharts.defaultOptions.legend?.backgroundColor ||
        "rgba(255,255,255,0.25)",
    },
    series: [
      {
        name: "나의 운동일",
        type: "column", // 막대 그래프로 설정
        data: healthChartData,
      },
      {
        name: "라포레 평균 운동일",
        type: "spline", // 선형 그래프
        data: averageAttendance,
      },
    ],
  };

  useEffect(() => {
    get_my_health_day();
  }, []);

  useEffect(() => {
    const today = new Date();
    let currentMonth = today.getMonth(); // 현재 월 (0부터 시작하므로 0 = 1월, 11 = 12월)

    const last12Months: string[] = [];

    for (let i = 0; i < 12; i++) {
      last12Months.push(monthNames[currentMonth]); // 현재 월부터 시작해서 배열에 추가
      currentMonth--; // 이전 월로 이동
      if (currentMonth < 0) {
        currentMonth = 11; // 월이 0보다 작으면 12월로 돌아감
      }
    }

    // 배열을 뒤집어 최근 달이 마지막에 오도록 정렬
    setChartDate(last12Months.reverse());
  }, []);

  const calculateAverageAttendance = (data: AttendanceData): number => {
    const userAttendance: { [key: string]: number } = {};

    // Count the number of times each user appears
    for (const users of Object.values(data)) {
      users.forEach((user) => {
        userAttendance[user] = (userAttendance[user] || 0) + 1;
      });
    }

    // Filter out users who have attended only once
    const filteredAttendance = Object.values(userAttendance).filter(
      (count) => count > 1
    );

    // Calculate the average attendance for users who attended more than once
    const totalAttendance = filteredAttendance.reduce(
      (sum, count) => sum + count,
      0
    );
    const uniqueUsers = filteredAttendance.length;

    const averageAttendance = uniqueUsers ? totalAttendance / uniqueUsers : 0;

    return Math.round(averageAttendance); // Round up the result
  };

  const get_average_day = (attendanceData: AttendanceData): number => {
    if (Object.keys(attendanceData).length === 0) {
      return 0;
    }
    const avg = calculateAverageAttendance(attendanceData);
    return avg;
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        {" "}
        {/* 원하는 만큼의 마진을 추가 */}
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};

export default ReservationHealthChart;
