import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { useTheme, useMediaQuery, TextField, Button } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const mainApi = new HttpMainApi();

interface propsType {
  userState: userState;
}

const MobilePassChpage = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setPassword2("");
    };
  }, []);

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };

  const fncChPwd = async () => {
    if (password === "" || password === undefined) {
      toastRef.current?.toast("비밀번호를 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password2 === "" || password2 === undefined) {
      toastRef.current?.toast("비밀번호 확인을 입력하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }
    if (password !== password2) {
      toastRef.current?.toast("비밀번호가 일치하지 않습니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return false;
    }

    const confirmCancel = window.confirm("비밀번호를 변경 하시겠습니까?");

    if (confirmCancel) {
      const encryptedPassword = hashPassword(password);
      setIsLoading(true);
      const sk = props.userState.userId;
      const pk = "COMMUNITY/USER";
      const param: any = {
        pk: pk,
        sk: sk,
        password: encryptedPassword,
      };
      const res = await mainApi.update_user_password(param);

      if (res.code === "200") {
        alert("비밀번호 변경이 완료되었습니다.");
        navigate("/mobilemain");
      } else {
        alert(
          `알 수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          p: isMobile ? 1 : 2,
          display: "flex",
          marginTop: 5,
        }}
      >
        <Box
          component={"div"}
          sx={{
            width: "90%",
            maxWidth: isMobile ? "100%" : "40rem",
            margin: "auto",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 2,
              borderRadius: "8px",
              border: "1px solid #e0e0e0",
            }}
          >
            <Stack spacing={2} direction="column">
              <TextField
                id="password"
                value={password}
                placeholder="비밀번호를 입력하세요"
                variant="outlined"
                label="비밀번호"
                fullWidth
                autoFocus
                required
                type="password"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <TextField
                id="password2"
                value={password2}
                placeholder="동일한 비밀번호를 입력하세요"
                variant="outlined"
                label="비밀번호 확인"
                fullWidth
                required
                type="password"
                autoComplete="off"
                inputProps={{
                  enterKeyHint: "Enter",
                  style: { padding: "12px 16px 10px 16px" },
                }}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
              />
              <Button variant="contained" onClick={fncChPwd}>
                비밀번호 변경
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MobilePassChpage;
