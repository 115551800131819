export const appConfig = {
  mainApiUrl:
    "https://b8v073ogbg.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  testApiUrl:
    "https://b8v073ogbg.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  secretKey: "detre",
  sessionName: "community",
  rememberidSSName: "remembercommunity", // 사이트마다 달라야 함
  seed: "11445532425",
  sessionTimeout: 1000 * 60 * 60 * 24, // 1440 minutes or 24 hours
  iotRegion: "ap-northeast-2",
  iotEndPoint: "a20gsl9wtf4xqv-ats.iot.ap-northeast-2.amazonaws.com",
  iotIdentityPollId: "ap-northeast-2:fbb88145-33f6-43e7-bcf9-d91236654889",
  REACT_APP_S3_ACCESS_KEY: "AKIASVOZMXVMZF5GUKOL",
  REACT_APP_S3_SECRET_KEY: "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",
  REACT_APP_S3_BUCKET_NAME: "detre-img",
};
