import { useState, useRef, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { userState } from "../../interface/MainInterface";

import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
}

const SignIn = (props: propsType) => {
  const toastRef: any = useRef();
  const userEmailRef: any = useRef();
  const userPwdRef: any = useRef();
  const verifyCodeRef: any = useRef();
  const userPwdConfirmRef: any = useRef();

  const [userEmail, setUserEmail] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [userPwdConfirm, setUserPwdConfirm] = useState("");
  const [realEmail, setRealEmail] = useState("");
  const [realPwd, setRealPwd] = useState("");
  const [needConfirm, setNeedConfirm] = useState(false);
  const [needNewPwd, setNeedNewPwd] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");

  const listener = (data: any) => {
    if (data?.payload?.data?.code === "UserNotFoundException") {
      toastRef.current?.toast("User not found.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    } else if (data?.payload?.data?.code === "NotAuthorizedException") {
      toastRef.current?.toast(
        "Please check your email or password",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
    } else if (data?.payload?.data?.code === "UserNotConfirmedException") {
      // 컨펌코드 넣기
      setNeedConfirm(true);
    } else if (data?.payload?.data?.code === "CodeMismatchException") {
      toastRef.current?.toast("Please check your verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
    } else {
      console.error(">>>>>> : " + data?.payload?.data);
    }
  };

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", listener);
    return unsubscribe;
  }, []);

  useEffect(() => {
    return () => {
      setUserEmail("");
      setUserPwd("");
      setUserPwdConfirm("");
      setNeedConfirm(false);
      setVerifyCode("");
      setRealEmail("");
      setRealPwd("");
    };
  }, []);

  const fncSignIn = async () => {
    if (userEmail === "" || userEmail === undefined) {
      toastRef.current?.toast("Please enter your email.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userEmailRef.current.focus();
      return;
    }
    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast("Please enter your password.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userPwdRef.current.focus();
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    try {
      await Auth.signIn(userEmail, userPwd);
      toastRef.current?.toast("Welcome " + userEmail, "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      window.location.href = "/mobilemain";
    } catch (error: any) {}
  };

  const autoSignIn = async () => {
    if (realEmail === "" || realEmail === undefined) {
      toastRef.current?.toast("Please enter your email.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userEmailRef.current.focus();
      return;
    }
    if (realPwd === "" || realPwd === undefined) {
      toastRef.current?.toast("Please enter your password.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userPwdRef.current.focus();
      return;
    }

    try {
      await Auth.signIn(realEmail, realPwd);
      toastRef.current?.toast("Welcome " + realEmail, "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      window.location.href = "/myAccount";
    } catch (error: any) {}
  };

  const fncConfirm = async () => {
    if (verifyCode === "" || verifyCode === undefined) {
      toastRef.current?.toast("Please enter your verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
      return;
    }

    try {
      await Auth.confirmSignUp(realEmail, verifyCode);
      toastRef.current?.toast("Success verify.", "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      setNeedConfirm(false);
      autoSignIn();
    } catch (error: any) {
      toastRef.current?.toast("Invalid verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
    }
  };

  const fncResendCode = async () => {
    try {
      await Auth.resendSignUp(realEmail);
      toastRef.current?.toast("Send your verify code.", "success", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
    } catch (err) {
      console.error("error resending code: ", err);
    }
  };

  const fncForgot = async () => {
    if (userEmail === "" || userEmail === undefined) {
      toastRef.current?.toast("Please enter your email.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      userEmailRef.current.focus();
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    try {
      await Auth.forgotPassword(userEmail);
      setNeedNewPwd(true);
      toastRef.current?.toast(
        "Send verify code to your email.",
        "success",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      verifyCodeRef.current.focust();
    } catch (err) {
      console.error(err);
    }
  };

  const fncNewPwd = async () => {
    if (verifyCode === "" || verifyCode === undefined) {
      toastRef.current?.toast("Please enter your verify code.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      verifyCodeRef.current.focust();
      return;
    }

    if (userPwd === "" || userPwd === undefined) {
      toastRef.current?.toast(
        "Please enter your new password.",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdRef.current.focus();
      return;
    }
    if (userPwdConfirm === "" || userPwdConfirm === undefined) {
      toastRef.current?.toast(
        "Please enter new password again.",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdConfirmRef.current.focus();
      return;
    }
    if (userPwd.length < 6) {
      toastRef.current?.toast(
        "Please enter password more than 6 char",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdRef.current.focus();
      return;
    }
    if (userPwd !== userPwdConfirm) {
      toastRef.current?.toast(
        "Passwords do not match. (new & again)",
        "error",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      userPwdConfirmRef.current.focus();
      return;
    }

    setRealEmail(userEmail);
    setRealPwd(userPwd);

    try {
      const data = await Auth.forgotPasswordSubmit(
        realEmail,
        verifyCode,
        userPwd
      );
      toastRef.current?.toast(
        "Success change your new password",
        "success",
        4000,
        { vertical: "top", horizontal: "center" }
      );
      setNeedConfirm(false);
      autoSignIn();
    } catch (err) {
      console.error(err);
    }
  };

  const handlePasswordEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncSignIn();
  };

  const handleVerifyCodeEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") fncConfirm();
  };

  return (
    <>
      <Box sx={{ display: "flex", height: "calc(100vh - 100px)" }}>
        <Box
          component={"div"}
          sx={{ width: "100%", maxWidth: "40rem", margin: "auto" }}
        >
          <Paper elevation={4} sx={{ p: 2 }}>
            {needConfirm ? (
              <Stack spacing={2} direction="column">
                <TextField
                  id="txtVerifyCode"
                  inputRef={verifyCodeRef}
                  value={verifyCode}
                  placeholder="인증번호를 입력해주세요."
                  variant="outlined"
                  autoFocus
                  fullWidth
                  required
                  onChange={(e) => {
                    setVerifyCode(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    handleVerifyCodeEnter(e);
                  }}
                  type="search"
                  autoComplete="off"
                  inputProps={{ enterKeyHint: "Enter" }}
                />
                <Button variant="contained" onClick={fncConfirm}>
                  Verify
                </Button>
                <Button variant="text" onClick={fncResendCode}>
                  Resend Code
                </Button>
              </Stack>
            ) : needNewPwd ? (
              <Stack spacing={2} direction="column">
                <TextField
                  inputRef={verifyCodeRef}
                  id="txtVerifyCode"
                  value={verifyCode}
                  placeholder="인증번호를 입력해주세요."
                  variant="outlined"
                  autoFocus
                  fullWidth
                  required
                  type="search"
                  autoComplete="off"
                  inputProps={{
                    enterKeyHint: "Enter",
                    style: { padding: "12px 16px 10px 16px" },
                  }}
                  onChange={(e) => {
                    setVerifyCode(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    handleVerifyCodeEnter(e);
                  }}
                />
                <TextField
                  id="sighInPwd"
                  inputRef={userPwdRef}
                  value={userPwd}
                  placeholder="Enter your new password."
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  autoComplete="current-password"
                  inputProps={{
                    autoComplete: "new-password",
                    enterKeyHint: "Enter",
                    style: { padding: "12px 16px 10px 16px" },
                  }}
                  onChange={(e) => {
                    setUserPwd(e.target.value);
                  }}
                />
                <TextField
                  id="sighInPwdComfirm"
                  inputRef={userPwdConfirmRef}
                  value={userPwdConfirm}
                  placeholder="Enter new password again."
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  autoComplete="current-password"
                  inputProps={{
                    autoComplete: "new-password",
                    enterKeyHint: "Enter",
                    style: { padding: "12px 16px 10px 16px" },
                  }}
                  onChange={(e) => {
                    setUserPwdConfirm(e.target.value);
                  }}
                />
                <Button variant="contained" onClick={fncNewPwd}>
                  Change Password
                </Button>
                <Button variant="text" onClick={fncResendCode}>
                  Resend Code
                </Button>{" "}
              </Stack>
            ) : (
              <Stack spacing={2} direction="column">
                <TextField
                  id="signInUserEmail"
                  inputRef={userEmailRef}
                  value={userEmail}
                  placeholder="이메일을 입력해주세요."
                  variant="outlined"
                  autoFocus
                  fullWidth
                  required
                  autoComplete="on"
                  inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
                <TextField
                  id="signInPwd"
                  inputRef={userPwdRef}
                  value={userPwd}
                  placeholder="패스워드를 입력해주세요."
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  autoComplete="current-password"
                  inputProps={{ style: { padding: "12px 16px 10px 16px" } }}
                  onChange={(e) => {
                    setUserPwd(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    handlePasswordEnter(e);
                  }}
                />

                <Button variant="contained" onClick={fncSignIn}>
                  Sign In
                </Button>
                <Button variant="outlined" onClick={fncForgot}>
                  Forgot password
                </Button>
              </Stack>
            )}
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
    </>
  );
};

export default SignIn;
