import { ApiBase } from "./api-base";
import { appConfig } from "../config/Config";

export class HttpMainApi extends ApiBase {
  onApiName(): string {
    return "AdminMainApi";
  }

  onUrlPath(): string {
    if (window.location.hostname === "localhost") return appConfig.testApiUrl;
    else return appConfig.mainApiUrl;
  }

  public async put_member_join(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_member_join";
    const response = this.requestPost(requestBody);
    return response;
  }
  public async put_member_ch_password(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_member_ch_password";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_member_login(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_member_login";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_password_check(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_password_check";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_reserv_time(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_reserv_time";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_reserv_qrcode(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_reserv_qrcode";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_now_qrcode_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_now_qrcode_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_now_qrcode_counting(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_now_qrcode_counting";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_now_inout_counting(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_now_inout_counting";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_health_day(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_health_day";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_health_rank_data(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_health_rank_data";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_cancel_qrcode_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_cancel_qrcode_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_reserv_month(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_reserv_month";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_month_reserv_data(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_month_reserv_data";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }
  public async get_price(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_price";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_qrcode_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_qrcode_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_study_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_study_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_month_reserv_data(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_month_reserv_data";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_healthchart(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_healthchart";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_reservation_diff_check(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_reservation_diff_check";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_one_hour_check(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_one_hour_check";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_reservation_check(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_reservation_check";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_user_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_user_list";
    const response = this.requestPost(requestBody);
    return response;
  }
  public async update_user_password(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_user_password";
    const response = this.requestPost(requestBody);
    return response;
  }
  public async update_user_delete(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_user_delete";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_user_finger_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_user_finger_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_locker_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_locker_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_book_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_book_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_next_month_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_next_month_info";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_locker_password(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_locker_password";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_locker_user(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_locker_user";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_user_finger_community_info(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_user_finger_community_info";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_user_finger(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_user_finger";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_account_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_account_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_account_inout_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_account_inout_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  //입장 -> 퇴장 프로세스
  public async put_health_inout(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_health_inout";
    const response = this.requestPost(requestBody);
    return response;
  }
  //입장과 퇴장 한번에 프로세스
  public async put_health_total_inout(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_health_total_inout";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_account_all_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_account_all_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_my_account_all_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_my_account_all_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async update_price(param: any) {
    let requestBody: any = param;
    requestBody.command = "update_price";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_kvs_play(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_kvs_play";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_cancel_my_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_cancel_my_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async put_cancel_my_reservation_price(param: any) {
    let requestBody: any = param;
    requestBody.command = "put_cancel_my_reservation_price";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_day_reservation(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_day_reservation";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async get_holiday_list(param: any) {
    let requestBody: any = param;
    requestBody.command = "get_holiday_list";
    const response = this.requestPost(requestBody);
    return response;
  }

  public async post(param: any) {
    const response = await this.requestPost(param);
    return response;
  }
}
