import { useState, useRef, useEffect } from "react";
import { Stack, Box, Typography, Grid } from "@mui/material";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { userState } from "../../interface/MainInterface";
import MobileRealTimeCard from "../components/MobileRealTimeCard";

interface propsType {
  userState: userState;
  startMode: string;
  selectTheme: any;
  height: string;
}

const MobileRealTime = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [themeList, setThemeList] = useState<any>([]);

  useEffect(() => {
    const exampleThemes = [
      { id: 1, title: "타석1" },
      { id: 2, title: "타석2" },
      { id: 3, title: "ROOM" },
    ];
    setThemeList(exampleThemes);
  }, []);
  useEffect(() => {
    return () => {
      setIsLoading(false);
      setThemeList([]);
    };
  }, []);

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box className="sub-header-root">
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Typography variant="h5" gutterBottom className="sub-header-title">
              실시간 예약 현황
            </Typography>
          </Stack>
        </Box>
        <Stack
          direction={"column"}
          spacing={1}
          className="theme-select-scroll"
          sx={{ height: props.height }}
        >
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              {themeList.map((themeInfo: any, index: number) => (
                <Grid
                  key={`pressCard-${index}`}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                >
                  <MobileRealTimeCard
                    userState={props.userState}
                    themeInfo={themeInfo}
                    startMode={props.startMode}
                    selectTheme={(theme: any) => {
                      props.selectTheme(theme);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Stack>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MobileRealTime;
