import React, { Fragment, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import Toast from "../../utils/Toast";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import Typography from "@mui/material/Typography";
import "react-calendar/dist/Calendar.css";
import IoTClient from "../components/IoTClient";
import { appConfig } from "../../config/Config";
interface propsType {
  userState: userState;
}
const mainApi = new HttpMainApi();
let callLogin = false;

const HealthUseMember = (props: propsType) => {
  const iotRef: any = useRef();
  const toastRef: any = useRef();

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    if (!callLogin) {
      window.location.reload(); // IoT다시 맺기
    }
  };

  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `member/coummnity/count`) {
      // 구독중인 라이브
      console.log("구독!");
    }
  }
  // 페이지 최초 로딩시
  useEffect(() => {
    callLogin = true;
  }, []);
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          margin: "auto",
        }}
      >
        <Box sx={{ padding: 2 }}></Box>
        <Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="button"
              gutterBottom
              sx={{
                textAlign: "left",
                margin: "auto",
                mt: 2,
                ml: 3,
              }}
            >
              MQTT TEST
              <IoTClient
                ref={iotRef}
                Seq="10"
                iotRegion={appConfig.iotRegion}
                iotEndPoint={appConfig.iotEndPoint}
                recieveIoTMessage={recieveIoTMessage}
                iotLost={iotLost}
                iotIdentityPollId={appConfig.iotIdentityPollId}
              />
            </Typography>
          </Box>
        </Box>
        {/* <hr
          style={{ borderColor: "#f0f0f0", borderWidth: "0.2px", width: "85%" }}
        /> */}
      </Stack>

      <Toast ref={toastRef} />
    </>
  );
};

export default HealthUseMember;
