import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import * as XLSX from "xlsx";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CryptoJS from "crypto-js";
interface propsType {
  userState: userState;
}
const mainApi = new HttpMainApi();

const UserList = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [userName, setUserName] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_user_list = async () => {
    setIsLoading(true);
    setUserList([]);
    const pk = "COMMUNITY/USER";
    const sk = userName;
    const param: any = {
      pk: pk,
      sk: sk,
      selectLevel: selectLevel,
    };

    const res = await mainApi.get_user_list(param);
    if (res.code === "200") {
      setUserList(res.response.result);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const hashPassword = (password: string) => {
    return CryptoJS.SHA256(password).toString();
  };
  // 패스워드 초기화 버튼 컴포넌트
  const renderResetPasswordButton = (params: any) => (
    <Button
      color="primary"
      onClick={() => handleResetPassword(params.row.pk, params.row.sk)}
    >
      <LockResetIcon />
    </Button>
  );

  const handleResetPassword = async (pk: string, sk: string) => {
    const confirmCancel = window.confirm(
      sk +
        " 회원의 패스워드를 초기화 하시겠습니까??\n[초기화 정보]패스워드는 회원 아이디와 동일하게 초기화 됩니다."
    );
    if (confirmCancel) {
      const encryptedPassword = hashPassword(sk);
      const param: any = {
        pk: pk,
        sk: sk,
        password: encryptedPassword,
      };

      const res = await mainApi.update_user_password(param);
      if (res.code === "200") {
        alert("초기화 되었습니다. (ID : 아이디 / 패스워드 : 아이디)");
        get_user_list();
      } else if (res.code === "400") {
        alert("초기화에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  // 회원 탈퇴
  const renderdeleteUserButton = (params: any) => (
    <Button
      color="primary"
      onClick={() => handleDeleteUser(params.row.pk, params.row.sk)}
    >
      <DeleteIcon />
    </Button>
  );

  const handleDeleteUser = async (pk: string, sk: string) => {
    const confirmCancel = window.confirm(
      sk +
        " 회원을 탈퇴 하시겠습니까??\n[주의]탈퇴한 회원은 복구가 불가능합니다.\n*탈퇴 회원은 지금부터 관리비 조회시 제외됩니다.(이번달 관리비 청구 필수!!)"
    );
    if (confirmCancel) {
      const { formatpk } = getFormattedDateTime();

      const param: any = {
        pk: pk,
        sk: sk,
        userLevel: "탈퇴",
        deleteDt: formatpk,
      };

      const res = await mainApi.update_user_delete(param);
      if (res.code === "200") {
        alert("탈퇴 되었습니다.");
        get_user_list();
      } else if (res.code === "400") {
        alert("탈퇴에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const renderLevelButton = (params: any) => (
    <Button
      color="primary"
      onClick={() =>
        handleLevelUser(params.row.pk, params.row.sk, params.row.userLevel)
      }
    >
      <GroupAddIcon />
    </Button>
  );

  const handleLevelUser = async (pk: string, sk: string, userLevel: string) => {
    if (userLevel === "탈퇴" || userLevel === "회원") {
      alert("회원 정보가 '신청' 상태가 아닙니다.");
      return;
    }

    const confirmCancel = window.confirm(
      sk +
        " 가입 승인 하시겠습니까??\n\n*승인된 회원은 지금부터 시설 신청 및 예약이 가능합니다."
    );
    if (confirmCancel) {
      const { formatpk } = getFormattedDateTime();

      const param: any = {
        pk: pk,
        sk: sk,
        userLevel: "회원",
        deleteDt: formatpk,
      };

      const res = await mainApi.update_user_delete(param);
      if (res.code === "200") {
        alert("승인 되었습니다.");
        get_user_list();
      } else if (res.code === "400") {
        alert("승인에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const renderDeferButton = (params: any) => (
    <Button
      color="primary"
      onClick={() =>
        handleDeferUser(params.row.pk, params.row.sk, params.row.userLevel)
      }
    >
      <PersonAddDisabledIcon />
    </Button>
  );

  const handleDeferUser = async (pk: string, sk: string, userLevel: string) => {
    if (userLevel === "탈퇴") {
      alert("회원 정보가 '탈퇴' 상태입니다. 보류로 바꿀수 없습니다.");
      return;
    }

    const confirmCancel = window.confirm(
      sk +
        " 보류 하시겠습니까??\n\n*보류된 회원은 관리실에 직접 오셔야 승인됩니다."
    );
    if (confirmCancel) {
      const { formatpk } = getFormattedDateTime();

      const param: any = {
        pk: pk,
        sk: sk,
        userLevel: "보류",
        deleteDt: formatpk,
      };

      const res = await mainApi.update_user_delete(param);
      if (res.code === "200") {
        alert("보류 되었습니다.");
        get_user_list();
      } else if (res.code === "400") {
        alert("보류에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const renderFingerButton = (params: any) => (
    <Button
      color="primary"
      onClick={() =>
        handrFingerButton(
          params.row.pk,
          params.row.sk,
          params.row.fingerState,
          params.row.fingerInputDt
        )
      }
    >
      <FingerprintIcon />
    </Button>
  );

  const handrFingerButton = async (
    pk: string,
    sk: string,
    fingerState: string,
    fingerInputDt: string
  ) => {
    const { formattedDateTime } = getFormattedDateTime();
    let StateMemo = "";
    let deleteDt = "";
    if (fingerState === "등록") {
      StateMemo = "지문 [사용안함] 상태로 업데이트 하시겠습니까?";
      fingerState = "";
      fingerInputDt = fingerInputDt;
      deleteDt = formattedDateTime;
    } else {
      StateMemo = "지문 [등록] 상태로 업데이트 하시겠습니까?";
      fingerState = "등록";
      fingerInputDt = formattedDateTime;
      deleteDt = "";
    }

    const confirmCancel = window.confirm(sk + StateMemo);
    if (confirmCancel) {
      const param: any = {
        pk: pk,
        sk: sk,
        fingerState: fingerState,
        fingerInputDt: fingerInputDt,
        fingerDeleteDt: deleteDt,
      };

      const res = await mainApi.update_user_finger(param);
      if (res.code === "200") {
        alert("지문 정보가 수정 되었습니다.");
        get_user_list();
      } else if (res.code === "400") {
        alert(
          "지문 정보 업데이트에 실패하였습니다.\n다시 조회 후 진행해주세요."
        );
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  const padFourDigits = (value: number) => {
    const paddedValue = String(value).padStart(4, "0"); // 4자리로 만들고 앞에 0을 채웁니다.
    return paddedValue;
  };

  const columns: GridColDef[] = [
    {
      field: "userId",
      headerName: "회원 아이디",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userName",
      headerName: "회원 이름",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "combinedValue",
      headerName: "동/호",
      width: 150,

      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        `${params.row.userDong}동 ${padFourDigits(params.row.userHo)}호`,
    },
    {
      field: "readInputDt",
      headerName: "가입 신청일",
      type: "number",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userLevel",
      headerName: "권한",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userchLevel",
      headerName: "권한 승인",
      width: 110,
      editable: false,
      renderCell: renderLevelButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fingerState",
      headerName: "지문 상태",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fingerUpdate",
      headerName: "상태 변경",
      width: 110,
      editable: false,
      renderCell: renderFingerButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userchDefer",
      headerName: "권한 보류",
      width: 110,
      editable: false,
      renderCell: renderDeferButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "resetPassword",
      headerName: "패스워드 초기화",
      width: 150,
      renderCell: renderResetPasswordButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "deleteUser",
      headerName: "회원 탈퇴",
      width: 100,
      renderCell: renderdeleteUserButton,
      align: "center",
      headerAlign: "center",
    },
  ];

  const [selectLevel, setSelectLevel] = useState("신청");
  const handleLevelChange = (event: SelectChangeEvent) => {
    setSelectLevel(event.target.value as string);
  };

  const downloadExcel = (accountList: any[], columns: any[]) => {
    // 각 행에 대해 엑셀 파일에 포함될 값을 계산
    const dataForExcel = accountList.map((row) => {
      let newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
        if (col.valueGetter) {
          newRow[col.headerName] = col.valueGetter({ row });
        } else {
          newRow[col.headerName] = row[col.field];
        }
      });
      return newRow;
    });

    // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts");
    XLSX.writeFile(workbook, "accounts.xlsx");
  };
  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box sx={{ position: "absolute", top: "60px", right: "20px" }}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">권한</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectLevel}
                  label="상태"
                  onChange={handleLevelChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"신청"}>신청</MenuItem>
                  <MenuItem value={"회원"}>회원</MenuItem>
                  <MenuItem value={"보류"}>보류</MenuItem>
                  <MenuItem value={"탈퇴"}>탈퇴</MenuItem>
                  <MenuItem value={"관리자"}>관리자</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              id="userId"
              value={userName}
              placeholder="이름"
              variant="outlined"
              label="이름"
              autoFocus
              fullWidth
              // required
              type="text"
              autoComplete="off"
              inputProps={{
                enterKeyHint: "Enter",
                style: { padding: "12px 16px 10px 16px" },
              }}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              sx={{ marginTop: "9px !important" }}
            />
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                get_user_list();
              }}
              sx={{
                borderRadius: "4px",
                width: "54px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              <SearchIcon />
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => downloadExcel(userList, columns)}
              sx={{
                borderRadius: "4px",
                width: "94px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              <FileDownloadIcon />
            </Button>
          </Stack>
        </Box>
        <Box className="sub-header-root" sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            회원 관리
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid>
            <DataGrid
              rows={userList}
              columns={columns}
              disableRowSelectionOnClick
              style={{ height: 500 }}
              getRowId={(row) => (row.userId ? row.userId.toString() : "")}
            />
            {userList.map((userList: any, index: number) => (
              <Grid
                key={`pressCard-${index}`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
              ></Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default UserList;
