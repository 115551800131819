import React, { useEffect, useState, useRef } from "react";
import { HttpMainApi } from "../../interface/main-api";
import ReactPlayer from "react-player";
// 날짜 포맷팅 함수 (예시)
const getFormattedDateTime = () => {
  const now = new Date();
  const formatpk = now.toISOString().split("T")[0];
  return { formatpk };
};

const mainApi = new HttpMainApi();

const KvsPlayerView: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hlsUrl, setHlsUrl] = useState<string | null>(null);

  // HLS Video Stream API 호출 함수
  const fnHLSVideoStream = async () => {
    const { formatpk } = getFormattedDateTime();

    const param = {
      // 필요한 값을 여기 넣습니다.
      date: formatpk,
      streamName: "community-Section-S01-TV01", // 예시로 Stream Name 추가
    };

    try {
      const res = await mainApi.get_kvs_play(param); // mainApi의 kvsplay 호출
      if (res.code === "200") {
        console.log("res.data.hlsUrl : ", res.data.hlsUrl);
        setHlsUrl(res.data.hlsUrl); // 성공 시 HLS URL 설정
      } else if (res.code === "400") {
        alert("실행에 실패하였습니다.\n다시 진행해주세요.");
      } else {
        alert(
          `알 수 없는 에러가 발생했습니다.\n- CODE: ${res.code}\n- Message: ${res.response.error_msg}`
        );
      }
    } catch (error) {
      console.error("Error fetching HLS URL:", error);
      alert("HLS URL을 가져오는 도중 오류가 발생했습니다.");
    }
  };

  // 컴포넌트가 마운트될 때 HLS 스트림을 시작합니다.
  useEffect(() => {
    fnHLSVideoStream();
  }, []);

  return (
    <div>
      {hlsUrl ? (
        <ReactPlayer
          url={hlsUrl}
          playing
          controls
          width="100%"
          muted // 자동 재생을 위해 음소거 설정
        />
      ) : (
        <p>Loading HLS stream...</p>
      )}
    </div>
  );
};

export default KvsPlayerView;
