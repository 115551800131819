import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
interface propsType {
  userState: userState;
}
interface Month {
  value: string;
  label: string;
}
const mainApi = new HttpMainApi();

const ReservationAccount = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [accountList, setAccountList] = useState<any>([]);
  const [userName, setUserName] = useState("");
  const [totalPrice, setTotalPrice] = useState<string>("0");
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setAccountList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_account_list = async () => {
    setIsLoading(true);
    setAccountList([]);
    const pk = props.userState.userId;
    const param: any = {
      pk: pk,
      sk: selectMonth,
      selectState: comunity,
    };

    const res = await mainApi.get_my_account_all_list(param);

    if (res.code === "200") {
      setAccountList(res.response.result);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const [comunity, setComunity] = useState("ALL");
  const handleChange = (event: SelectChangeEvent) => {
    setAccountList([]);
    setComunity(event.target.value as string);
  };
  const [selectState, setSelectState] = useState("신청");
  const handleStateChange = (event: SelectChangeEvent) => {
    setSelectState(event.target.value as string);
  };

  const generateMonths = () => {
    const months = [];
    const currentDate = new Date();
    for (let i = -1; i < 6; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const yearMonth = `${date.getFullYear()}${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;
      const formattedMonth = `${date.getFullYear()}년 ${String(
        date.getMonth() + 1
      ).padStart(2, "0")}월`;

      months.push({ value: yearMonth, label: formattedMonth });
    }

    return months;
  };
  const [selectMonth, setSelectMonth] = useState<string>("");
  const [months, setMonths] = useState<Month[]>([]); // 여기에서 타입을 명시적으로 지정

  const handleMonthChange = (event: SelectChangeEvent) => {
    setSelectMonth(event.target.value as string);
  };

  useEffect(() => {
    const generatedMonths = generateMonths();
    setMonths(generatedMonths);
    if (generatedMonths.length > 0) {
      setSelectMonth(generatedMonths[1].value);
    }
  }, []);

  function formatInputDt(inputDt: string): string {
    // 정규 표현식을 사용하여 년, 월, 일을 추출합니다.
    const matches = inputDt.match(/(\d{4})년 (\d{2})월 (\d{2})일/);

    if (matches) {
      // matches[1]은 년, matches[2]는 월, matches[3]는 일에 해당합니다.
      // 추출된 값들을 연결하여 반환합니다.
      return `${matches[1]}${matches[2]}${matches[3]}`;
    } else {
      // 일치하는 부분이 없으면 빈 문자열을 반환합니다.
      return "";
    }
  }
  function findLaterDate(inputDt: string, firstDt: string): string {
    // 날짜 형식을 ISO 8601로 변환
    const formattedInputDt = `${inputDt.slice(0, 4)}-${inputDt.slice(
      4,
      6
    )}-${inputDt.slice(6)}`;
    const formattedFirstDt = `${firstDt.slice(0, 4)}-${firstDt.slice(
      4,
      6
    )}-${firstDt.slice(6)}`;

    // Date 객체 생성
    const dateInputDt = new Date(formattedInputDt);
    const dateFirstDt = new Date(formattedFirstDt);

    // 두 날짜 비교
    if (dateInputDt > dateFirstDt) {
      return inputDt; // inputDt가 더 큰 날짜
    } else {
      return firstDt; // firstDt가 더 크거나 두 날짜가 같은 경우
    }
  }
  function findFasterDate(inputDt: string, firstDt: string): string {
    // 날짜 형식을 ISO 8601로 변환
    const formattedInputDt = `${inputDt.slice(0, 4)}-${inputDt.slice(
      4,
      6
    )}-${inputDt.slice(6)}`;
    const formattedFirstDt = `${firstDt.slice(0, 4)}-${firstDt.slice(
      4,
      6
    )}-${firstDt.slice(6)}`;

    // Date 객체 생성
    const dateInputDt = new Date(formattedInputDt);
    const dateFirstDt = new Date(formattedFirstDt);

    // 두 날짜 비교
    if (dateInputDt < dateFirstDt) {
      return inputDt; // inputDt가 더 큰 날짜
    } else {
      return firstDt; // firstDt가 더 크거나 두 날짜가 같은 경우
    }
  }

  function fn_useDt(row: any): string {
    const { formatpk } = getFormattedDateTime();
    const firstDt = row.pk.split("/")[0] + "01";
    const year = parseInt(row.pk.split("/")[0].substring(0, 4), 10);
    const month = parseInt(row.pk.split("/")[0].substring(4, 6), 10) - 1;

    const lastDate = new Date(year, month + 1, 0);
    const lastDt = `${lastDate.getFullYear()}${(lastDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}${lastDate.getDate().toString().padStart(2, "0")}`;

    const startDtTemp = formatInputDt(row.inputDt);
    const endDtTemp = row.cancelDt ? formatInputDt(row.cancelDt) : formatpk;
    const startDt = findLaterDate(firstDt, startDtTemp);
    const endDt = findFasterDate(lastDt, endDtTemp);

    const today = new Date(
      endDt.slice(0, 4) + "-" + endDt.slice(4, 6) + "-" + endDt.slice(6)
    ).getTime();
    const firstDay = new Date(
      startDt.slice(0, 4) + "-" + startDt.slice(4, 6) + "-" + startDt.slice(6)
    ).getTime();
    const diffInDays =
      Math.floor((today - firstDay) / (1000 * 60 * 60 * 24)) + 1;

    return diffInDays.toString();
  }
  function fn_useTime(row: any): string {
    const reservations = row.reservInfo?.reservations;
    const firstReservationKey = reservations
      ? Object.keys(reservations)[0]
      : null;
    const reservOption = firstReservationKey
      ? reservations[firstReservationKey].reservOption
      : null;

    if (!reservOption) {
      return "—"; // 예약 옵션이 없는 경우 대시 반환
    }
    // console.log(reservOption);
    const splitTime = reservOption.split(",");
    const roundTime = Math.round(splitTime.length / 2);
    return String(roundTime);
  }

  useEffect(() => {
    const sumPrice = accountList.reduce(
      (acc: number, curr: any) => acc + (curr.communityPrice || 0),
      0
    );
    setTotalPrice(addCommasToNumber(sumPrice));
  }, [accountList]);

  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    const commonColumns: GridColDef[] = [];

    if (comunity === "ALL") {
      // commonColumns의 처음부터 원하는 인덱스까지와 그 이후 부분을 나누어 관리
      const allColumns: GridColDef[] = [
        {
          field: "pk",
          headerName: "월/시설정보",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "communityPrice",
          headerName: "청구금액",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.communityPrice || 0,
        },
        {
          field: "reservState",
          headerName: "상태",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
      ];
      setColumns(allColumns);
    } else if (
      comunity === "STUDY" ||
      comunity === "HEALTHLOCKER" ||
      comunity === "GOLFLOCKER"
    ) {
      const ectColumns: GridColDef[] = [
        {
          field: "inputDt",
          headerName: "신청일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "communityPrice",
          headerName: "청구금액",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.communityPrice || 0,
        },
        {
          field: "reservOption",
          headerName: "번호",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservOption = firstReservationKey
              ? reservations[firstReservationKey].reservOption
              : "—";
            return reservOption;
          },
        },
        {
          field: "reservDt",
          headerName: "이용일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservDt = firstReservationKey
              ? reservations[firstReservationKey].reservDt
              : "—";
            return reservDt;
          },
        },
        {
          field: "reservState",
          headerName: "상태",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "cancelDt",
          headerName: "취소일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.cancelDt || "—",
        },
        {
          field: "useDt",
          headerName: "사용일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => fn_useDt(params.row) || "—",
        },
      ];
      setColumns(ectColumns);
    } else if (comunity === "SCREEN" || comunity === "SCREENROOM") {
      const ectColumns: GridColDef[] = [
        {
          field: "inputDt",
          headerName: "신청일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "communityPrice",
          headerName: "청구금액",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.communityPrice || 0,
        },

        {
          field: "reservDt",
          headerName: "이용일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservDt = firstReservationKey
              ? reservations[firstReservationKey].reservDt
              : "—";
            return reservDt;
          },
        },
        {
          field: "reservOption",
          headerName: "이용시간",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservOption = firstReservationKey
              ? reservations[firstReservationKey].reservOption
              : "—";
            return reservOption;
          },
        },
        {
          field: "useTime",
          headerName: "총 시간",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => fn_useTime(params.row) || "—",
        },
        {
          field: "info_01",
          headerName: "이용 홀",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.info_01 || "—",
        },
        {
          field: "reservState",
          headerName: "상태",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "cancelDt",
          headerName: "취소일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.cancelDt || "—",
        },
      ];
      setColumns(ectColumns);
    } else {
      const defaultColumns: GridColDef[] = [
        {
          field: "inputDt",
          headerName: "신청일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "communityPrice",
          headerName: "청구금액",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.communityPrice || 0,
        },
        {
          field: "reservDt",
          headerName: "이용일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => {
            const reservations = params.row.reservInfo?.reservations;
            const firstReservationKey = reservations
              ? Object.keys(reservations)[0]
              : null;
            const reservDt = firstReservationKey
              ? reservations[firstReservationKey].reservDt
              : "—";
            return reservDt;
          },
        },
        {
          field: "reservState",
          headerName: "상태",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
        },
        {
          field: "cancelDt",
          headerName: "취소일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => params.row.cancelDt || "—",
        },
        {
          field: "useDt",
          headerName: "사용일",
          width: 200,
          editable: false,
          align: "center",
          headerAlign: "center",
          valueGetter: (params) => fn_useDt(params.row) || "—",
        },
      ];
      setColumns(defaultColumns);
    }
  }, [comunity]);

  const addCommasToNumber = (value: number): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          margin: "10px",
          pt: "25px !important",
          pb: "25px !important",
        }}
      >
        <Box sx={{ flex: "1 1 100%" }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            커뮤니티 관리비 납부 예정 금액
          </Typography>
        </Box>
        <hr
          style={{ borderColor: "#ddd", borderWidth: "0.3px", width: "100%" }}
        />
        <Stack direction="row" spacing={2} sx={{ width: "100%", mb: 2 }}>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Box sx={{ flex: "1 1 40%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">시설</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={comunity}
                  label="시설"
                  onChange={handleChange}
                  sx={{ height: 46 }}
                >
                  <MenuItem value={"ALL"}>전체</MenuItem>
                  <MenuItem value={"GOLF"}>골프장</MenuItem>
                  <MenuItem value={"HEALTH"}>헬스장</MenuItem>
                  <MenuItem value={"STUDY"}>독서실</MenuItem>
                  <MenuItem value={"HEALTHLOCKER"}>헬스장락커</MenuItem>
                  <MenuItem value={"GOLFLOCKER"}>골프장락커</MenuItem>
                  <MenuItem value={"SCREENROOM"}>스크린게임</MenuItem>
                  <MenuItem value={"SCREEN"}>스크린타석</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ flex: "1 1 40%" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">기준</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectMonth}
                  label="정산일"
                  onChange={handleMonthChange}
                  sx={{ height: 46 }}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ flex: "1 1 20%" }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  get_account_list();
                }}
                sx={{
                  borderRadius: "4px",
                  width: "100%",
                  height: "46px",
                }}
              >
                <SearchIcon />
              </Button>
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1, width: "100%" }}>
          {/* Box의 너비를 100%로 설정 */}
          <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
            {/* Grid 컨테이너의 너비를 100%로 설정하고 마진을 제거 */}
            <Grid
              item
              xs={12}
              style={{
                width: "100%",
                height: 500,
                paddingLeft: 0,
              }}
            >
              {/* Grid 아이템의 너비를 100%로 설정 */}
              <DataGrid
                rows={accountList}
                columns={columns}
                disableRowSelectionOnClick
                style={{ width: "100%", height: "100%" }}
                getRowId={(row) => (row.sk ? row.sk.toString() : "")}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>총 금액: {totalPrice} 원</div>

          {/* 하단에 총 가격 표시 */}
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationAccount;
