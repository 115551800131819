import React, { useEffect, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box, Stack, Button, Typography } from "@mui/material";
import { userState } from "../interface/MainInterface";
import CloseIcon from "@mui/icons-material/Close";
import { getFormattedDateTime } from "../utils/getFormattedDateTime";
import Webcam from "react-webcam";
import { BrowserQRCodeReader, IScannerControls } from "@zxing/browser";
import { HttpMainApi } from "../interface/main-api";
import Toast from "./Toast";

const mainApi = new HttpMainApi();

interface propsType {
  userState: userState;
  qrPkData: string;
  qrSkData: string;
  cameraOpen: boolean;
  cameraClickClose: () => void;
}

let checkNum = 0;

const CameraQrcodePage = (props: propsType) => {
  const webcamRef = useRef<Webcam>(null);
  const toastRef: any = useRef();
  const scannerControlsRef = useRef<IScannerControls | null>(null);

  useEffect(() => {
    if (props.cameraOpen) {
      const scan = async () => {
        try {
          if (props.cameraOpen && webcamRef.current) {
            const video = webcamRef.current.video;
            if (video) {
              const codeReader = new BrowserQRCodeReader();
              const controls = await codeReader.decodeFromVideoElement(
                video,
                async (result) => {
                  if (result) {
                    const scannedData = result.getText();

                    if (checkNum === 0) {
                      if (
                        scannedData === "epdpxmfmfkvhfpzjabslxltpsxjgpftmwkd"
                      ) {
                        const { formattedTime, formatmonthpk } =
                          getFormattedDateTime();

                        const userId = props.qrPkData;

                        // 1.헬스 신청 확인 (유효하지 않은 QR코드입니다.)
                        const pk = `${formatmonthpk}/HEALTH`;
                        const sk = props.qrSkData;
                        const param: any = {
                          pk: pk,
                          sk: sk,
                        };
                        const res = await mainApi.get_qrcode_reservation(param);
                        if (res.code === "200") {
                          const {
                            formatpk,
                            formathourspk,
                            formatmonthpk,
                            formattedDate,
                          } = getFormattedDateTime();
                          const pk = `${formatmonthpk}/INOUT`;
                          const param2: any = {
                            pk: pk,
                            sk: formatpk + formathourspk,
                            skSearch: formatpk,
                            inputDt: formattedDate,
                            displayTime: formattedTime,
                            userId: userId,
                          };
                          // 입장과 퇴장 동시 이벤트로 변경
                          const res2 = await mainApi.put_health_total_inout(
                            param2
                          );
                          if (res2.code === "200") {
                            // 입장 이벤트
                            console.log("입장 성공");
                            checkNum = 1;

                            props.cameraClickClose();
                            return;
                          } else if (res2.code === "301") {
                            console.log("퇴장 성공");
                            // 퇴장이벤트
                          } else {
                            toastRef.current?.toast(
                              "QR코드를 새로고침 후 다시 시도해 주세요",
                              "error",
                              4000,
                              {
                                vertical: "top",
                                horizontal: "center",
                              }
                            );
                            return;
                          }
                        } else if (res.code === "400") {
                          toastRef.current?.toast(
                            "QR코드가 유효하지 않습니다.",
                            "error",
                            4000,
                            {
                              vertical: "top",
                              horizontal: "center",
                            }
                          );
                          return;
                        }
                      } else {
                        toastRef.current?.toast(
                          "QR코드가 유효하지 않습니다.",
                          "error",
                          4000,
                          {
                            vertical: "top",
                            horizontal: "center",
                          }
                        );
                        return;
                      }
                    } else {
                      props.cameraClickClose();
                      return;
                    }
                  }
                }
              );

              scannerControlsRef.current = controls;
            }
          }
        } catch (error) {
          console.error("QR Code scan error: ", error);
        }
      };

      scan();
    }

    return () => {
      // Cleanup code to stop the QR code reader when the component unmounts or cameraOpen changes
      if (scannerControlsRef.current) {
        scannerControlsRef.current.stop();
      }
    };
  }, [props.cameraOpen]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        open={props.cameraOpen}
        onClick={props.cameraClickClose}
      >
        <div>
          <Stack
            direction={"column"}
            spacing={2}
            sx={{ p: "12px", pb: "16px !important" }}
          >
            <Box
              className="css-8zynwq"
              sx={{
                marginTop: "2rem !important",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  color: "white",
                  zIndex: 1,
                }}
                variant="body2"
              >
                헬스장에 준비된 QR코드를 촬영해주세요.
              </Typography>
              <section>
                <div>
                  <div className="css-1d3bbye">
                    <Box
                      sx={{
                        backgroundSize: "cover",
                        borderRadius: "0.5rem",
                      }}
                    >
                      {props.cameraOpen && (
                        <Webcam
                          ref={webcamRef}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          videoConstraints={{ facingMode: "environment" }}
                        />
                      )}
                    </Box>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        left: "83%",
                        transform: "translate(-50%, -50%)",
                      }}
                    ></div>
                  </div>
                </div>
              </section>
            </Box>
            <Button
              variant="text"
              sx={{ marginTop: 2, color: "#fff" }}
              onClick={props.cameraClickClose}
            >
              <CloseIcon style={{ fontSize: "48px" }} />
            </Button>
          </Stack>
          <Toast ref={toastRef} />
        </div>
      </Backdrop>
    </>
  );
};

export default CameraQrcodePage;
