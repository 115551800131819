import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  CardActionArea,
  CardActions,
} from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
interface propsType {
  userState: userState;
}
interface ReservationDetails {
  userName: string;
  userId: string;
  inputDt: string;
  reservDt: string;
  reservOption: string;
  deviceNum: string;
  userAddr: string;
  cancelDt: string;
}

interface Reservations {
  [key: string]: ReservationDetails;
}

interface ReservationInfo {
  sk: string;
  pk: string;
  userAddr: string;
  inputDt: string;
  userName: string;
  userId: string;
  reservState: string;
  cancelDt: string;
  reservations: Reservations;
}

interface Reservation {
  sk: string;
  pk: string;
  reservInfo: ReservationInfo;
  state: string;
}

const mainApi = new HttpMainApi();

const ReservationGolfEnd = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [reservationData, setReservationData] = useState<Reservation[]>([]);
  const [noData, setNoData] = useState(true);
  const [cancelOn, setCancelOn] = useState(false);
  const [cancelDay, setCancelDay] = useState(false);
  const [nowMonth, setNowMonth] = useState<string>("");
  const diffDay = ["25", "26", "27", "28", "29", "30", "31"];

  useEffect(() => {
    get_my_reservation();
    const { formatmonthpk, formatday } = getFormattedDateTime();
    setNowMonth(formatmonthpk);
    if (diffDay.includes(formatday)) {
      setCancelOn(true);
    } else {
      setCancelOn(false);
    }
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const get_my_reservation = async () => {
    setIsLoading(true);
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatmonthpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "GOLF",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setNoData(false);
      setReservationData(res.response.result as Reservation[]);
    } else if (res.code === "400") {
      setNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };
  const reservation_cancel = async (pk: string, sk: string) => {
    // alert("관리자 확인 후 취소가 가능합니다.\n- 사용 현황 확인 필요");
    // return;

    const confirmCancel = window.confirm(
      "신청을 취소하시겠습니까?\n[주의] 확인 후 사용료가 청구될 수 있습니다."
    );
    if (confirmCancel) {
      const { formattedDateTime } = getFormattedDateTime();
      const param: any = {
        pk: pk,
        sk: sk,
        inputDt: formattedDateTime,
      };
      const res = await mainApi.put_cancel_my_reservation(param);
      if (res.code === "200") {
        alert("신청이 취소되었습니다.");
        get_my_reservation();
      } else if (res.code === "400") {
        alert("신청 취소에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };

  function formatLastDayOfMonth(detailsReservDt: string): string {
    // 입력된 detailsReservDt에서 연도와 월을 추출
    const year = detailsReservDt.substring(0, 4);
    const month = detailsReservDt.substring(4, 6);

    // Date 객체를 생성, 다음 달의 첫 날을 나타내도록 설정
    // 주의: JavaScript의 Date는 월을 0부터 시작으로 취급하기 때문에 month에서 1을 빼주지 않습니다.
    const date = new Date(Number(year), Number(month) - 1, 0);
    // Date 객체를 사용하여 전달의 마지막 날짜를 구하고, 원하는 포맷으로 변환
    const formattedDate = `${date.getFullYear()}년 ${String(
      date.getMonth() + 1
    ).padStart(2, "0")}월 ${String(date.getDate()).padStart(
      2,
      "0"
    )}일 23시 59분까지`;
    return formattedDate;
  }

  return (
    <>
      <Stack
        spacing={2} // This controls the space between child components
        alignItems="center" // Align items to the center
        justifyContent="center" // Center items along the main axis
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 300,
              textAlign: "left",
              margin: "auto",
              mt: 2,
              ml: 3,
            }}
          ></Typography>
        </Box>

        {noData ? (
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{ marginTop: 4 }}
          >
            신청된 내용이 없습니다.
          </Typography>
        ) : (
          reservationData.map((reservData, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                maxWidth: isMobile ? "100%" : "40rem",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  minWidth: "340px",
                  borderRadius: "16px",
                  boxShadow: "none",
                  borderColor: "#f0f0f0",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="160"
                    image="/images/reservation_t_06.png"
                    alt="green iguana"
                    sx={{ maxWidth: "100%", height: 160 }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      골프연습장 신청이 완료되었습니다.
                    </Typography>
                    <hr
                      style={{
                        borderColor: "#ddd",
                        borderWidth: "0.3px",
                        width: "100%",
                      }}
                    />
                    {Object.entries(reservData.reservInfo.reservations).map(
                      ([time, details], idx) => (
                        <div key={idx}>
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            이용 날짜 : {details.reservDt}
                          </Typography>
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            이용 시간 : 06:00 ~ 22:00
                          </Typography>

                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            {details.inputDt}에 신청되었습니다.
                          </Typography>
                          <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                            color="#ff0000"
                          >
                            취소 가능 기간 :
                            {formatLastDayOfMonth(
                              reservData.sk.substring(0, 6)
                            )}
                          </Typography>
                        </div>
                      )
                    )}
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      width: "100%",
                    }}
                    color="primary"
                    variant="contained"
                    endIcon={<CancelIcon />}
                    disabled={
                      !(reservData.sk.substring(0, 6) !== nowMonth && cancelOn)
                    }
                    onClick={() =>
                      reservation_cancel(reservData.pk, reservData.sk)
                    }
                  >
                    <Typography variant="button">신청취소</Typography>
                  </Button>
                </CardActions>
              </Card>
            </Box>
          ))
        )}
      </Stack>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationGolfEnd;
