import { API, Auth } from "aws-amplify";
import { aiApiUrl } from "./MainInterface";
import axios from "axios";

export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;
  protected authToken: string | undefined;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
    this.authToken = undefined;
  }

  protected async getToken() {
    if (this.authToken === undefined) {
      try {
        const session = await Auth.currentSession();
        this.authToken = `Bearer ${session.getIdToken()?.getJwtToken()}`;
      } catch (error) {
        return undefined;
      }
    }

    return this.authToken;
  }

  protected async requestPost2(requestBody: any): Promise<any> {
    let header: any = {};
    let authToken: any;

    if (this.apiName === "MainApi") {
      authToken = await this.getToken();
      if (authToken !== undefined) {
        header = {
          Authorization: authToken,
        };
      }
    }

    const request = {
      headers: header,
      body: requestBody,
    };
    try {
      const response = await API.post(this.apiName, this.urlPath, request);
      return response;
    } catch (error) {
      return { code: "500", response: { error_msg: error } };
    }
  }
  protected async requestPost(requestBody: any): Promise<any> {
    try {
      let header: any = { timeout: 60000 };
      const response = await axios.post(this.urlPath, requestBody, header);
      return response.data;
    } catch (error) {
      return { code: "500", response: { error_msg: "apiTimeout" } };
    }
  }

  protected async requestStream(requestBody: any): Promise<any> {
    if (this.apiName === "AiApi") {
      // Stream 형식으로 Request
      const response = await fetch(aiApiUrl, {
        method: "POST",
        body: JSON.stringify(requestBody),
      });
      return response;
    }
  }
}
