import {
  useCallback,
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import * as AuthMain from "../auth/AuthMain";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import { userState } from "../interface/MainInterface";
import LeftMenu from "./LeftMenu";

interface propsType {
  userState: userState;
  open: boolean;
  winSize: string;
  selectedMenu: string;
  headerLarge: boolean;
  drawerWidth: number;
  largeDrawerWidth: number;
  handleDrawer: any;
  menuOver: any;
  menuOut: any;
}

const Header = (props: propsType, ref: any) => {
  let navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState("");
  const LeftMenuRef: any = useRef();

  // 헤더 메뉴 버튼 클릭시 좌측 메뉴 활성/비활성화
  const clickhandleDrawer = () => {
    props.handleDrawer();
  };

  useEffect(() => {
    return () => {};
  }, []);

  // 메뉴 클릭시 페이지 이동
  const move = useCallback(
    (loc: string) => {
      if (props.winSize === "xs") {
        // 모바일 모드일때는 메뉴 선택시 좌측메뉴를 최소화
        props.handleDrawer();
      }
      // 페이지 이동
      navigate(loc);
    },
    [navigate, props]
  );
  // 패스워드 변경
  const chPwd = async () => {
    navigate("/mobilepasschpage");
  };
  const fnAccount = async () => {
    navigate("/reservationaccount");
  };
  const fnProfile = async () => {
    navigate("/mobilemypage");
  };

  // 로그아웃
  const signOut = async () => {
    if (!window.confirm("로그아웃 하시겠습니까?")) return;
    await AuthMain.signOut();
    window.location.href = "/memberlogin";
  };

  const getLeftCount = () => {
    LeftMenuRef.current.getLeftCount();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getLeftCount,
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function getAvatarUrlByUserId(userId: string): string {
    // This is a placeholder. Implement your logic to return the avatar URL based on the userId
    return `https://detre-img.s3.ap-northeast-2.amazonaws.com/${userId}.png`;
  }
  useEffect(() => {
    setAvatarUrl(getAvatarUrlByUserId(props.userState.userId));
  }, [props.userState.userId]);

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: "0", zIndex: "700" }}>
      {/* 상단 고정메뉴 Component */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#f8f9fa",
          boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* 좌측 메뉴 Drawer */}
        <Drawer
          sx={{
            width: props.drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: props.drawerWidth,
              boxSizing: "border-box",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "#2E3341",
              color: "#ffffff",
              transition: "width 0.5s",
            },
          }}
          anchor="left"
          open={props.open}
          variant={"persistent"}
          onMouseOver={() => {
            if (props.winSize === "xs") {
              props.menuOver();
            } else {
              if (!props.headerLarge) props.menuOver();
            }
          }}
          onMouseOut={() => {
            if (props.winSize === "xs") {
              props.menuOut();
            } else {
              if (!props.headerLarge) props.menuOut();
            }
          }}
          onClose={() => {
            props.handleDrawer();
          }}
          className="main-sidebar sidebar-dark-primary"
        >
          <LeftMenu
            ref={LeftMenuRef}
            userState={props.userState}
            drawerWidth={props.drawerWidth}
            largeDrawerWidth={props.largeDrawerWidth}
            selectedMenu={props.selectedMenu}
            move={move}
          />
        </Drawer>
        {/* 좌측 메뉴 Drawer */}
        {/* 상단 툴바 */}
        <Toolbar
          sx={{
            ml: `${props.drawerWidth}px`,
            minHeight: "44px !important",
            display: "flex",
            justifyContent: "space-between",
            pr: "0px !important",
            transition: "margin 0.5s",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={clickhandleDrawer}
            edge="start"
            sx={{ mr: 2, color: "#2E3341", display: "flex" }}
          >
            <MenuIcon
              sx={{ color: "#2E3341", width: "2.3rem", height: "2.3rem" }}
            />
          </IconButton>
          <Box sx={{ pr: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar src={avatarUrl} />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={fnProfile}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={fnAccount}>
                <ListItemIcon>
                  <RequestQuoteIcon fontSize="small" />
                </ListItemIcon>
                My account
              </MenuItem>
              <MenuItem onClick={chPwd}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                비밀번호 변경
              </MenuItem>
              <MenuItem onClick={signOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        {/* 상단 툴바 */}
      </AppBar>
    </Box>
  );
};

export default forwardRef(Header);
