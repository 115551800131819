import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckIcon from "@mui/icons-material/Check";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";

const mainApi = new HttpMainApi();
interface propsType {
  userState: userState;
  userInfo: userState;
  handleClose: () => void;
}

const ReservationHealthAdmin = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedGubun, setSelectedGubun] = useState("헬스장");
  const [selectedMember, setSelectedMember] = useState(props.userInfo.userId);

  function formatYearMonth(selectYearMonth: string) {
    const regex = /(\d{4})년 (\d{1,2})월/;
    const matches = selectYearMonth.match(regex);
    if (matches) {
      const year = matches[1];
      const month = matches[2].padStart(2, "0");
      return `${year}${month}`;
    }

    return ""; // 매칭되지 않는 경우 빈 문자열 반환
  }

  const reservation_save = async () => {
    const formatted = formatYearMonth(selectYearMonth);
    const { formattedDateTime, formatdaypk } = getFormattedDateTime();
    const userId = props.userInfo.userId;
    const userAddr = props.userInfo.readAddress;
    const userName = props.userInfo.userName;
    const pk = `${formatted}/HEALTH`;
    const param: any = {
      pk: pk,
      sk: selectedGubun,
      option: selectedMember,
      userId: userId,
      userAddr: userAddr,
      userName: userName,
      inputDt: formattedDateTime,
      reservDt: selectYearMonth,
      my_pk: pk,
      my_sk: `${formatted}${formatdaypk}`,
      communityPrice: communityPrice,
    };

    const res = await mainApi.put_month_reserv_data(param);
    if (res.code === "200") {
      setAlertOpen(false);
      props.handleClose();
      alert("예약 완료되었습니다.");
    } else if (res.code === "301") {
      setAlertOpen(false);
      alert("이미 신청된 내역이 있습니다.\n다시 조회 후 진행해주세요.");
      props.handleClose();
    } else {
      setAlertOpen(false);
      props.handleClose();
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  const [months, setMonths] = useState<string[]>([]);
  const [selectYearMonth, setSelectYearMonth] = useState<string>("");

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // 현재 월과 다음 두 달을 계산합니다.
    const monthsArray = [
      new Date(currentYear, currentMonth - 1, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
      new Date(currentYear, currentMonth, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
      new Date(currentYear, currentMonth + 1, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
    ];

    setMonths(monthsArray);
    setSelectYearMonth(monthsArray[1]);
  }, []);

  const displayMonthName = (monthName: string) => {
    return monthName?.substring(6);
  };

  const guidelines = [
    "1) 매월 신청 후 헬스장 이용이 가능합니다.",
    "   - 이용료 :  월 5,000원(1인당)",
    "2) 관리소에서 지문 등록 후 입장하실 수 있습니다.",
    "   - 미등록 회원 이용 시 한 달 이용료 청구됩니다.",
  ];

  const [alertOpen, setAlertOpen] = useState(false);
  const handleClickOpen = () => {
    setAlertOpen(true);
  };
  const handleClose = () => {
    setAlertOpen(false);
  };

  const [communityPrice, setCommunityPrice] = useState(0);
  const get_price = async () => {
    const pk = "COMMUNITY/ACCOUNT";
    const sk = "헬스장";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_price(param);
    if (res.code === "200") {
      setCommunityPrice(res.response.result[0]["PRICE"]);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  useEffect(() => {
    get_price();
  }, []);
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 300,
              textAlign: "left",
              margin: "auto",
              mt: 2,
              ml: 3,
            }}
          ></Typography>
        </Box>

        <>
          <Box
            sx={{
              width: "90%",
              maxWidth: isMobile ? "100%" : "40rem",
              margin: "auto",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{ color: "#0000FF" }}
              >
                {props.userInfo.userName}님 {displayMonthName(months[1])} 신청
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 300,
                textAlign: "left",
                margin: "auto",
                ml: 3,
              }}
            >
              {/* 유의사항 */}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "95%",
            }}
          >
            <Card
              sx={{
                minWidth: "340px",
                borderRadius: "16px",
                boxShadow: "none",
                borderColor: "#f0f0f0",
                borderWidth: "1px",
                borderStyle: "solid",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginBottom: 2,
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{
                  marginTop: "0.6em",
                }}
              >
                헬스장 이용방법
              </Typography>
              <hr
                style={{
                  borderColor: "#ddd",
                  borderWidth: "0.3px",
                  width: "95%",
                }}
              />
              {guidelines.map((guide, index) => (
                <div style={{ textAlign: "left", width: "100%" }} key={index}>
                  <Typography
                    key={index}
                    style={{ margin: "10px 20px", textAlign: "left" }}
                  >
                    {guide}
                  </Typography>
                </div>
              ))}
            </Card>
          </Box>

          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
              justifyContent: "center",
              width: "95%", // 전체 너비를 사용하도록 설정
              height: "4em",
              marginTop: "3em",
            }}
          >
            <Button
              sx={{
                minWidth: "340px",
                borderRadius: "10px",
                border: "1px solid #ccc",
                width: "100%",
              }}
              color="primary"
              variant="contained"
              endIcon={<CheckIcon />}
              onClick={() => handleClickOpen()}
            >
              <Typography variant="h6">
                {displayMonthName(months[1])} 신청 하기
              </Typography>
            </Button>
          </Stack>
        </>
      </Stack>
      <Stack
        direction="row"
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          width: "95%",
          height: "2em",
          marginTop: "3em",
        }}
      ></Stack>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: "500px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"신청 하시겠습니까?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            [헬스장 신청 정보]
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            신청자 : {props.userInfo.userName}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            사용 기간 : {selectYearMonth}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            이용료 : {communityPrice}원
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={reservation_save} autoFocus>
            신청
          </Button>
        </DialogActions>
      </Dialog>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationHealthAdmin;
