import React, { useState, useEffect } from "react";
import { Stack, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { userState } from "../interface/MainInterface";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../utils/getFormattedDateTime";
import {
  red,
  pink,
  purple,
  indigo,
  blue,
  cyan,
  teal,
  green,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  deepPurple,
} from "@mui/material/colors";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { HttpMainApi } from "../interface/main-api";

const mainApi = new HttpMainApi();
interface propsType {
  userState: userState;
  qrPkData: string;
  qrSkData: string;
  rankOpen: boolean;
  rankClose: () => void;
  groupedList: GroupedList[];
}
interface UserExerciseCount {
  userId: string;
  count: number;
}

interface GroupedByCount {
  [key: number]: UserExerciseCount[];
}
interface GroupedList {
  userIds: string[];
  count: number;
}

interface Member {
  userAddr: string;
  reservState: string;
  userId: string;
  userName: string;
  sk: string;
  pk: string;
  inputDt: string;
  displayTime: string;
}
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Typography variant="body2" color="text.secondary">{`${Math.round(
        props.value
      )}일`}</Typography>
    </Box>
  );
}

const HealthRankPage = (props: propsType) => {
  let navigate = useNavigate();
  const [groups, setGroups] = useState<GroupedList[]>([]);

  useEffect(() => {
    setGroups(props.groupedList);
    // console.log("first : ", props.groupedList);
  }, [props.rankOpen]);
  function getAvatarUrlByUserId(userId: string): string {
    // This is a placeholder. Implement your logic to return the avatar URL based on the userId
    return `https://detre-img.s3.ap-northeast-2.amazonaws.com/${userId}.png`;
  }
  const colors = [
    red,
    pink,
    purple,
    indigo,
    blue,
    cyan,
    teal,
    green,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    deepPurple,
  ];
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    const colorShade = 500;
    return colors[randomIndex][colorShade];
  };

  const [memberLive, setMemberLive] = useState<Member[]>([]);
  // const get_qr_reservation = async () => {
  //   const { formatpk, formatmonthpk } = getFormattedDateTime();
  //   const pk = `${formatmonthpk}/INOUT`;
  //   const sk = formatpk;
  //   const param: any = {
  //     pk: pk,
  //     sk: sk,
  //   };
  //   const res3 = await mainApi.get_now_inout_counting(param);
  //   if (res3.code === "200") {
  //     const reservationInfo: Member[] = res3.response.result_in;
  //     setMemberLive(reservationInfo);
  //   }
  // };

  // useEffect(() => {
  //   if (props.rankOpen) {
  //     get_qr_reservation();
  //   }
  // }, [props.rankOpen]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 2,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
        open={props.rankOpen}
      >
        <Stack
          direction="column"
          style={{ height: "100%", paddingTop: "20%", border: "4px" }}
        >
          <TableContainer
            component={Paper}
            sx={{
              minWidth: "23rem",
              maxWidth: "100%",
              width: "100%",
              maxHeight: "50vh", // Set the maximum height to 50% of the viewport height
              overflowY: "auto", // Enable vertical scrolling
              overflowX: "auto", // Keeps your existing horizontal scrolling if needed
            }}
          >
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ width: "75%" }}>
                    회원 (
                    <Link
                      component="button"
                      onClick={() => navigate("/mobilemypage")}
                      underline="none"
                      sx={{
                        cursor: "pointer",
                        color: "#e53935",
                        fontWeight: "bold",
                      }}
                    >
                      사진변경
                    </Link>
                    )
                  </TableCell>
                  <TableCell align="center" sx={{ width: "25%" }}>
                    <Link
                      component="button"
                      onClick={() => navigate("/reservationhealthchart")}
                      underline="none"
                      sx={{
                        cursor: "pointer",
                        color: "#e53935",
                        fontWeight: "bold",
                      }}
                    >
                      전체 보기
                    </Link>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((group, index) => {
                  // 로그인한 사용자의 아이디가 그룹 내에 존재하는지 확인
                  const isUserInGroup = group.userIds.includes(
                    props.userState.userId
                  );

                  // 로그인한 사용자의 아이디가 존재하는 경우, 우선적으로 배열의 맨 앞에 위치시킴
                  // 그렇지 않은 경우, 원래 순서를 유지
                  const userIdsOrdered = isUserInGroup
                    ? [props.userState.userId].concat(
                        group.userIds.filter(
                          (userId) => userId !== props.userState.userId
                        )
                      )
                    : group.userIds;

                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">
                        <AvatarGroup max={7}>
                          {userIdsOrdered.map((userId, userIndex) => {
                            // 로그인한 사용자와 현재 userId가 같은 경우에만 StyledBadge를 적용
                            const badgeOrAvatar =
                              userId === props.userState.userId ? (
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  variant="dot"
                                  key={userIndex}
                                >
                                  <Avatar
                                    sx={{ bgcolor: getRandomColor() }}
                                    alt={userId}
                                    src={getAvatarUrlByUserId(userId)}
                                  />
                                </StyledBadge>
                              ) : (
                                <Avatar
                                  sx={{ bgcolor: getRandomColor() }}
                                  key={userIndex}
                                  alt={userId}
                                  src={getAvatarUrlByUserId(userId)}
                                />
                              );

                            // 로그인한 사용자가 아닌 경우, 혹은 로그인한 사용자가 해당 그룹에 포함된 경우에만 아바타 표시
                            return userId !== props.userState.userId ||
                              isUserInGroup
                              ? badgeOrAvatar
                              : null;
                          })}
                        </AvatarGroup>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Box sx={{ width: "100%" }}>
                          <LinearProgressWithLabel value={group.count} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Button
            variant="text"
            sx={{ marginTop: 2, color: "#fff" }}
            onClick={props.rankClose}
          >
            <CloseIcon style={{ fontSize: "48px" }} />
          </Button>
        </Stack>
      </Backdrop>
    </>
  );
};

export default HealthRankPage;
