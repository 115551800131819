import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  CardActionArea,
  CardActions,
} from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import "react-calendar/dist/Calendar.css";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
interface propsType {
  userState: userState;
}
interface ReservationDetails {
  userName: string;
  userId: string;
  inputDt: string;
  reservDt: string;
  reservOption: string;
  deviceNum: string;
}

interface Reservations {
  [key: string]: ReservationDetails;
}

interface ReservationInfo {
  sk: string;
  pk: string;
  reservations: Reservations;
}

interface Reservation {
  sk: string;
  pk: string;
  reservInfo: ReservationInfo;
  state: string;
}

const mainApi = new HttpMainApi();

const ReservationScreenEnd = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [reservationData, setReservationData] = useState<Reservation[]>([]);
  const [noData, setNoData] = useState(true);
  const [lastCancel, setLastCancel] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    get_my_reservation();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const get_my_reservation = async () => {
    setIsLoading(true);
    const { formatpk, formathoursTime } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatpk;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: "SCREEN",
    };
    const res = await mainApi.get_month_reserv_data(param);
    if (res.code === "200") {
      setNoData(false);
      setReservationData(res.response.result as Reservation[]);
      const sortList: any[] = [];
      const sortedResult = res.response.result.map((item: any) => {
        sortList.push(...Object.keys(item.reservInfo.reservations));
        return sortList;
      });
      const filteredResult = sortList.filter((time) => time < formathoursTime);
      setLastCancel(filteredResult);
    } else if (res.code === "400") {
      setNoData(true);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  function sortReservations(reservations: { [key: string]: any }) {
    const sortedReservations: { [key: string]: any } = {};
    const keys = Object.keys(reservations).sort((a, b) => {
      // 시간을 Date 객체로 변환
      const timeA = new Date(`1970-01-01T${a}`);
      const timeB = new Date(`1970-01-01T${b}`);

      // Date 객체의 타임스탬프를 비교
      return timeA.getTime() - timeB.getTime();
    });

    for (const key of keys) {
      sortedReservations[key] = reservations[key];
    }

    return sortedReservations;
  }

  const reservation_cancel = async (pk: string, sk: string) => {
    const communityPrice = 0;
    const confirmCancel = window.confirm(
      `예약을 취소하시겠습니까?\n[사용료] ${communityPrice}원`
    );
    if (confirmCancel) {
      const { formattedDateTime, formatpk } = getFormattedDateTime();
      const param: any = {
        pk: pk,
        sk: sk,
        inputDt: formattedDateTime,
        communityPrice: communityPrice,
        CancelUpdateDt: formatpk,
        CancelUpdateId: props.userState.userId,
      };
      const res = await mainApi.put_cancel_my_reservation_price(param);
      if (res.code === "200") {
        alert("예약이 취소되었습니다.");
        get_my_reservation();
      } else if (res.code === "400") {
        alert("예약 취소에 실패하였습니다.\n다시 조회 후 진행해주세요.");
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
        );
      }
    }
  };
  return (
    <>
      <Stack
        spacing={2} // This controls the space between child components
        alignItems="center" // Align items to the center
        justifyContent="center" // Center items along the main axis
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 300,
              textAlign: "left",
              margin: "auto",
              mt: 2,
              ml: 3,
            }}
          ></Typography>
        </Box>

        {noData ? (
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{ marginTop: 4 }}
          >
            예약된 내용이 없습니다.
          </Typography>
        ) : (
          reservationData.map((reservData, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                maxWidth: isMobile ? "100%" : "40rem",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  minWidth: "340px",
                  borderRadius: "16px",
                  boxShadow: "none",
                  borderColor: "#f0f0f0",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {Object.entries(reservData.reservInfo.reservations).map(
                  ([time, details], idx) => (
                    <>
                      <CardActionArea key={idx}>
                        <CardMedia
                          component="img"
                          height="160"
                          image={
                            details.deviceNum === "ROOM"
                              ? "/images/reservation_t_09.png"
                              : "/images/reservation_t_11.png"
                          }
                          alt="reservation image"
                          sx={{ maxWidth: "100%", height: 160 }}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            스크린 골프 예약이 완료되었습니다.
                          </Typography>
                          <hr
                            style={{
                              borderColor: "#ddd",
                              borderWidth: "0.3px",
                              width: "100%",
                            }}
                          />

                          <div>
                            {/* <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                          >
                            이 름 : {details.userName}
                          </Typography> */}
                            <Typography
                              variant="button"
                              display="block"
                              gutterBottom
                            >
                              예약 날짜 : {details.reservDt}
                            </Typography>
                            <Typography
                              variant="button"
                              display="block"
                              gutterBottom
                            >
                              예약 시간 : {details.reservOption}
                            </Typography>
                            <Typography
                              variant="button"
                              display="block"
                              gutterBottom
                            >
                              스크린 타석 : {details.deviceNum}
                            </Typography>
                            <Typography
                              variant="button"
                              display="block"
                              gutterBottom
                            >
                              {details.inputDt}에 예약되었습니다.
                            </Typography>
                          </div>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        {lastCancel.includes(details.reservOption) &&
                        details.deviceNum === "ROOM" ? (
                          <Button
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              width: "100%",
                            }}
                            color="primary"
                            disabled={true}
                            variant="contained"
                            endIcon={<CancelIcon />}
                          >
                            <Typography variant="button">취소 불가</Typography>
                          </Button>
                        ) : (
                          <Button
                            sx={{
                              borderRadius: "10px",
                              border: "1px solid #ccc",
                              width: "100%",
                            }}
                            color="primary"
                            variant="contained"
                            endIcon={<CancelIcon />}
                            onClick={() =>
                              reservation_cancel(reservData.pk, reservData.sk)
                            }
                          >
                            <Typography variant="button">예약취소</Typography>
                          </Button>
                        )}
                      </CardActions>
                    </>
                  )
                )}
              </Card>
            </Box>
          ))
        )}
      </Stack>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationScreenEnd;
