import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { useTheme, useMediaQuery } from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { appConfig } from "../../config/Config";
import S3Uploader from "../../utils/attach/S3Uploader";
import Avatar from "@mui/material/Avatar";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import Badge from "@mui/material/Badge";
import { CardContent, Typography } from "@mui/material";

const mainApi = new HttpMainApi();

interface propsType {
  userState: userState;
}

const MobileMypage = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [memberHealthDay, setMemberHealthDay] = useState(0);

  const get_my_health_day = async () => {
    const { formatmonthpk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const pk = `${formatmonthpk}/HEALTHDATA`;
    const sk = "헬스기록";
    const param: any = {
      pk: pk,
      sk: sk,
    };

    const res = await mainApi.get_my_health_day(param);
    if (res.code === "200") {
      let count = 0;
      const reservations = res.response.result[0].reservations;

      Object.values(reservations).forEach((day: any) => {
        if (day.includes(userId)) {
          count += 1;
        }
      });
      setMemberHealthDay(count);
    }
  };

  useEffect(() => {
    get_my_health_day();
  }, []);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  // 첨부파일 업로드 완료시 콜백
  const [avatarUrl, setAvatarUrl] = useState("");
  const [coverUrl, setCoverUrl] = useState("");
  const fncUploadComplete = (result: any, type: string) => {
    if (result.status === "ok") {
      if (result.status === "ok") {
        if (type === "avatar") {
          setAvatarUrl(
            getAvatarUrlByUserId(props.userState.userId) +
              "?t=" +
              new Date().getTime()
          );
        } else if (type === "cover") {
          setCoverUrl(
            getAvatarUrlByBg(props.userState.userId) +
              "?t=" +
              new Date().getTime()
          );
        }
      }
    }
  };

  function getAvatarUrlByUserId(userId: string): string {
    // This is a placeholder. Implement your logic to return the avatar URL based on the userId
    return `https://detre-img.s3.ap-northeast-2.amazonaws.com/${userId}.png`;
  }
  function getAvatarUrlByBg(userId: string): string {
    // This is a placeholder. Implement your logic to return the avatar URL based on the userId
    return `https://detre-img.s3.ap-northeast-2.amazonaws.com/bg-${userId}.png`;
  }

  useEffect(() => {
    setAvatarUrl(getAvatarUrlByUserId(props.userState.userId));
    setCoverUrl(getAvatarUrlByBg(props.userState.userId));
  }, [props.userState.userId]);

  return (
    <>
      <Box
        sx={{
          p: isMobile ? 1 : 2,
          display: "flex",
          marginTop: 5,
        }}
      >
        <Box
          component={"div"}
          sx={{
            width: "90%",
            maxWidth: isMobile ? "100%" : "40rem",
            margin: "auto",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 2,
              borderRadius: "8px",
              border: "1px solid #e0e0e0",
            }}
          >
            <Stack spacing={2} direction="column">
              <>
                <Box sx={{ position: "relative", width: "100%" }}>
                  {/* Cover Image */}
                  <Box
                    sx={{
                      height: 200,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${coverUrl})`,
                    }}
                  >
                    <label htmlFor="cover-image-upload">
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <S3Uploader
                            rootBucket={appConfig.REACT_APP_S3_BUCKET_NAME}
                            uploadDir={`bg-${props.userState.userId}`}
                            callBack={(result: any) =>
                              fncUploadComplete(result, "cover")
                            }
                          />
                        }
                        sx={{
                          position: "absolute",
                          right: 10,
                          bottom: -15,
                        }}
                      ></Badge>
                    </label>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -40,
                      left: "50%",
                      transform: "translateX(-50%)",
                      zIndex: 1,
                    }}
                  >
                    <label htmlFor="avatar-image-upload">
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <S3Uploader
                            rootBucket={appConfig.REACT_APP_S3_BUCKET_NAME}
                            uploadDir={`${props.userState.userId}`}
                            callBack={(result: any) =>
                              fncUploadComplete(result, "avatar")
                            }
                          />
                        }
                      >
                        <Avatar
                          alt={props.userState.userId}
                          src={avatarUrl}
                          sx={{ width: 100, height: 100 }}
                        />
                      </Badge>
                    </label>
                  </Box>
                </Box>

                <CardContent sx={{ pt: 7 }}>
                  {/* Adjust padding top to account for avatar size */}
                  <Typography variant="h5" gutterBottom>
                    {props.userState.userName} 회원님 (
                    {props.userState.readAddress})
                  </Typography>
                  <Typography variant="body2">
                    이번달 {memberHealthDay}일 열심히 운동하였습니다.
                  </Typography>
                </CardContent>
              </>
            </Stack>
          </Paper>
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MobileMypage;
