import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import * as XLSX from "xlsx";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ReservationHealthAdmin from "../mobilePage/ReservationHealthAdmin";
import ReservationGolfAdmin from "../mobilePage/ReservationGolfAdmin";
import ReservationStudyAdmin from "../mobilePage/ReservationStudyAdmin";
import ReservationLockerAdmin from "../mobilePage/ReservationLockerAdmin";
interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const AdminReservPage = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [userName, setUserName] = useState("");
  const [viewReserv, setViewReserv] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_user_list = async () => {
    if (userName === "") {
      alert("회원 이름을 넣어주세요");
      return;
    }
    setIsLoading(true);
    setUserList([]);
    const pk = "COMMUNITY/USER";
    const sk = userName;
    const param: any = {
      pk: pk,
      sk: sk,
      selectLevel: selectLevel,
    };

    const res = await mainApi.get_user_list(param);
    if (res.code === "200") {
      setUserList(res.response.result);
      setViewReserv("");
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setViewReserv("");
  };

  const [userInfo, setUserInfo] = useState<userState>(props.userState);
  const renderHealthButton = (params: any) => (
    <Button color="primary" onClick={() => handleHealth(params.row)}>
      <AddBoxIcon />
    </Button>
  );

  const handleHealth = async (params: any) => {
    setUserInfo(params);
    setViewReserv("health");
  };

  const renderGolfButton = (params: any) => (
    <Button color="primary" onClick={() => handleGolf(params.row)}>
      <AddBoxIcon />
    </Button>
  );

  const handleGolf = async (params: any) => {
    setUserInfo(params);
    setViewReserv("golf");
  };
  const renderStudyButton = (params: any) => (
    <Button color="primary" onClick={() => handleStudy(params.row)}>
      <AddBoxIcon />
    </Button>
  );
  const handleStudy = async (params: any) => {
    setUserInfo(params);
    setViewReserv("study");
  };
  const renderLockerButton = (params: any) => (
    <Button color="primary" onClick={() => handleLocker(params.row)}>
      <AddBoxIcon />
    </Button>
  );
  const handleLocker = async (params: any) => {
    setUserInfo(params);
    setViewReserv("locker");
  };

  const columns: GridColDef[] = [
    {
      field: "userId",
      headerName: "회원 아이디",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userName",
      headerName: "회원 이름",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "readAddress",
      headerName: "동/호",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "readInputDt",
      headerName: "가입 신청일",
      type: "number",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userLevel",
      headerName: "권한",
      width: 110,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userchLevel",
      headerName: "헬스장",
      width: 110,
      editable: false,
      renderCell: renderHealthButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fingerUpdate",
      headerName: "골프연습장",
      width: 110,
      editable: false,
      renderCell: renderGolfButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "userchDefer",
      headerName: "독서실",
      width: 110,
      editable: false,
      renderCell: renderStudyButton,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "resetPassword",
      headerName: "락커",
      width: 150,
      renderCell: renderLockerButton,
      align: "center",
      headerAlign: "center",
    },
  ];

  const [selectLevel, setSelectLevel] = useState("회원");
  const handleLevelChange = (event: SelectChangeEvent) => {
    setSelectLevel(event.target.value as string);
  };

  const downloadExcel = (accountList: any[], columns: any[]) => {
    // 각 행에 대해 엑셀 파일에 포함될 값을 계산
    const dataForExcel = accountList.map((row) => {
      let newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
        if (col.valueGetter) {
          newRow[col.headerName] = col.valueGetter({ row });
        } else {
          newRow[col.headerName] = row[col.field];
        }
      });
      return newRow;
    });

    // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Accounts");
    XLSX.writeFile(workbook, "accounts.xlsx");
  };
  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box sx={{ position: "absolute", top: "60px", right: "20px" }}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <TextField
              id="userId"
              value={userName}
              placeholder="이름"
              variant="outlined"
              label="이름"
              autoFocus
              fullWidth
              // required
              type="text"
              autoComplete="off"
              inputProps={{
                enterKeyHint: "Enter",
                style: { padding: "12px 16px 10px 16px" },
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  get_user_list(); // 엔터 키를 눌렀을 때 실행할 함수
                }
              }}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              sx={{ marginTop: "9px !important" }}
            />
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                get_user_list();
              }}
              sx={{
                borderRadius: "4px",
                width: "54px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              <SearchIcon />
            </Button>
          </Stack>
        </Box>
        <Box className="sub-header-root" sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            회원 예약 관리
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid>
            <DataGrid
              rows={userList}
              columns={columns}
              disableRowSelectionOnClick
              style={{ height: 250 }}
              getRowId={(row) => (row.userId ? row.userId.toString() : "")}
            />
            {userList.map((userList: any, index: number) => (
              <Grid
                key={`pressCard-${index}`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
              ></Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
      {viewReserv === "health" && (
        <ReservationHealthAdmin
          userState={props.userState}
          userInfo={userInfo}
          handleClose={handleClose}
        />
      )}
      {viewReserv === "golf" && (
        <ReservationGolfAdmin
          userState={props.userState}
          userInfo={userInfo}
          handleClose={handleClose}
        />
      )}
      {viewReserv === "study" && (
        <ReservationStudyAdmin
          userState={props.userState}
          userInfo={userInfo}
          handleClose={handleClose}
        />
      )}
      {viewReserv === "locker" && (
        <ReservationLockerAdmin
          userState={props.userState}
          userInfo={userInfo}
          handleClose={handleClose}
        />
      )}

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default AdminReservPage;
