import { useEffect, useState, useRef, forwardRef } from "react";

import {
  Box,
  List,
  CardMedia,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import CalculateIcon from "@mui/icons-material/Calculate";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import KeyIcon from "@mui/icons-material/Key";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { userState, IMenuItem } from "../interface/MainInterface";
import { HttpMainApi } from "../interface/main-api";
import PopCreatePress from "../page/module/PopCreatePress";
import { useNavigate } from "react-router-dom";
interface propsType {
  userState: userState;
  drawerWidth: number;
  largeDrawerWidth: number;
  selectedMenu: string;
  move: any;
}

// 공통 메뉴 : 일반사용자 접근
export const MENU: IMenuItem[] = [
  {
    name: "회원 관리",
    iconType: "img",
    icon: "/images/icon-search.png",
    path: "/userlist",
  },
  {
    name: "지문 관리",
    iconType: "mui-icon",
    icon: (
      <FingerprintIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/userfingerlist",
  },
  {
    name: "관리비 청구 대상",
    iconType: "mui-icon",
    icon: (
      <CalculateIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/accountlist",
    adminOnly: true,
  },
  {
    name: "관리비 청구 대상 상세",
    iconType: "mui-icon",
    icon: (
      <ChromeReaderModeIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/accountdetaillist",
    adminOnly: true,
  },
  {
    name: "예약 관리",
    iconType: "mui-icon",
    icon: (
      <PendingActionsIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/adminreserv",
  },
  {
    name: "락커 비밀번호 관리",
    iconType: "mui-icon",
    icon: <KeyIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />,
    path: "/lockerpassword",
  },
  {
    name: "시설 이용 현황 관리",
    iconType: "mui-icon",
    icon: (
      <SummarizeIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/usedetaillist",
    adminOnly: true,
  },

  {
    name: "관리자메뉴얼",
    iconType: "mui-icon",
    icon: (
      <LibraryBooksIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/adminmanual",
    adminOnly: true,
  },

  {
    name: "헬스장 CCTV",
    iconType: "mui-icon",
    icon: (
      <SlideshowIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/kvsplayer",
    adminOnly: true,
  },
];

// 공통 메뉴 : 모바일 전용
export const MOBILE_MENU: IMenuItem[] = [
  {
    name: "시설 신청",
    iconType: "mui-icon",
    icon: (
      <InsertInvitationIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/reservation",
  },

  {
    name: "신청 확인",
    iconType: "img",
    icon: "/images/icon-mypage.png",
    path: "/reservationend",
  },
  {
    name: "스크린 골프 예약",
    iconType: "mui-icon",
    icon: (
      <AccessTimeIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/reservationscreen",
  },
  {
    name: "스크린 골프 예약 확인",
    iconType: "mui-icon",
    icon: (
      <AlarmOnIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/reservationscreenend",
  },
  {
    name: "작은 도서관 책 검색",
    iconType: "mui-icon",
    icon: (
      <MenuBookIcon sx={{ width: "16px", height: "16px", color: "#aec5fe" }} />
    ),
    path: "/mobilebooklist",
  },
  {
    name: "청구서 확인",
    iconType: "mui-icon",
    icon: (
      <RequestQuoteIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    path: "/reservationaccount",
  },
];

export const ADMIN_MENU: IMenuItem[] = [
  {
    name: "사용자 관리",
    iconType: "mui-icon",
    icon: (
      <ManageAccountsIcon
        sx={{ width: "16px", height: "16px", color: "#aec5fe" }}
      />
    ),
    // path: "/mngUser",
    path: "/mobilemainpage",
  },
];

const mainApi = new HttpMainApi();

const LeftMenu = (props: propsType, ref: any) => {
  const popCreatePressRef: any = useRef(); // 보도자료 생성용 팝업
  const [expandMenu, setExpandMenu] = useState<any>([]); // 자식메뉴 있을때

  useEffect(() => {
    return () => {
      setExpandMenu([]);
    };
  }, []);

  useEffect(() => {
    // 메뉴 변경시 하일라이트를 위한 State 계산
    for (const menu of MENU) {
      if (menu.children) {
        for (const child of menu.children) {
          if (props.selectedMenu.split("/")[1] === child.path.split("/")[1]) {
            let temp = { ...expandMenu };
            temp[menu.path] = true;
            setExpandMenu(temp);
          }
        }
      }
    }
  }, [props.selectedMenu, expandMenu]);

  // 사이트 로고 동적 할당
  function SiteLogo() {
    const navigate = useNavigate(); // navigate 함수를 초기화합니다

    const handleClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      event.preventDefault();
      navigate("/mobilemain");
    };

    return (
      <h1 className="logo-root">
        <a href="/mobilemain" className="logo-a" onClick={handleClick}>
          <img className="logo-img" src="/images/d_logo.png" alt="logo" />
        </a>
      </h1>
    );
  }

  const ListMenu = () => {
    return (
      <>
        <Box className="left-menu-root">
          <Typography className="logo-menu-title">관리자 메뉴</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isAuth ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(menuInfo.path);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && (
                          <CardMedia
                            component="img"
                            className="left-menu-icon"
                            image={menuInfo.icon}
                          />
                        )}
                        {menuInfo.iconType === "mui-icon" && (
                          <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>
                              {menuInfo.name}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  const MobileMenu = () => {
    return (
      <>
        <Box className="left-menu-root">
          <Typography className="logo-menu-title">MENU</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {MOBILE_MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isAuth ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(menuInfo.path);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && (
                          <CardMedia
                            component="img"
                            className="left-menu-icon"
                            image={menuInfo.icon}
                          />
                        )}
                        {menuInfo.iconType === "mui-icon" && (
                          <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>
                              {menuInfo.name}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  const AdminMenu = () => {
    return (
      <>
        <Box className="left-menu-root mb-20">
          <Typography className="logo-menu-title">ADMIN</Typography>
          <List key={"left-menu"} sx={{ p: 0 }}>
            {ADMIN_MENU.map((menuInfo, index) =>
              menuInfo.adminOnly && !props.userState.isAuth ? (
                <div key={menuInfo.name + "" + index}></div>
              ) : (
                <div key={menuInfo.name + "" + index}>
                  <ListItem
                    disablePadding
                    sx={{
                      height: "42px",
                      width: "218px",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      className="left-menu-wrapper"
                      selected={menuInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(menuInfo.path);
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        {menuInfo.iconType === "img" && (
                          <CardMedia
                            component="img"
                            className="left-menu-icon"
                            image={menuInfo.icon}
                          />
                        )}
                        {menuInfo.iconType === "mui-icon" && (
                          <Icon sx={{ fontSize: "16px" }}>{menuInfo.icon}</Icon>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography className={"left-menu-text"}>
                              {menuInfo.name}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </div>
              )
            )}
          </List>
        </Box>
      </>
    );
  };

  return (
    <>
      <SiteLogo />
      {props.userState.userLevel === "관리자" && <ListMenu />}
      {props.userState.userId === "rosijin" && <ListMenu />}
      {props.userState.userId === "admin" && <ListMenu />}
      <MobileMenu />
      <Box sx={{ flexGrow: "1" }}></Box>
      {/* {props.userState.userLevel === "관리자" && <AdminMenu />} */}
      <PopCreatePress
        ref={popCreatePressRef}
        userState={props.userState}
        move={props.move}
      />
    </>
  );
};

export default forwardRef(LeftMenu);
