import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { Box, Grid, Stack, Typography, Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CryptoJS from "crypto-js";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
//IoT start
import { v4 as uuidv4 } from "uuid";
import * as AWS from "aws-sdk";
import { appConfig } from "../../config/Config";
import moment from "moment";
import * as Paho from "paho-mqtt";
//IoT end
import { DataGrid, GridColDef } from "@mui/x-data-grid";

interface propsType {
  userState: userState;
}
const mainApi = new HttpMainApi();

const HealthOut = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-365, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  const iotRegion: string = appConfig.iotRegion;
  const iotIdentityPollId: string = appConfig.iotIdentityPollId;
  const iotEndPoint: string = appConfig.iotEndPoint;
  let clientId = useRef<string>("");
  let mqtt_client: any = useRef();
  useEffect(() => {
    AWS.config.region = iotRegion;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: iotIdentityPollId,
    });
    AWS.config.getCredentials((err) => {
      if (err) console.error(err.stack);
      else {
        if (AWS.config.credentials !== undefined) {
          mqtt_client.current = initMqttClient();

          mqtt_client.current.onConnectionLost = function (err: any) {
            console.info("MQTT Connection Lost : ", err);
          };
          // mqtt_client.current.onMessageArrived = function (msg: any) {

          //   try {

          //   } catch (e) {
          //     console.error("onMessageArrived error : ", e);
          //   }
          // };
          var connectOptions: any = {
            onSuccess: function () {
              try {
                mqtt_client.current.subscribe(`member/commnity/count`, {
                  onSuccess: function () {
                    // mqtt_client.current.publish(
                    //   "member/coummnity/count",
                    //   JSON.stringify("coummnity onSuccess publish")
                    // );
                    console.log("IoT subscribe ");
                  },
                  onFailure: function () {
                    console.error("subscribeFailed");
                  },
                });
              } catch (e) {
                console.error("subscribeFailed", e);
              }
            },
            useSSL: true,
            timeout: 3,
            mqttVersion: 4,
            onFailure: function (errorMessage: any) {
              console.error("connectionLost", errorMessage);
            },
          };
          mqtt_client.current.connect(connectOptions);
        }
      }
    });
  }, []);

  function SigV4Utils() {}

  SigV4Utils.sign = function (key: any, msg: any) {
    var hash = CryptoJS.HmacSHA256(msg, key);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.sha256 = function (msg: any) {
    var hash = CryptoJS.SHA256(msg);
    return hash.toString(CryptoJS.enc.Hex);
  };

  SigV4Utils.getSignatureKey = function (
    key: any,
    dateStamp: any,
    regionName: any,
    serviceName: any
  ) {
    var kDate = CryptoJS.HmacSHA256(dateStamp, "AWS4" + key);
    var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
    var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
    var kSigning = CryptoJS.HmacSHA256("aws4_request", kService);
    return kSigning;
  };

  const initMqttClient = () => {
    var time = moment.utc();
    var dateStamp = time.format("YYYYMMDD");
    var amzdate = dateStamp + "T" + time.format("HHmmss") + "Z";
    var service = "iotdevicegateway";
    var region = AWS.config.region;
    var accessKey = AWS.config.credentials?.accessKeyId;
    var secretKey = AWS.config.credentials?.secretAccessKey;
    var algorithm = "AWS4-HMAC-SHA256";
    var method = "GET";
    var canonicalUri = "/mqtt";
    var host = iotEndPoint;

    var credentialScope =
      dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
    var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
    canonicalQuerystring +=
      "&X-Amz-Credential=" +
      encodeURIComponent(accessKey + "/" + credentialScope);
    canonicalQuerystring += "&X-Amz-Date=" + amzdate;
    canonicalQuerystring += "&X-Amz-Expires=86400";
    canonicalQuerystring += "&X-Amz-SignedHeaders=host";

    var canonicalHeaders = "host:" + host + "\n";
    var payloadHash = SigV4Utils.sha256("");
    var canonicalRequest =
      method +
      "\n" +
      canonicalUri +
      "\n" +
      canonicalQuerystring +
      "\n" +
      canonicalHeaders +
      "\nhost\n" +
      payloadHash;

    var stringToSign =
      algorithm +
      "\n" +
      amzdate +
      "\n" +
      credentialScope +
      "\n" +
      SigV4Utils.sha256(canonicalRequest);
    var signingKey = SigV4Utils.getSignatureKey(
      secretKey,
      dateStamp,
      region,
      service
    );
    var signature = SigV4Utils.sign(signingKey, stringToSign);
    canonicalQuerystring += "&X-Amz-Signature=" + signature;
    if (AWS.config.credentials?.sessionToken !== undefined) {
      canonicalQuerystring +=
        "&X-Amz-Security-Token=" +
        encodeURIComponent(AWS.config.credentials.sessionToken);
    }

    var requestUrl =
      "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
    clientId.current = "member-client-" + uuidv4();
    var mqtt_client = new Paho.Client(requestUrl, clientId.current);

    return mqtt_client;
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setUserList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_qr_reservation = async () => {
    const { formatpk, formatmonthpk } = getFormattedDateTime();
    const pk = `${formatmonthpk}/INOUT`;
    const sk = formatpk;
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_now_inout_counting(param);
    if (res.code === "200") {
      setUserList(res.response.result_in);
    }
  };

  const renderdeleteUserButton = (params: any) => (
    <Button color="primary" onClick={() => handleDeleteUser(params.row.userId)}>
      <DirectionsRunIcon />
    </Button>
  );

  const handleDeleteUser = async (userId: string) => {
    const confirmCancel = window.confirm(userId + "회원을 퇴장 시키겠습니까?");
    const { formattedTime } = getFormattedDateTime();
    if (confirmCancel) {
      const { formatpk, formathourspk, formatmonthpk, formattedDate } =
        getFormattedDateTime();
      const pk = `${formatmonthpk}/INOUT`;
      const param: any = {
        pk: pk,
        sk: formatpk + formathourspk,
        skSearch: formatpk,
        inputDt: formattedDate,
        displayTime: formattedTime,
        userId: userId,
      };
      const res_del = await mainApi.put_health_inout(param);
      if (res_del.code === "301") {
        alert("퇴장 되었습니다.");
        get_qr_reservation();
        mqtt_client.current.publish(
          "member/commnity/healthout",
          JSON.stringify("community onSuccess publish"), // 메시지 내용
          0, // QoS 설정 (0, 1, 또는 2)
          false // retain 플래그 설정 (true 또는 false)
        );
      } else {
        alert(
          `알수 없는 에러가 발생했습니다.\n- CODE : ${res_del.code}\n- message : ${res_del.response.error_msg}`
        );
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "userId",
      headerName: "회원 아이디",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "displayTime",
      headerName: "입장시간",
      width: 250,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "deleteUser",
      headerName: "회원 퇴장",
      width: 180,
      renderCell: renderdeleteUserButton,
      align: "center",
      headerAlign: "center",
    },
  ];

  const [selectLevel, setSelectLevel] = useState("신청");
  const handleLevelChange = (event: SelectChangeEvent) => {
    setSelectLevel(event.target.value as string);
  };
  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{ p: "32px", pb: "16px !important" }}
      >
        <Box sx={{ position: "absolute", top: "60px", right: "20px" }}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                get_qr_reservation();
              }}
              sx={{
                borderRadius: "4px",
                width: "54px",
                height: "54px",
                marginTop: "9px !important",
              }}
            >
              <SearchIcon />
            </Button>
          </Stack>
        </Box>
        <Box className="sub-header-root" sx={{ marginBottom: 4 }}>
          <Typography variant="h5" gutterBottom className="sub-header-title">
            헬스장 퇴장 처리
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid>
            <DataGrid
              rows={userList}
              columns={columns}
              disableRowSelectionOnClick
              style={{ height: 500 }}
              getRowId={(row) => (row.userId ? row.userId.toString() : "")}
            />
            {userList.map((userList: any, index: number) => (
              <Grid
                key={`pressCard-${index}`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
              ></Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default HealthOut;
