import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

interface propsType {
  reserv_time: string[];
  selectedTimes: string[];
  times: string[];
}
const getCurrentTimeFormatted = () => {
  const now = new Date();
  return (
    now.getHours().toString().padStart(2, "0") +
    ":" +
    now.getMinutes().toString().padStart(2, "0")
  );
};

const ReservRoomView = (props: propsType) => {
  const [filteredTimes, setFilteredTimes] = useState<string[]>([]);

  const updateFilteredTimes = () => {
    const currentTime = getCurrentTimeFormatted();
    const newFilteredTimes = props.reserv_time.filter(
      (time) => time >= currentTime
    );
    setFilteredTimes(newFilteredTimes);
  };

  useEffect(() => {
    // 컴포넌트 마운트 시 필터링 로직 실행
    updateFilteredTimes();

    // 10분마다 필터링 로직 재실행을 위한 인터벌 설정
    const intervalId = setInterval(updateFilteredTimes, 600000); // 600000ms = 10분

    // 컴포넌트 언마운트 시 인터벌 제거
    return () => clearInterval(intervalId);
  }, [props.reserv_time]);
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <List
        sx={{ width: "100%", height: "100%", bgcolor: "#595959" }}
        subheader={
          <>
            <ListSubheader
              sx={{
                marginTop: 3,
                marginLeft: 5,
                fontSize: "3.5rem",
                color: "#FFFFFF",
                bgcolor: "#595959",
              }}
            >
              예약 현황 안내
            </ListSubheader>
            <Typography
              sx={{
                marginTop: 1,
                marginLeft: 5,
                marginBottom: 3,
                fontSize: "1.5rem",
                color: "#FFFFFF",
                bgcolor: "#595959",
                paddingLeft: 2,
              }}
            >
              다음 예약 시간을 확인해 주세요.
            </Typography>
            <Divider
              sx={{
                backgroundColor: "#ffffff",
              }}
              component="li"
            />
          </>
        }
      >
        {filteredTimes.slice(0, 5).map((timeValue, index) => (
          <div key={index}>
            <ListItem
              sx={{
                display: "flex", // Flexbox 디스플레이 설정
                justifyContent: "space-between", // 아이템 사이에 공간 동등하게 배분
                alignItems: "center", // 아이템을 세로 중앙 정렬
              }}
            >
              <Typography
                sx={{
                  marginLeft: 5,
                  display: "inline",
                  color: "#FFFFFF",
                  fontSize: "8rem",
                  verticalAlign: "middle",
                }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                {timeValue}
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  marginRight: 5,
                  color: "#FFFFFF",
                  borderColor: "#FFFFFF",
                  fontSize: "3rem",
                  minWidth: "auto",
                  padding: "0 16px",
                  "& .MuiButton-startIcon": {
                    fontSize: "3rem",
                  },
                  verticalAlign: "middle",
                }}
              >
                {props.times.includes(timeValue) ? "예약 완료" : ""}
              </Button>
            </ListItem>
            <Divider
              sx={{
                backgroundColor: "#ffffff",
              }}
              component="li"
            />
          </div>
        ))}
      </List>
    </div>
  );
};

export default ReservRoomView;
