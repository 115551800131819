import { useEffect, useRef, useState, forwardRef } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import Input from "@mui/material/Input";
import Toast from "../Toast";
import { appConfig } from "../../config/Config";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Compressor from "compressorjs";

interface propsType {
  rootBucket: string | undefined;
  uploadDir: string;
  callBack: any;
}

AWS.config.update({
  region: "ap-northeast-2",
  accessKeyId: appConfig.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: appConfig.REACT_APP_S3_SECRET_KEY,
});

const S3Uploader = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const attachInputRef: any = useRef();
  const s3 = new AWS.S3();

  const [click, setClick] = useState(false);
  const [targetCnt, setTargetCnt] = useState(0);
  const [okCnt, setOkCnt] = useState(0);
  const [resultFiles, setResultFiles] = useState<any>([]);

  const fncUpload = () => {
    setTargetCnt(0);
    setOkCnt(0);
    setResultFiles([]);
    attachInputRef.current?.click();
  };

  const handleAttachInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptableFormats = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/JPEG",
      "image/JPG",
      "image/PNG",
    ];

    if (e.target.files !== null) {
      const files: FileList = e.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!acceptableFormats.includes(file.type)) {
          alert("지원하지 않는 파일입니다.");
          return;
        }
      }

      setTargetCnt(files.length);
      let result: any = [...resultFiles];

      for await (const fileInfo of files) {
        const file = fileInfo;

        // 파일 압축 처리
        new Compressor(file, {
          quality: 0.6, // 압축 품질 설정 (0.6은 적당한 품질)
          success(compressedBlob) {
            // Blob을 File로 변환
            const compressedFile = new File(
              [compressedBlob],
              file.name, // 원본 파일명 유지
              { type: file.type, lastModified: Date.now() } // 파일 속성 설정
            );
            // 압축된 파일을 S3로 업로드
            uploadToS3(compressedFile);
          },
          error(err) {
            console.error("Image compression error:", err);
          },
        });
      }

      setResultFiles(result);
    }
  };

  const uploadToS3 = async (compressedFile: File) => {
    const dirName = props.uploadDir;
    const fileKey = dirName + ".png"; // 기존 로직대로 파일명 설정

    const params = {
      Body: compressedFile,
      Bucket: props.rootBucket,
      Key: fileKey,
      ContentType: "image/png", // 업로드 타입
    };

    const res = await putObjectWrapper(params);

    if (res.$response.httpResponse.statusCode === 200) {
      setResultFiles((prev: any) => [
        ...prev,
        { FILE_NAME: compressedFile.name, FILE_PATH: fileKey },
      ]);
      setOkCnt((setOkCnt) => setOkCnt + 1);
    } else {
      props.callBack({ status: "fail" });
    }
  };

  const putObjectWrapper = async (params: any) => {
    try {
      const result = await s3.putObject(params).promise();
      return result;
    } catch (err) {
      console.error("S3 putObject error:", err);
      throw err;
    }
  };

  useEffect(() => {
    if (okCnt === targetCnt) {
      props.callBack({ status: "ok", fileInfo: resultFiles });
      setClick(false);
    } else setClick(true);
  }, [okCnt, targetCnt]);

  useEffect(() => {
    setClick(false);
    setTargetCnt(0);
    setOkCnt(0);
    setResultFiles([]);
  }, []);

  return (
    <>
      <CameraAltIcon
        onClick={() => {
          fncUpload();
        }}
      />

      <Input
        key="attachment"
        color="primary"
        type="file"
        inputProps={{ accept: "image/*", multiple: true }}
        inputRef={attachInputRef}
        onChange={handleAttachInput}
        sx={{ display: "none" }}
      />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(S3Uploader);
